// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Contexts
import ModalProvider from 'contexts/Modal/ModalProvider';

// Components
import Button from 'common/components/Button';
import Modal from 'ideation/components/base/Modal';
import NotificationsSignupModal from '../NotificationsSignupModal';

const NotificationsSignup = ({ title, t }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <ModalProvider>
      <Button className="signup__cta" onClick={openModal} primary>
        {t('project.sign_up_for_notifications_button')}
      </Button>
      <Modal isOpen={showModal} onClose={closeModal} className="narrow-full-screen">
        <NotificationsSignupModal onClose={() => closeModal()} title={title} />
      </Modal>
    </ModalProvider>
  );
};

NotificationsSignup.propTypes = {
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(NotificationsSignup);
