// Libraries
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Success from './Success';
import Form from './Form';

// Enums
import Key from '../../enums/Key';

const NotificationsSignupModal = ({ onClose, title }) => {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const hotKeyListener = ({ key }) => {
      if (key === Key.ESCAPE) {
        onClose();
      }
    };

    window.addEventListener('keydown', hotKeyListener);
    return () => window.removeEventListener('keydown', hotKeyListener);
  }, []);

  return (
    <div className="notifications-signup-modal">
      {success && <Success hideModal={onClose} />}
      {!success && (
        <Form hideModal={onClose} onSuccess={() => setSuccess(true)} projectTitle={title} />
      )}
    </div>
  );
};

NotificationsSignupModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

NotificationsSignupModal.defaultProps = {
  title: '',
};

export default NotificationsSignupModal;
