// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

// Routes
import routes from 'routes';

// Utilities
import { populatePath } from 'common/utils/helpers';

const ProjectTile = (props) => {
  const { project, showEmptyCategoryTitle } = props;
  const params = useParams();
  const { t } = useTranslation();

  const txtViewProject = t('project.view_project');
  const getProjectUrl = (projectId) => populatePath(routes.PROJECT, { ...params, projectId });
  const getCategoryTitle = () => {
    if (project.category?.title) return project.category.title;
    if (showEmptyCategoryTitle) return <span>&nbsp;</span>;
    return null;
  };

  return (
    <div className="project-tile">
      <div className="project-tile__image">
        {project.heroImage?.imageUrls && (
          <img
            src={project.heroImage.imageUrls.card}
            alt={project.heroImage.altText}
            title={project.heroImage.caption}
            style={{ objectPosition: project?.heroImage?.objectPosition || 'center' }}
          />
        )}
      </div>
      <div className="project-tile__body">
        <div>
          <div className="project-tile__body__category">{getCategoryTitle()}</div>
          <div className="project-tile__body__info">
            <h3
              className={classNames({
                'single-line': !!project.teaser,
              })}
              id={`projecttile_${project.id}`}
            >
              {project.title}
            </h3>
            {project.teaser && <p>{project.teaser}</p>}
          </div>
        </div>
        <div className="project-tile__body__actions">
          <a
            href={getProjectUrl(project.slug || project.id)}
            className="button is-primary is-primary-background"
            aria-labelledby={`projecttile_${project.id}`}
          >
            {txtViewProject}
          </a>
        </div>
      </div>
    </div>
  );
};

ProjectTile.propTypes = {
  project: PropTypes.shape({
    heroImage: PropTypes.shape({
      imageUrls: PropTypes.shape({
        card: PropTypes.string,
      }),
      altText: PropTypes.string,
      caption: PropTypes.string,
      objectPosition: PropTypes.string,
    }),
    category: PropTypes.shape({
      title: PropTypes.string,
    }),
    title: PropTypes.string,
    teaser: PropTypes.string,
    slug: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  showEmptyCategoryTitle: PropTypes.bool,
};

ProjectTile.defaultProps = {
  showEmptyCategoryTitle: true,
};

export default ProjectTile;
