// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';

// Utilities
import { generateHtmlIdForLabel } from 'common/utils/helpers';

export const SortableItem = ({ item, addKeyDownListener, removeKeyDownListener }) => {
  const htmlId = generateHtmlIdForLabel(item.title);

  return (
    <li
      className={classNames('sortable-item', {
        'sortable-item--text-only': !item.imageUrl,
      })}
      tabIndex={0}
      aria-live="assertive"
      aria-label={item.title}
      draggable
      onFocus={addKeyDownListener}
      onBlur={removeKeyDownListener}
    >
      <div className="sortable-item__content">
        <div className="sortable-item__content-grip">
          <i className="fas fa-grip-vertical" />
        </div>

        {item.imageUrl && (
          <div
            className="sortable-item__content-image"
            style={{ backgroundImage: `url("${encodeURI(item.imageUrl)}")` }}
          />
        )}

        <div className="sortable-item__content-body content">
          <label htmlFor={htmlId} className="is-primary-color">
            {item.title}
          </label>
          {item.description && <p>{item.description}</p>}
        </div>
      </div>
    </li>
  );
};

SortableItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
  }).isRequired,
  addKeyDownListener: PropTypes.func.isRequired,
  removeKeyDownListener: PropTypes.func.isRequired,
};

export default SortableElement(SortableItem);
