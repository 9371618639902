// Libraries
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';

// State
import { deleteResponseEntry, upsertResponseEntry } from 'state/ducks/engagement';

// Components
import CheckboxSelectInput from 'engagement/components/inputs/CheckboxSelectInput';

export function MultiSelectQuestion(props) {
  const { question, currentQuestionValues, dispatchUpdateQuestionValue } = props;
  const { id, inputs, displayData } = question;
  const questionValue = currentQuestionValues?.[id] || {};
  const dispatch = useDispatch();
  const dispatchDebounced = useCallback(debounce(dispatch, 300), []);

  const onChange = (newQuestionValue, lastInputId) => {
    dispatchUpdateQuestionValue(question.id, newQuestionValue);

    const value = newQuestionValue[lastInputId]?.value;
    const params = {
      inputId: lastInputId,
      questionId: question.id,
      inputAnswer: isString(value) ? value : undefined,
    };
    if (lastInputId in newQuestionValue) dispatchDebounced(upsertResponseEntry(params));
    else dispatch(deleteResponseEntry(params));
  };

  return (
    <div className="multi-select-question" data-test="multi-select-question">
      <CheckboxSelectInput
        options={inputs}
        initialValue={questionValue}
        onChange={onChange}
        choicesLimit={displayData?.choicesLimit}
      />
    </div>
  );
}

MultiSelectQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayData: PropTypes.shape({
      choicesLimit: PropTypes.string,
    }),
    inputs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        label: PropTypes.string.isRequired,
        format: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  currentQuestionValues: PropTypes.shape({}),
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
};

MultiSelectQuestion.defaultProps = {
  currentQuestionValues: {},
};

export default React.memo(MultiSelectQuestion);
