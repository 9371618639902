// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// Components
import VotingWidget from 'ideation/components/ideaActions/VotingWidget';
import CommentCountWidget from 'ideation/components/ideaActions/CommentCountWidget';
import FlagIdeaWidget from 'ideation/components/ideaActions/FlagIdeaWidget';

// Enums
import PublicStatus from 'common/enums/PublicStatus';
import { IdeaType } from 'ideation/enums/propTypes';
import { DOWNVOTE, UPVOTE } from 'ideation/enums/votingTypes';
import { downvoteIdea, flagIdea, upvoteIdea } from 'api/v3/private';

// Errors & Validations
import { processUserError } from 'ideation/validations/ideaValidations';

const IdeaActionContainer = ({
  displayCommentCount,
  ideaRoute,
  idea,
  publicStatus,
  onIdeaUpdate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [displayFlagMenu, setDisplayFlagMenu] = useState(false);
  const flaggedByUser = Boolean(idea.moderationFlags);
  const displayFlagWidget = publicStatus !== PublicStatus.CLOSED || flaggedByUser;

  const upvote = async () => {
    try {
      const { deserialized: updatedIdea } = await upvoteIdea(idea.id);
      onIdeaUpdate({
        ...idea,
        upVotesCount: updatedIdea.upVotesCount,
        downVotesCount: updatedIdea.downVotesCount,
        votes: idea.votes?.voteType === UPVOTE ? null : { voteType: UPVOTE },
      });
    } catch (error) {
      processUserError(error, dispatch);
    }
  };

  const downvote = async () => {
    try {
      const { deserialized: updatedIdea } = await downvoteIdea(idea.id);
      onIdeaUpdate({
        ...idea,
        upVotesCount: updatedIdea.upVotesCount,
        downVotesCount: updatedIdea.downVotesCount,
        votes: idea.votes?.voteType === DOWNVOTE ? null : { voteType: DOWNVOTE },
      });
    } catch (error) {
      processUserError(error, dispatch);
    }
  };

  const flag = async (reason) => {
    try {
      await flagIdea(idea.id, { reason });
      onIdeaUpdate({ ...idea, moderationFlags: {} });
    } catch (error) {
      processUserError(error, dispatch);
    } finally {
      setDisplayFlagMenu(false);
    }
  };

  return (
    <>
      <VotingWidget
        handleVote={upvote}
        voteType={UPVOTE}
        votedByUser={idea.votes?.voteType === UPVOTE}
        voteCount={idea.upVotesCount}
        voteTooltip={t('ideation.up_vote_idea_tooltip')}
        publicStatus={publicStatus}
      />
      <VotingWidget
        handleVote={downvote}
        voteType={DOWNVOTE}
        votedByUser={idea.votes?.voteType === DOWNVOTE}
        voteCount={idea.downVotesCount}
        voteTooltip={t('ideation.down_vote_idea_tooltip')}
        publicStatus={publicStatus}
      />
      {displayCommentCount && (
        <CommentCountWidget ideaRoute={ideaRoute} idea={idea} userHasCommented={false} /> // TODO
      )}
      {displayFlagWidget && (
        <FlagIdeaWidget
          handleFlagIdea={flag}
          flaggedByUser={flaggedByUser}
          displayMenu={displayFlagMenu}
          setDisplayMenu={setDisplayFlagMenu}
          publicStatus={publicStatus}
          id={idea.id}
        />
      )}
    </>
  );
};

IdeaActionContainer.propTypes = {
  ideaRoute: PropTypes.string,
  idea: IdeaType.isRequired,
  publicStatus: PropTypes.string.isRequired,
  displayCommentCount: PropTypes.bool,
  onIdeaUpdate: PropTypes.func.isRequired,
};

IdeaActionContainer.defaultProps = {
  ideaRoute: '',
  displayCommentCount: false,
};

export default IdeaActionContainer;
