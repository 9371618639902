// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

// Enumerables
import PublicStatus from 'common/enums/PublicStatus';

const Tag = ({ publicStatus, t }) => (
  <span
    className={classNames('tag', 'call-to-action-tile__tag', 'is-medium', {
      'call-to-action-tile__tag--closed': publicStatus === PublicStatus.CLOSED,
      'call-to-action-tile__tag--open': publicStatus === PublicStatus.OPEN,
      'call-to-action-tile__tag--upcoming': publicStatus === PublicStatus.UPCOMING,
    })}
  >
    {publicStatus === PublicStatus.CLOSED && t('project.closed')}
    {publicStatus === PublicStatus.OPEN && t('project.open')}
    {publicStatus === PublicStatus.UPCOMING && t('project.upcoming')}
  </span>
);

Tag.propTypes = {
  publicStatus: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Tag);
