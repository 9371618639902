/* eslint-disable react/state-in-constructor */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ShowMoreList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      itemsToShow: 5,
      expanded: false,
    };
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.showMore();
    }
  }

  showMore() {
    const { expanded } = this.state;
    const { children } = this.props;
    if (expanded) {
      this.setState({ itemsToShow: 5, expanded: false });
    } else {
      this.setState({ itemsToShow: children.length, expanded: true });
    }
  }

  render() {
    const { children } = this.props;
    if (!children) return null;

    const { itemsToShow, expanded } = this.state;
    const showing = children.length > itemsToShow ? itemsToShow : children.length;
    const total = children.length;
    const spanText = `Showing ${showing} of ${total}`;
    const showMoreButtonText = expanded ? 'Show Less' : 'Show More';
    const showMetaInfo = expanded || total > showing;

    return (
      <>
        <div className="show-more-list" id="show-more-list-1">
          {expanded ? children : children.slice(0, itemsToShow)}
        </div>
        {showMetaInfo && (
          <p className="show-more-list__meta">
            <span className="show-more-list__info">{spanText}</span>
            <span
              className="show-more-list__button is-primary"
              onClick={() => this.showMore()}
              onKeyPress={(e) => this.handleKeyPress(e)}
              tabIndex={0}
              aria-controls="#show-more-list-1"
              id="show-more-button"
            >
              {showMoreButtonText}
            </span>
          </p>
        )}
      </>
    );
  }
}

ShowMoreList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

ShowMoreList.defaultProps = {
  children: [],
};

export default ShowMoreList;
