// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

// Api
import { getRequest } from 'api/v3';
import { getIdeaBoardUrl, getIdeaUrl, getIdeaFetcher } from 'api/v3/private';

// Utils
import { userAuthenticated } from 'ideation/utils/userAuthenticate';

// Enums
import DiscussableTypes from 'common/enums/DiscussableTypes';
import PublicStatus from 'common/enums/PublicStatus';

// Components
import Header from 'header/components/Header';
import Footer from 'common/components/Footer';
import SocialFooter from 'common/components/SocialFooter';
import IdeaHeader from 'ideation/components/idea/IdeaHeader';
import DiscussionBoard from 'discussion/components/DiscussionBoard';
import LoginSection from 'discussion/components/LoginSection';
import ProfileSection from 'discussion/components/ProfileSection';

const IdeaContainer = ({ match }) => {
  const { t } = useTranslation();
  const { ideaBoardId, ideaId } = match.params;
  const ideaBoard = useSWR(getIdeaBoardUrl(ideaBoardId), getRequest);
  const idea = useSWR([getIdeaUrl(ideaId, { include: 'hero_image' }), ideaBoardId], getIdeaFetcher);
  const isLoggedIn = userAuthenticated();
  const approvedAndModeratedCount = idea.data
    ? idea.data?.deserialized?.approvedAndModeratedCount
    : 0;

  const onIdeaUpdate = (updatedIdea) => {
    idea.mutate({ deserialized: updatedIdea }, false);
  };

  const disabled = ideaBoard?.data?.deserialized?.publicStatus !== PublicStatus.OPEN;

  return (
    <div className="idea-container">
      <Header ideation ideaBoard={ideaBoard.data?.deserialized} showAuth />
      <IdeaHeader
        idea={idea.data?.deserialized}
        ideaBoard={ideaBoard.data?.deserialized}
        onIdeaUpdate={onIdeaUpdate}
      />
      <main id="main">
        <div className="container inner inner--narrowest pt-2">
          <div className="content my-6 has-text-centered">
            <p>
              {t(
                disabled
                  ? 'ideation.idea_board_closed_notification'
                  : 'ideation.thoughts_or_feedback'
              )}
            </p>
          </div>
        </div>

        <div className="container inner inner--narrow mb-6">
          <section className="idea-comments pt-4">
            {isLoggedIn ? <ProfileSection /> : <LoginSection />}

            {ideaBoard.data?.deserialized && (
              <DiscussionBoard
                discussableType={DiscussableTypes.Idea}
                discussableId={ideaId}
                isLoggedIn={isLoggedIn}
                disabled={disabled}
                approvedAndModeratedCount={approvedAndModeratedCount}
              />
            )}
          </section>
        </div>
      </main>
      <SocialFooter project={ideaBoard.data?.deserialized.project} />
      <Footer />
    </div>
  );
};

IdeaContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default withRouter(IdeaContainer);
