// Libraries
import React from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import Notification from 'common/components/Notification';

const ClosedNotification = () => {
  const { t } = useTranslation();

  return (
    <Notification className="idea-submission-notification" isClosed noDismiss>
      {t('ideation.idea_board_closed_notification')}
    </Notification>
  );
};

ClosedNotification.propTypes = {};

export default ClosedNotification;
