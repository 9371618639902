import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Options from './Options';
import Rows from './Rows';

class MatrixInput extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  render() {
    const { options, rows, onChange, values } = this.props;
    const modifier = options.length > 7 ? 'matrix-input--compact' : 'matrix-input--normal';

    return (
      <div className={`matrix-input matrix-input--${options.length} ${modifier}`}>
        <Options options={options} />
        <Rows rows={rows} options={options} onChange={onChange} values={values} />
      </div>
    );
  }
}

export default MatrixInput;
