// Libraries
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';

// State
import { deleteResponseEntry, upsertResponseEntry } from 'state/ducks/engagement';

// Utilities
import { determineImageSize } from 'common/utils/helpers';

// Enumerables
import InputType from 'engagement/enums/InputType';

// Components
import ButtonSelectInput from 'engagement/components/inputs/ButtonSelectInput';
import CardSelectInput from 'engagement/components/inputs/CardSelectInput';
import RadioSelectInput from 'engagement/components/inputs/RadioSelectInput';

export function SingleSelectQuestion(props) {
  const { question, currentQuestionValues, dispatchUpdateQuestionValue } = props;
  const { id, displayData, inputs } = question;
  const dispatch = useDispatch();
  const dispatchDebounced = useCallback(debounce(dispatch, 300), []);
  const questionValue = currentQuestionValues?.[id] || {};
  const [currentInputId, currentInputValue] = Object.entries(questionValue)[0] || [];
  const initialTextValue = isString(currentInputValue?.value) ? currentInputValue.value : '';

  const onChange = (inputId, value = true) => {
    const newQuestionValue = {};
    if (inputId) newQuestionValue[inputId] = { value };

    dispatchUpdateQuestionValue(question.id, newQuestionValue);

    const params = {
      inputId,
      questionId: question.id,
      inputAnswer: isString(value) ? value : undefined,
    };

    if (inputId !== currentInputId)
      dispatch(deleteResponseEntry({ ...params, inputId: currentInputId }));
    dispatchDebounced(upsertResponseEntry(params));
  };

  if (inputs.every(({ media }) => media)) {
    return (
      <div className="single-select-question">
        <CardSelectInput
          options={inputs.map((input) => ({
            ...input,
            imageUrl: determineImageSize(input.media.imageUrls),
          }))}
          initialValue={[currentInputId]}
          onChange={(inputIds) => onChange(inputIds[0])}
          multipleSelection={false}
        />
      </div>
    );
  }

  if (displayData && displayData.inputsType === InputType.BUTTON) {
    return (
      <div className="single-select-question">
        <ButtonSelectInput
          options={inputs}
          initialValue={[currentInputId]}
          onChange={(inputIds) => onChange(inputIds[0])}
          multipleSelection={false}
        />
      </div>
    );
  }

  return (
    <div className="single-select-question">
      <RadioSelectInput
        options={inputs}
        initialValue={currentInputId}
        initialTextChoiceValue={initialTextValue}
        onChange={onChange}
      />
    </div>
  );
}

SingleSelectQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayData: PropTypes.shape({
      inputsType: PropTypes.string,
    }),
    inputs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  currentQuestionValues: PropTypes.shape({}),
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
};

SingleSelectQuestion.defaultProps = {
  currentQuestionValues: {},
};

export default memo(SingleSelectQuestion);
