// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

// Utilities
import { capitalize, formatUnitValue, sumQuestionValues } from 'common/utils/helpers';

function OpenBudgetOverview({ budget, capType, unitLabel, currentValues }) {
  const { t } = useTranslation();
  const used = sumQuestionValues(currentValues);
  const remaining = budget - used;
  const deficit = remaining < 0;
  const progress = (deficit ? budget / used : used / budget) * 100;

  function renderLabel() {
    if (capType === 'hard_cap') return t('engagement.remaining');
    if (remaining > 0) return t('engagement.surplus');
    if (remaining < 0) return t('engagement.deficit');
    return t('engagement.balanced');
  }

  return (
    <div className="open-budget-overview">
      <div className="open-budget-overview__header">
        {capitalize(t('engagement.used'))}
        <span className="open-budget-overview__used">{formatUnitValue(used, unitLabel)}</span>
      </div>
      <div
        className={classNames('open-budget-overview__progress', {
          'open-budget-overview__progress--deficit': deficit,
        })}
      >
        <div
          className="open-budget-overview__progress-value"
          style={{ width: deficit ? `calc(${progress}% + 1px)` : `${progress}%` }}
        />
      </div>
      <div className="open-budget-overview__footer">
        {capType === 'soft_cap' && (
          <i
            className={classNames('fas open-budget-overview__icon', {
              'fa-plus-circle': remaining > 0,
              'fa-check': remaining === 0,
              'fa-exclamation-triangle open-budget-overview__icon--negative': remaining < 0,
            })}
          />
        )}
        {renderLabel()}
        <span className="open-budget-overview__remaining">
          {formatUnitValue(remaining, unitLabel)}
        </span>
      </div>
    </div>
  );
}

OpenBudgetOverview.propTypes = {
  budget: PropTypes.number.isRequired,
  capType: PropTypes.oneOf(['soft_cap', 'hard_cap']).isRequired,
  unitLabel: PropTypes.string,
  currentValues: PropTypes.shape({}),
};

OpenBudgetOverview.defaultProps = {
  unitLabel: '',
  currentValues: {},
};

export default OpenBudgetOverview;
