// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// Utils
import { populatePath } from 'common/utils/helpers';

// Enums
import routes from 'routes';

const CategoryItem = (props) => {
  const { category } = props;
  const { id, heroImage, title, description, slug } = category;
  const params = useParams();
  const { t } = useTranslation();

  const getCategoryUrl = (categoryId) =>
    populatePath(routes.CATEGORY_ALIAS, { ...params, categoryId });

  return (
    <div className="category-item">
      <div className="category-item__image">
        {heroImage?.imageUrls && (
          <img src={heroImage.imageUrls.card} alt={heroImage.altText} title={heroImage.altText} />
        )}
      </div>
      <div className="category-item__body">
        <div>
          <div className="category-item__body__info">
            <h3
              className={classNames({
                'single-line': !!description,
              })}
              id={`category-title-${id || slug}`}
            >
              {title}
            </h3>
            <p>{description}</p>
          </div>
        </div>
        <div className="category-item__body__actions">
          <a
            href={getCategoryUrl(slug || id)}
            className="button is-primary is-primary-background"
            aria-labelledby={`category-title-${id || slug}`}
          >
            {t('project.view_category')}
          </a>
        </div>
      </div>
    </div>
  );
};

CategoryItem.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    heroImage: PropTypes.shape({
      imageUrls: PropTypes.shape({
        card: PropTypes.string,
      }),
      altText: PropTypes.string,
    }),
    title: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
};

export default CategoryItem;
