import React from 'react';
import PropTypes from 'prop-types';

/**
 * Quote component.
 */
const Quote = ({ photoUrl, name, position, children }) => (
  <div className="Quote">
    <div className="columns">
      <div className="column is-one-quarter headshot">
        <div className="headshot-content">
          {photoUrl && <img src={photoUrl} alt="headshot" />}
          <p>
            {name}
            <span>{position}</span>
          </p>
        </div>
      </div>
      <div className="column quote">
        <div className="quote-content is-primary-color">{children}</div>
      </div>
    </div>
  </div>
);

Quote.propTypes = {
  /**
   * Photo to display.
   */
  photoUrl: PropTypes.string.isRequired,
  /**
   * Person's name.
   */
  name: PropTypes.string.isRequired,
  /**
   * Person's position.
   */
  position: PropTypes.string.isRequired,
  /**
   * Quote content.
   */
  children: PropTypes.node.isRequired,
};

export default Quote;
