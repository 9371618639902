// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { renderMarkdown } from 'common/utils/helpers';

const CommunityRules = (props) => {
  const { rules } = props;

  if (!rules) {
    return false;
  }

  return (
    <div className="community-rules">
      <p dangerouslySetInnerHTML={{ __html: renderMarkdown(rules) }} />
    </div>
  );
};

CommunityRules.propTypes = {
  rules: PropTypes.string,
};

CommunityRules.defaultProps = {
  rules: '',
};

export default CommunityRules;
