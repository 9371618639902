// Libraries
import React from 'react';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';

// Utils
import { getUserToken } from 'ideation/utils/userAuthenticate';
import { csrfToken, signOutPath } from 'common/utils/authHelpers';

const SIGN_OUT_PATH = signOutPath(window.location.pathname);
const CSRF_TOKEN = csrfToken();

const ProfileSection = () => {
  const { t } = useTranslation();
  const token = getUserToken();
  const username = jwtDecode(token).preferred_username;

  return (
    <div className="profile-section">
      <span
        dangerouslySetInnerHTML={{
          __html: t('ideation.posting_as', {
            username: `<b>${username}</b>`,
            interpolation: { escapeValue: false },
          }),
        }}
      />
      {/* TODO create a user profile page for this button */}
      {/* <span>．</span>
      <button
        type="button"
        onClick={() => console.log('my profile')}
      >
        {t('ideation.my_profile')}
      </button> */}
      <b>．</b>
      <form action={SIGN_OUT_PATH} method="POST">
        <input type="hidden" name="authenticity_token" value={CSRF_TOKEN} />
        <input type="hidden" name="_method" value="delete" />
        <button type="submit">{t('ideation.log_out_button')}</button>
      </form>
    </div>
  );
};

export default ProfileSection;
