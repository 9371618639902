// Libraries
import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';

// Components
import BudgetCheckboxGroup from 'engagement/components/QuestionContainer/questions/Budget/BudgetCheckboxGroup';

export function BudgetInput(props) {
  const { options, initialValue, onChange } = props;
  const [selectedOptionIds, setSelectedOptionIds] = useState(Object.keys(initialValue));

  const handleChange = (newOptionIds, lastOptionId) => {
    setSelectedOptionIds(newOptionIds);
    onChange?.(newOptionIds, lastOptionId);
  };

  return (
    <table className="budget-input checkbox-select-input">
      <BudgetCheckboxGroup
        options={options}
        initialValue={selectedOptionIds}
        onChange={handleChange}
      />
    </table>
  );
}

BudgetInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
      format: PropTypes.string.isRequired,
    })
  ).isRequired,
  initialValue: PropTypes.objectOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};

BudgetInput.defaultProps = {
  initialValue: {},
  onChange: null,
};

export default memo(BudgetInput);
