// Libraries
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import useSWR from 'swr';

// Routes
import Routes from 'routes';

// Utilities
import { fetcher, fetchProject } from 'api/rest';
import { isEmbedded, populatePath } from 'common/utils/helpers';

// Components
import EngagementTile from 'project/components/EngagementTile';
import NotificationsSignup from 'common/components/NotificationsSignup';
import QRCodeImage from 'common/components/QRCodeImage';
import VisitHubLink from '../VisitHubLink';

const CompleteEngagementFooter = ({ nextEngagement, keepSubmission }) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { data: project } = useSWR([fetchProject, match.params.projectId], fetcher);
  const [exitLinkContainer, setExitLinkContainer] = useState();

  useEffect(() => {
    setExitLinkContainer(document.getElementById('exit-links'));
  }, []);

  function renderNextEngagementColumn() {
    function getContent() {
      if (project.public && project.projectHubEnabled && !keepSubmission)
        return (
          <>
            <h2 className="title">{t('engagement.stay_involved_project')}</h2>
            <QRCodeImage
              value={window.location.origin + populatePath(Routes.PROJECT, match.params)}
              alt="Project hub link"
              options={{ margin: 3, width: 360 }}
              style={{ marginBottom: '40px' }}
            />
          </>
        );

      if (nextEngagement) {
        return (
          <>
            <h2 className="title">
              {keepSubmission
                ? t('engagement.participate_in_more')
                : t('engagement.stay_involved_project')}
            </h2>
            <EngagementTile engagement={nextEngagement} />
          </>
        );
      }

      return null;
    }

    const content = getContent();
    if (!content) return null;

    return (
      <div className="column is-three-fifths next-engagement">
        <div className="content">{content}</div>
      </div>
    );
  }

  function renderInvolvementColumn() {
    const content = [];

    if (project.notificationSignUpEnabled)
      content.push(
        <div className="stay-involved__row" key="notifications">
          <p>{t('engagement.get_notified_message')}</p>
          <NotificationsSignup title={project.title} />
        </div>
      );

    if (project.public && project.projectHubEnabled && !isEmbedded())
      content.push(
        <div className="stay-involved__row" key="hubLink">
          <p>{t('engagement.learn_more')}</p>
          <VisitHubLink button />
        </div>
      );

    if (content.length === 0) return null;

    return (
      <div className="column stay-involved">
        <div className="content stay-involved__content">
          {keepSubmission && (
            <h2 className="title stay-involved__title">{t('engagement.stay_involved')}</h2>
          )}
          {content}
        </div>
      </div>
    );
  }

  function renderContent() {
    if (!project) return null;

    return (
      <div className="container inner">
        <div className="columns">
          {renderNextEngagementColumn()}
          {renderInvolvementColumn()}
        </div>
      </div>
    );
  }

  return (
    <div className="complete-engagement-footer">
      {renderContent()}
      {Boolean(exitLinkContainer && !keepSubmission) &&
        createPortal(
          <div className="is-secondary-color exit-links-container">
            <a
              className="exit-link-container-link is-secondary-color"
              href={populatePath(Routes.ENGAGEMENT_SECTION, { ...match.params, position: 1 })}
            >
              <i className="fas fa-redo" />
              {t('engagement.restart')}
            </a>
          </div>,
          exitLinkContainer
        )}
    </div>
  );
};

CompleteEngagementFooter.propTypes = {
  nextEngagement: PropTypes.shape({}),
  keepSubmission: PropTypes.bool.isRequired,
};

CompleteEngagementFooter.defaultProps = {
  nextEngagement: null,
};

export default CompleteEngagementFooter;
