// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// State
import { upsertResponseEntry } from 'state/ducks/engagement';

// Components
import RatingQuestionInput from './RatingQuestionInput';

function RatingQuestion({ question, currentQuestionValues, dispatchUpdateQuestionValue }) {
  const dispatch = useDispatch();

  const onChangeHandler = (input, value) => {
    dispatchUpdateQuestionValue(question.id, { [input.id]: { value } });
    dispatch(
      upsertResponseEntry({ inputId: input.id, questionId: question.id, inputAnswer: value })
    );
  };

  return (
    <div className="rating-question">
      {question.inputs.map((input) => (
        <RatingQuestionInput
          question={question}
          defaultValue={currentQuestionValues?.[question.id]?.[input.id]?.value}
          onChange={(value) => onChangeHandler(input, value)}
          key={input.id}
        />
      ))}
    </div>
  );
}

RatingQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    required: PropTypes.bool,
    displayData: PropTypes.shape({
      scale: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  currentQuestionValues: PropTypes.shape({}),
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
};

RatingQuestion.defaultProps = {
  currentQuestionValues: null,
};

export default RatingQuestion;
