// Libraries
import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

// State
import { getQuestionInputsMap, pushResponseEntry } from 'state/ducks/engagement';
import { deleteEntry, patchEntry, postEntry } from 'state/helpers/responses';

// Utils
import { confirmValidMarkers } from 'engagement/utils/helpers';

// Components
import ImageAnnotationInput from 'engagement/components/inputs/ImageAnnotationInput';

export function ImageQuestion(props) {
  const { question, currentQuestionValues, dispatchUpdateQuestionValue } = props;
  const questionValue = currentQuestionValues?.[question.id] || {};
  const inputsMap = useSelector(getQuestionInputsMap);
  const dispatch = useDispatch();
  const dispatchDebounced = useCallback(debounce(dispatch, 300), []);

  const markers = useMemo(() => {
    return Object.entries(questionValue).reduce((accumulator, [inputId, { value }]) => {
      const validMarkers = confirmValidMarkers(value, 'x', 'y');
      accumulator[inputId] = validMarkers.map(({ guid, x, y, comment }) => ({
        guid,
        position: [y, x],
        comment,
      }));

      return accumulator;
    }, {});
  }, [questionValue]);

  const getOnChangeHandler = (inputId) => (markersMap, meta) => {
    for (const marker of Object.values(markersMap)) {
      marker.position = marker.position.map(Math.round);
    }

    const newValue = Object.entries(markersMap).map(([guid, marker]) => {
      const { position, comment } = marker;
      const [y, x] = position;
      return { guid, x, y, comment };
    });

    const newQuestionValue = { [inputId]: { value: newValue } };
    dispatchUpdateQuestionValue(question.id, newQuestionValue, true);

    if (meta.removed) {
      const entry = deleteEntry({
        questionUuid: question.uuid,
        inputUuid: inputsMap[inputId].uuid,
        responseUuid: meta.removed.uuid,
        inputAnswer: meta.removed.position.slice().reverse().join(','), // x,y
        comment: meta.removed.comment,
      });
      dispatch(pushResponseEntry(entry));
      return;
    }

    const lastMarkerUuid = meta.created || meta.updated;
    const data = {
      questionUuid: question.uuid,
      inputUuid: inputsMap[inputId].uuid,
      responseUuid: lastMarkerUuid,
      inputAnswer: markersMap[lastMarkerUuid].position.slice().reverse().join(','), // x,y
      comment: markersMap[lastMarkerUuid].comment,
    };

    if (meta.created) dispatch(pushResponseEntry(postEntry(data)));
    else dispatchDebounced(pushResponseEntry(patchEntry(data)));
  };

  return (
    <div className="image-question">
      {question.inputs.map(({ id, media }) => (
        <ImageAnnotationInput
          key={id}
          initialMarkers={markers[id]}
          onChange={getOnChangeHandler(id)}
          imageUrl={media.links.self}
        />
      ))}
    </div>
  );
}

ImageQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  currentQuestionValues: PropTypes.shape({}),
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
};

ImageQuestion.defaultProps = {
  currentQuestionValues: {},
};

export default memo(ImageQuestion);
