// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';

// Api
import { getRequest } from 'api/v3';
import { getIdeaBoardUrl } from 'api/v3/private';

// Components
import IdeaBoard from 'ideation/components/ideaBoard/IdeaBoard';

const IdeaBoardContainer = ({ match }) => {
  const { data } = useSWR(getIdeaBoardUrl(match.params.ideaBoardId), getRequest);

  if (!data) return null;

  return <IdeaBoard ideaBoard={data.deserialized} />;
};

IdeaBoardContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      ideaBoardId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default IdeaBoardContainer;
