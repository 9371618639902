// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import { withTranslation } from 'react-i18next';

// Components
import Thumbnails from './Thumbnails';

const renderCaption = (title, description) => {
  if (!title) {
    return null;
  }
  return (
    <div className="slide__caption">
      <p className="caption__title">{title}</p>
      {description && <p className="caption__description">{description}</p>}
    </div>
  );
};

export const ImageGallery = ({ images, t }) => {
  if (!images.length) {
    return null;
  }

  return (
    <div className="image-gallery">
      <CarouselProvider
        naturalSlideWidth={16}
        naturalSlideHeight={9}
        totalSlides={images.length}
        visibleSlides={1}
      >
        <div className="image-gallery__slider-wrapper">
          <ButtonBack className="image-gallery__slider-previous button is-primary is-primary-background">
            <i className="fas fa-angle-left" />
          </ButtonBack>

          <ButtonNext className="image-gallery__slider-next button is-primary is-primary-background">
            <i className="fas fa-angle-right" />
          </ButtonNext>

          <Slider className="image-gallery__slider">
            {images.map(({ src, title, description }, index) => (
              <Slide key={`${title}-${src}`} index={index} className="slider__slide-wrapper">
                <div className="slider__slide">
                  {renderCaption(title, description)}
                  <Image src={src} className="slide__image" />
                </div>
              </Slide>
            ))}
          </Slider>
        </div>

        <Thumbnails images={images} t={t} />
      </CarouselProvider>
    </div>
  );
};

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      thumbnailSrc: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ImageGallery);
