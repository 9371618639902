// Libraries
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Notification = forwardRef(
  (
    { title, children, onClose, className, isInfo, isSuccess, isDanger, isClosed, noDismiss },
    ref
  ) => (
    <div
      className={classNames(className, 'notification', {
        'is-closed': isClosed,
        'is-info': isInfo,
        'is-success': isSuccess,
        'is-danger': isDanger,
      })}
      ref={ref}
    >
      {!noDismiss && (
        <button
          label="Dismiss"
          title="Dismiss"
          type="button"
          className="notification__dismiss delete"
          onClick={onClose}
        />
      )}
      <div className="notification__icon">
        <span className="icon">
          <i
            className={classNames('fas', {
              'fa-info-circle': isInfo,
              'fa-check': isSuccess,
              'fa-exclamation-circle': isDanger,
              'fa-clipboard-check': isClosed,
            })}
          />
        </span>
      </div>
      <div className="notification__content">
        {title && <h5 className="notification__title title is-5">{title}</h5>}
        {children}
      </div>
    </div>
  )
);

Notification.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  className: PropTypes.string,
  isInfo: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isDanger: PropTypes.bool,
  isClosed: PropTypes.bool,
  noDismiss: PropTypes.bool,
};

Notification.defaultProps = {
  title: null,
  children: null,
  className: null,
  isInfo: false,
  isSuccess: false,
  isDanger: false,
  isClosed: false,
  noDismiss: false,
  onClose: () => {},
};

export default Notification;
