// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import isPast from 'date-fns/isPast';
import isFuture from 'date-fns/isFuture';
import parseISO from 'date-fns/parseISO';
import classNames from 'classnames';

// Utilities
import { urlFormatter } from 'common/utils/helpers';

// Components
import ProjectMeta from 'project/components/ProjectMeta';

export const StoryPhases = ({ heading, phases, t }) => {
  const phasesToRender = phases.map((phase) => ({
    ...phase,
    isCurrent: isPast(parseISO(phase.startDate)) && isFuture(parseISO(phase.endDate)),
  }));

  const lastCompleteIndex = phasesToRender.reduce((lastIndex, phase, index) => {
    if ((phase.isCurrent || isPast(parseISO(phase.endDate))) && index > lastIndex) {
      return index;
    }

    return lastIndex;
  }, 0);

  const barStep = phases.length > 1 ? 100 / (phases.length - 1) : 0;
  const barInnerHeight = lastCompleteIndex * barStep;

  return (
    <ProjectMeta heading={heading} icon="fas fa-align-left">
      <div className="story-phases">
        <div className="story-phases__column">
          {phasesToRender.map((phase, index) => (
            <div
              className={classNames('story-phases__row story-phases__number', {
                'story-phases__number--current': phase.isCurrent,
              })}
              key={phase.id}
            >
              {index + 1}
            </div>
          ))}
        </div>

        <div className="story-phases__column">
          <div className="story-phases__bar">
            <div className="story-phases__bar-inner" style={{ height: `${barInnerHeight}%` }} />
            {phasesToRender.map((phase, index) => (
              <div
                className={classNames('story-phases__bar-tick', {
                  'story-phases__bar-tick--current': phase.isCurrent,
                })}
                style={{ top: `${barStep * index}%` }}
                key={phase.id}
              />
            ))}
          </div>
        </div>

        <div className="story-phases__column">
          {phasesToRender.map((phase) => (
            <div
              className={classNames('story-phases__row story-phases__text', {
                'story-phases__text--current': phase.isCurrent,
              })}
              key={phase.id}
            >
              <div className="story-phases__info">
                <div
                  className={classNames('story-phases__title', {
                    'story-phases__title--current': phase.isCurrent,
                  })}
                >
                  {phase.link ? (
                    <a className="is-secondary-color" href={urlFormatter(phase.link)}>
                      {phase.title}
                    </a>
                  ) : (
                    phase.title
                  )}
                </div>
                <div className="story-phases__timeframe">{t(phase.timeFrame)}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ProjectMeta>
  );
};

StoryPhases.propTypes = {
  heading: PropTypes.string.isRequired,
  phases: PropTypes.arrayOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(StoryPhases);
