// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

// Enums
import PublicStatus from 'common/enums/PublicStatus';

// Components
import SearchInput from 'ideation/components/base/SearchInput';

const IdeaBoardSearch = ({
  publicStatus,
  register,
  searchValue,
  reset,
  handleSubmit,
  searchSubmitted,
  listening,
  volume,
}) => {
  const { t } = useTranslation();

  return (
    <div className="idea-board-search">
      <div className="container inner inner--narrow inner--tablet-nopad">
        <form
          id="idea-board-search__form"
          className={classNames('idea-board-search__form', {
            'idea-board-search__form--listening': listening,
          })}
          onSubmit={handleSubmit}
        >
          <div className="field is-horizontal">
            <div className="field-body">
              <SearchInput
                name={'ideaSearch'}
                register={register}
                placeholder={
                  publicStatus === PublicStatus.CLOSED
                    ? t('ideation.search_ideas')
                    : t('ideation.enter_or_search')
                }
                handleSubmit={handleSubmit}
                listening={listening}
                volume={volume}
              />
              {!listening && (searchValue || searchSubmitted) && (
                <div className="control idea-board-search__form-clear-control">
                  <input
                    className="idea-board-search__form-clear is-secondary-color"
                    type="reset"
                    value={t('ideation.clear')}
                    onClick={reset}
                  />
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

IdeaBoardSearch.propTypes = {
  register: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  searchSubmitted: PropTypes.bool.isRequired,
  publicStatus: PropTypes.string,
  searchValue: PropTypes.string,
  listening: PropTypes.bool.isRequired,
  volume: PropTypes.number.isRequired,
};

IdeaBoardSearch.defaultProps = {
  publicStatus: '',
  searchValue: '',
};

export default IdeaBoardSearch;
