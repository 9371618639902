// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Utilities
import { getOrdinal } from 'common/utils/helpers';

/**
 * MobileSortableItem component.
 */
export class MobileSortableItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      /**
       * Unique option ID.
       */
      id: PropTypes.number.isRequired,
      /**
       * Option title.
       */
      title: PropTypes.string.isRequired,
      /**
       * (Optional) Option image URL.
       */
      imageUrl: PropTypes.string,
    }).isRequired,
    /**
     * Item's current position.
     */
    index: PropTypes.number.isRequired,
    /**
     * Index options to display in dropdown.
     */
    options: PropTypes.arrayOf(PropTypes.number).isRequired,
    /**
     * Position change handler.
     */
    onChange: PropTypes.func.isRequired,
  };

  renderOption = (option) => (
    <option key={option} value={option}>
      {getOrdinal(option + 1)}
    </option>
  );

  render() {
    const {
      item: { title, imageUrl },
      index,
      onChange,
      options,
    } = this.props;
    const firstIndex = options[0];
    const lastIndex = options[options.length - 1];

    return (
      <div className={`mobile-sortable-item card ${!imageUrl ? 'no-image' : ''}`}>
        <div className="card-index-control is-primary-background">
          {index !== firstIndex && (
            <i className="fas fa-caret-up" onClick={() => onChange(index - 1)} />
          )}
          <p>{index + 1}</p>
          {index !== lastIndex && (
            <i className="fas fa-caret-down" onClick={() => onChange(index + 1)} />
          )}
        </div>

        {imageUrl && (
          <div className="card-image">
            <figure className="image is-5by3">
              <img src={imageUrl} draggable={false} alt="" />
            </figure>
          </div>
        )}

        <div className="card-content">
          <div className="content">
            <div className="label-and-details">
              <span className="label is-primary-color">{title}</span>
              {/* 
                <div className="details">
                  <span className="details--plus">+</span>
                  <span className="details--link">Details</span>
                </div>
              */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MobileSortableItem);
