// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Components
import ShowMoreList from 'common/components/ShowMoreList';
import AnnouncementListItem from 'announcement/components/AnnouncementListItem';
import ProjectMeta from 'project/components/ProjectMeta';

export const Announcements = ({ announcements, match, t }) => (
  <ProjectMeta
    heading={t('project.project_updates')}
    icon="fas fa-bell"
    className="project-updates"
    headingClassName="project-updates__heading"
  >
    <ul className="project-updates__items">
      <ShowMoreList>
        {announcements.map((announcement) => (
          <AnnouncementListItem key={announcement.id} match={match} announcement={announcement} />
        ))}
      </ShowMoreList>
    </ul>
  </ProjectMeta>
);

Announcements.propTypes = {
  announcements: PropTypes.arrayOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Announcements);
