// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { numberRegex } from 'common/utils/helpers';

// Components
import Input from 'common/components/Input';

const NumberInput = ({ decimals, min, max, ...props }) => {
  const onBeforeInput = (event) => {
    const newValue = event.target.value + event.data;

    if (!numberRegex(decimals).test(newValue)) {
      event.preventDefault();
    }
  };

  return (
    <Input
      type="number"
      className="number-input"
      onBeforeInput={onBeforeInput}
      min={min}
      max={max}
      {...props}
    />
  );
};

NumberInput.propTypes = {
  decimals: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  ariaLabel: PropTypes.string.isRequired,
};

export default NumberInput;
