// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Routes
import Routes from 'routes';

// Utilities
import { populatePath, renderMarkdown } from 'common/utils/helpers';

// Components
import IdeaBoardHeaderContainer from 'ideation/components/ideaBoard/IdeaBoardHeaderContainer';

const IdeaBoardHeader = ({
  title,
  description,
  match,
  backgroundImageUrl,
  backgroundImagePosition,
  projectPublic,
}) => {
  const { t } = useTranslation();

  return (
    <IdeaBoardHeaderContainer
      backgroundImageUrl={backgroundImageUrl}
      backgroundImagePosition={backgroundImagePosition}
    >
      {projectPublic && (
        <div className="container inner">
          <a href={populatePath(Routes.PROJECT, match.params)} className="back-link">
            {t('ideation.project_page')}
          </a>
        </div>
      )}
      <div className="idea-board-header__inner container inner inner--narrow">
        <h1 className="idea-board-header__title title is-1 has-text-white">{title}</h1>
        {description && (
          <div className="idea-board-header__content has-text-white has-text-weight-bold">
            <p
              className="introduction markdown"
              dangerouslySetInnerHTML={{ __html: renderMarkdown(description) }}
            />
          </div>
        )}
      </div>
    </IdeaBoardHeaderContainer>
  );
};

IdeaBoardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  backgroundImagePosition: PropTypes.string,
  projectPublic: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string).isRequired,
  }).isRequired,
};

IdeaBoardHeader.defaultProps = {
  backgroundImageUrl: '',
  backgroundImagePosition: 'center',
  description: '',
};

export default withRouter(IdeaBoardHeader);
