// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import toast from 'react-hot-toast';

// Helpers
import { rtlLanguage } from 'common/utils/helpers';

const CommentInputToast = ({ id, children }) => (
  <div className="box comment-input__toast">
    <i className="zc zc-warning" />
    <div
      className={classNames('comment-input__toast__body', {
        rtl: rtlLanguage(),
      })}
    >
      {children}
    </div>
    <i
      className={classNames('zc zc-close-medium comment-input__toast__close-btn', {
        rtl: rtlLanguage(),
      })}
      onClick={() => id && toast.remove(id)}
    />
  </div>
);

CommentInputToast.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};

CommentInputToast.defaultProps = {
  id: 0,
  children: <></>,
};

export default CommentInputToast;
