/**
 * Update the like related properties for a discussion cache
 * @param {function} mutate
 * @param {number} page
 * @param {(number|string)} discussionId
 * @param {(number|string)} discussionLikeId
 */
export const updateDiscussionLikeCache = (mutate, page, discussionId, discussionLikeId) => {
  mutate((cachedData) => {
    for (const discussion of cachedData[page].deserialized) {
      if (discussion.id === discussionId) {
        discussion.discussionLikesCount += discussionLikeId ? 1 : -1;
        discussion.userDiscussionLikeId = discussionLikeId;
      }
    }

    return cachedData;
  });
};

/**
 * Update the report related properties for a discussion cache
 * @param {function} mutate
 * @param {number} page
 * @param {(number|string)} discussionId
 * @param {(number|string)} discussionReportId
 */
export const updateDiscussionReportCache = (mutate, page, discussionId, discussionReportId) => {
  mutate((cachedData) => {
    for (const discussion of cachedData[page].deserialized) {
      if (discussion.id === discussionId) {
        discussion.discussionReportsCount += 1;
        discussion.userDiscussionReportId = discussionReportId;
      }
    }

    return cachedData;
  });
};

/**
 * To check if the role is a staff role
 * @param {string} role
 * @returns {boolean}
 */
export const isStaff = (role) => {
  switch (role) {
    case 'super_admin':
    case 'owner':
    case 'builder':
    case 'contributor':
    case 'read_only':
    case 'participant':
      return true;
    case 'participant_user':
    default:
      return false;
  }
};
