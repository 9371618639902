// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utilities
import { storyTheme } from 'common/utils/helpers';

const ProjectMeta = ({ heading, icon, children, className, headingClassName }) => (
  <div className={classNames('project-meta', className)} role="region" aria-label={heading}>
    <h2 className={classNames('project-meta__heading', headingClassName)}>
      {storyTheme() && (
        <span className="project-meta__heading-icon icon is-small">
          <i className={icon} />
        </span>
      )}
      {heading}
    </h2>
    {children}
  </div>
);

ProjectMeta.propTypes = {
  heading: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headingClassName: PropTypes.string,
};

ProjectMeta.defaultProps = {
  className: null,
  headingClassName: null,
};

export default ProjectMeta;
