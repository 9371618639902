// Libraries
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// State
import { clearSubmissionStatus } from 'state/ducks/ideation';

// Components
import ApprovedNotification from 'ideation/components/ideaBoard/ApprovedNotification';
import PendingNotification from 'ideation/components/ideaBoard/PendingNotification';
import UserBannedNotification from 'ideation/components/ideaBoard/UserBannedNotification';
import UserSuspendedNotification from 'ideation/components/ideaBoard/UserSuspendedNotification';

// Enums
import { APPROVED, PENDING } from 'ideation/enums/submissionStatus';
import { USER_BANNED, USER_SUSPENDED } from 'ideation/enums/userStatusErrors';

// Utils
import { useScrollIntoView } from 'common/hooks/useScrollIntoView';

const SubmissionNotification = () => {
  const dispatch = useDispatch();
  const submissionStatus = useSelector(({ ideation }) => ideation.submissionStatus);
  const notificationRef = useScrollIntoView(submissionStatus);

  if (!submissionStatus) {
    return null;
  }

  const { status, userStatus } = submissionStatus;
  const clearStatus = () => dispatch(clearSubmissionStatus());

  switch (status) {
    case APPROVED:
      return <ApprovedNotification onClose={clearStatus} ref={notificationRef} />;
    case PENDING:
      return <PendingNotification onClose={clearStatus} ref={notificationRef} />;
    case USER_BANNED:
      return <UserBannedNotification onClose={clearStatus} ref={notificationRef} />;
    case USER_SUSPENDED:
      return (
        <UserSuspendedNotification
          onClose={clearStatus}
          suspendedUntil={userStatus.suspension.until}
          ref={notificationRef}
        />
      );
    default:
      return null;
  }
};

export default SubmissionNotification;
