// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Enums
import { ValidationTypes } from 'engagement/enums/singleTextBox';

// Components
import Input from 'common/components/Input';

const TextInput = (props) => {
  const { id, label, placeholder, value, validationType, characterCountMax, onChange } = props;

  const inputFormId = `input-${id}`;
  const showCharacterCount = validationType === ValidationTypes.CHARACTER_COUNT;

  return (
    <div className="field">
      <label className="label visuallyhidden" htmlFor={inputFormId}>
        {label}
      </label>
      <div className="control">
        <Input
          id={inputFormId}
          data-test="text-input"
          name={inputFormId}
          placeholder={placeholder}
          defaultValue={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
      {showCharacterCount && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          {`${value.length}/${characterCountMax}`}
        </div>
      )}
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  validationType: PropTypes.string,
  characterCountMax: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TextInput.defaultProps = {
  value: '',
  placeholder: '',
  label: '',
  validationType: '',
  characterCountMax: '',
};

export default TextInput;
