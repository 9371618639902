// Libraries
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Marker as LeafletMarker, Popup } from 'react-leaflet';
import { defer } from 'underscore';
import { withTranslation } from 'react-i18next';

// Assets
import { markerIcon } from 'assets';

// Components
import Textarea from 'common/components/Textarea';

/**
 * Wrapper for Leaflet Marker component.
 */
export class Marker extends Component {
  markerRef = createRef();

  textareaRef = createRef();

  shouldComponentUpdate(nextProps) {
    const { popupVisible } = this.props;

    // only rerender component if popupVisible prop has changed - fix for popup losing focus in IE10
    if (popupVisible !== nextProps.popupVisible) {
      return true;
    }

    return false;
  }

  /**
   * Open/close popup based on popupVisible prop.
   */
  componentDidUpdate() {
    const { popupVisible } = this.props;

    if (popupVisible) {
      this.markerRef.current?.openPopup();
    } else {
      this.markerRef.current?.closePopup();
    }
  }

  handleMarkerAdd = () => {
    const { popupVisible } = this.props;

    if (popupVisible) {
      this.markerRef.current?.openPopup();
    }
  };

  /**
   * Popup opened handler.
   */
  handlePopupOpen = () => {
    defer(this.focusTextarea);
  };

  /**
   * Focus textarea field.
   */
  focusTextarea = () => {
    this.textareaRef.current?.focus();
  };

  render() {
    const {
      position,
      comment,
      onDragStart,
      onDragEnd,
      onMouseOver,
      onMouseOut,
      onClick,
      onCommentChange,
      onPopupClose,
      onDelete,
      t,
    } = this.props;

    return (
      <LeafletMarker
        draggable
        position={position}
        icon={markerIcon}
        riseOnHover
        ref={this.markerRef}
        eventHandlers={{
          click: onClick,
          dragstart: onDragStart,
          dragend: onDragEnd,
          mouseover: onMouseOver,
          mouseout: onMouseOut,
          add: this.handleMarkerAdd,
        }}
      >
        <Popup
          className="map-input-popup"
          autoPan={false}
          autoClose={false}
          closeButton={false}
          onOpen={this.handlePopupOpen}
        >
          <a
            className="close is-primary-color"
            title={t('engagement.close_popup')}
            onClick={onPopupClose}
          >
            <i className="fas fa-times" />
          </a>
          <label className="popup-title is-primary-color" htmlFor="popup-textarea">
            {t('engagement.pin_placement_question')} ({t('engagement.optional')})
          </label>
          <Textarea
            id="popup-textarea"
            defaultValue={comment}
            onChange={onCommentChange}
            onClick={this.focusTextarea}
            ref={this.textareaRef}
          />
          <a onClick={onDelete} className="is-primary-color">
            {t('engagement.delete_pin')}
          </a>
        </Popup>
      </LeafletMarker>
    );
  }
}

Marker.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  comment: PropTypes.string,
  popupVisible: PropTypes.bool.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCommentChange: PropTypes.func.isRequired,
  onPopupClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Marker.defaultProps = {
  comment: '',
};

export default withTranslation()(Marker);
