// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { renderMarkdown, determineImageSize } from 'common/utils/helpers';

// Components
import YoutubeEmbedder from 'common/components/YoutubeEmbedder';
import Link from 'common/components/Link';
import NotificationsSignup from 'common/components/NotificationsSignup';

const AnnouncementDetails = ({
  announcement,
  heroImage,
  video,
  project: { notificationSignUpEnabled, title },
  t,
}) => (
  <div className="announcement__details">
    {announcement.longDescription && (
      <div
        className="description markdown"
        dangerouslySetInnerHTML={{ __html: renderMarkdown(announcement.longDescription) }}
      />
    )}

    {heroImage && heroImage.imageUrls && (
      <img
        src={determineImageSize(heroImage.imageUrls)}
        alt={heroImage.altText}
        title={heroImage.caption}
      />
    )}

    {video && video.youtubeId && <YoutubeEmbedder id={video.youtubeId} />}

    <br />

    {announcement.ctaButtonText && (
      <Link
        href={announcement.ctaButtonLink}
        button
        className="is-secondary-color is-primary-outline announcement__cta"
      >
        {announcement.ctaButtonText}
      </Link>
    )}
    <br />
    <br />
    {notificationSignUpEnabled && (
      <div className="signup announcement__signup">
        <div className="signup__body announcement__signup-body">
          <h2 className="signup__title title is-primary-color">{t('project.stay_informed')}</h2>
          <p className="signup__message announcement__signup-message">
            {t('project.get_notified_message')}
          </p>
        </div>
        <NotificationsSignup title={title} />
      </div>
    )}
  </div>
);

AnnouncementDetails.propTypes = {
  announcement: PropTypes.shape({
    longDescription: PropTypes.string,
    ctaButtonText: PropTypes.string,
    ctaButtonLink: PropTypes.string,
  }).isRequired,
  heroImage: PropTypes.shape({
    imageUrls: PropTypes.shape({}),
    altText: PropTypes.string,
    caption: PropTypes.string,
  }),
  video: PropTypes.shape({
    youtubeId: PropTypes.string,
  }),
  project: PropTypes.shape({
    notificationSignUpEnabled: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

AnnouncementDetails.defaultProps = {
  heroImage: { imageUrls: {} },
  video: { youtubeId: null },
};

export default AnnouncementDetails;
