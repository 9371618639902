// Libraries
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import sortBy from 'lodash/sortBy';

// State
import { upsertResponseEntry } from 'state/ducks/engagement';

// Utilities
import { parseInteger } from 'common/utils/helpers';

// Components
import MatrixInput from './MatrixInput';

export function MatrixQuestion(props) {
  const { question, currentQuestionValues, dispatchUpdateQuestionValue } = props;
  const { inputs, weights } = question;
  const questionValue = currentQuestionValues?.[question.id] || {};
  const dispatch = useDispatch();

  const options = useMemo(() => {
    return sortBy(weights, ['position']).map((weight) => ({
      ...weight,
      id: parseInteger(weight.id),
    }));
  }, [weights]);

  const onChange = (inputId, value) => {
    dispatchUpdateQuestionValue(question.id, { [inputId]: { value } }, true);
    dispatch(upsertResponseEntry({ inputId, questionId: question.id, weightId: value }));
  };

  return (
    <div className="matrix-question" data-test="matrix-question">
      <MatrixInput options={options} rows={inputs} onChange={onChange} values={questionValue} />
    </div>
  );
}

MatrixQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    weights: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  currentQuestionValues: PropTypes.shape({}),
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
};

MatrixQuestion.defaultProps = {
  currentQuestionValues: {},
};

export default MatrixQuestion;
