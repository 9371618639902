// Libraries
import { useEffect, useRef } from 'react';

/**
 * Hook for scrolling an element into view on every `dependency` truthy change
 *
 * @param {any} dependency
 * @returns {React.RefObject}
 */
export function useScrollIntoView(dependency) {
  const ref = useRef();

  useEffect(() => {
    if (dependency) {
      ref.current?.scrollIntoView();
    }
  }, [dependency]);

  return ref;
}
