// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ModerationStatuses, { PENDING, REJECTED } from 'common/enums/ModerationStatuses';

const WarningMessage = (props) => {
  const { status } = props;
  const { t } = useTranslation();

  if (status !== PENDING && status !== REJECTED) return null;

  const getWarningMsg = () => {
    switch (status) {
      case PENDING:
        return 'ideation.pending_idea_msg';
      case REJECTED:
        return 'ideation.rejected_idea_msg';
      default:
        return '';
    }
  };

  return (
    <div className="idea-card__warning-message">
      <div>
        <span
          className={classNames('icon', {
            'has-text-info': status === PENDING,
            'has-text-danger': status === REJECTED,
          })}
        >
          <i className="fas fa-exclamation-circle" />
        </span>
      </div>
      <div>
        <p>{t(getWarningMsg())}</p>
      </div>
    </div>
  );
};

WarningMessage.propTypes = {
  status: PropTypes.oneOf(Object.values(ModerationStatuses)).isRequired,
};

export default WarningMessage;
