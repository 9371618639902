// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { storyTheme } from 'common/utils/helpers';

const Hero = ({ title, subheading, backgroundImageUrl, backgroundImagePosition }) => (
  <div
    id="page-hero"
    className="hero is-primary is-large project-hero"
    style={{
      backgroundImage: backgroundImageUrl && `url("${encodeURI(backgroundImageUrl)}")`,
      backgroundSize: 'cover',
      backgroundPosition: `${backgroundImagePosition} center`,
    }}
    role="region"
  >
    <div className="hero-body project-hero__body">
      {storyTheme() && (
        <>
          <div className="project-hero__headings container inner">
            <h1 className="project-hero__title">{title}</h1>
            {subheading && <h2 className="project-hero__subheading">{subheading}</h2>}
          </div>
          <div className="project-hero__line container inner" />
        </>
      )}
    </div>
  </div>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  backgroundImagePosition: PropTypes.string,
};

Hero.defaultProps = {
  subheading: null,
  backgroundImageUrl: '',
  backgroundImagePosition: 'center',
};

export default Hero;
