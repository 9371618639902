// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import BudgetCheckbox from '../BudgetCheckbox';

class BudgetCheckboxGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: new Set(props.initialValue),
    };
  }

  getOptionClickHandler = (id) => () => {
    const { onChange } = this.props;
    const { selectedOptions } = this.state;

    if (selectedOptions.has(id)) {
      selectedOptions.delete(id);
    } else {
      selectedOptions.add(id);
    }

    const newSelectedOptions = new Set(selectedOptions);

    this.setState({
      selectedOptions: newSelectedOptions,
    });

    if (onChange) {
      onChange([...newSelectedOptions], id);
    }
  };

  renderOption = (option) => {
    const { selectedOptions } = this.state;
    const { id } = option;
    const checked = selectedOptions.has(id);

    return (
      <BudgetCheckbox
        clickHandler={this.getOptionClickHandler}
        option={option}
        checked={checked}
        key={id}
      />
    );
  };

  render() {
    const { options } = this.props;
    return <tbody className="checkbox-group">{options.map(this.renderOption)}</tbody>;
  }
}

BudgetCheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  onChange: PropTypes.func,
};

BudgetCheckboxGroup.defaultProps = {
  initialValue: [],
  onChange: null,
};

export default BudgetCheckboxGroup;
