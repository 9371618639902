// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ZCDPopup } from '@zencity/common-ui';
import toast from 'react-hot-toast';

// Utils
import { rtlLanguage } from 'common/utils/helpers';

// Enums
import { COMMENT_OFFENSIVE, COMMENT_OTHER, COMMENT_SPAM } from 'discussion/enums';

// Components
import CommentInputToast from 'discussion/components/CommentInput/CommentInputToast';

const ReportButton = (props) => {
  const {
    userDiscussionReportId,
    discussionReportsCount,
    reportDiscussionAction,
    disabled,
    commentId,
  } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const showFlaggedMessage = () => {
    toast.custom(
      (toastObj) => (
        <CommentInputToast id={toastObj.id}>
          <p>{t('ideation.flagged_msg')}</p>
        </CommentInputToast>
      ),
      {
        position: 'bottom-center',
        duration: 2000,
      }
    );
  };

  if (userDiscussionReportId) {
    return (
      <button aria-label={t('ideation.report_button')} type="button">
        <i className="has-text-danger fas fa-flag" onClick={showFlaggedMessage} />
      </button>
    );
  }

  return (
    <ZCDPopup
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      placement={rtlLanguage() ? 'right-end' : 'left-end'}
      triggerElement={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <button
          aria-label={t('ideation.report_button')}
          type="button"
          disabled={disabled}
          style={{ marginRight: '0px' }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          aria-controls={`reportmenu_${commentId}`}
        >
          <i
            className={classNames(
              {
                far: !discussionReportsCount,
                'has-text-danger fas': discussionReportsCount,
              },
              'fa-flag'
            )}
          />
        </button>
      }
    >
      <div className="box" role="menu" id={`reportmenu_${commentId}`}>
        <button
          className="flag-idea-button"
          type="button"
          disabled={disabled}
          onClick={() => reportDiscussionAction(COMMENT_OFFENSIVE.id, COMMENT_OFFENSIVE.body)}
          role="menuitem"
        >
          {t('ideation.report_offensive')}
        </button>
        <button
          className="flag-idea-button"
          type="button"
          disabled={disabled}
          onClick={() => reportDiscussionAction(COMMENT_SPAM.id, COMMENT_SPAM.body)}
          role="menuitem"
        >
          {t('ideation.report_spam')}
        </button>
        <button
          className="flag-idea-button"
          type="button"
          disabled={disabled}
          onClick={() => reportDiscussionAction(COMMENT_OTHER.id, COMMENT_OTHER.body)}
          role="menuitem"
        >
          {t('ideation.report_other')}
        </button>
      </div>
    </ZCDPopup>
  );
};

ReportButton.propTypes = {
  userDiscussionReportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  discussionReportsCount: PropTypes.number.isRequired,
  reportDiscussionAction: PropTypes.func,
  disabled: PropTypes.bool,
  commentId: PropTypes.string.isRequired,
};

ReportButton.defaultProps = {
  userDiscussionReportId: null,
  reportDiscussionAction: () => {},
  disabled: false,
};

export default ReportButton;
