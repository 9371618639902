// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

// Enums
import * as sorts from 'ideation/enums/sortOrder';

const IdeaBoardSort = ({ handleSortClick, activeTab }) => {
  const { t } = useTranslation();
  const sortTabs = Object.keys(sorts).map((key) => (
    <li
      key={key}
      className={classNames({ 'is-active': activeTab === key })}
      role="menuitem"
      tabIndex={0}
      onClick={() => handleSortClick(key)}
    >
      {t(sorts[key].label)}
    </li>
  ));

  return (
    <div className="container inner inner--narrow">
      <div className="tabs idea-board">
        <ul role="menu">{sortTabs}</ul>
      </div>
    </div>
  );
};

IdeaBoardSort.propTypes = {
  handleSortClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
};

IdeaBoardSort.defaultProps = {
  activeTab: 'MOST_POPULAR',
};
export default IdeaBoardSort;
