// Libraries
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

// Routes
import routes from 'routes';

// Utilities
import { populatePath } from 'common/utils/helpers';

const FinishedProjects = (props) => {
  const { projects } = props;
  const params = useParams();
  const { t } = useTranslation();

  const txtViewProject = t('project.view_project');
  const getProjectUrl = (projectId) => populatePath(routes.PROJECT, { ...params, projectId });

  const projectElements = useMemo(() => {
    return projects.map((project) => (
      <div key={project.id} className="project-tile">
        <div className="project-tile__image">
          {project.heroImage?.imageUrls && (
            <img
              src={project.heroImage.imageUrls.card}
              alt={project.heroImage.altText}
              title={project.heroImage.caption}
              style={{ objectPosition: project?.heroImage?.objectPosition || 'center' }}
            />
          )}
        </div>
        <div className="project-tile__body">
          <div>
            <div className="project-tile__body__category">
              {project.category?.title || <span>&nbsp;</span>}
            </div>
            <div className="project-tile__body__info">
              <h3
                className={classNames({
                  'single-line': !!project.teaser,
                })}
              >
                {project.title}
              </h3>
              {project.teaser && <p>{project.teaser}</p>}
            </div>
          </div>
          <div className="project-tile__body__actions">
            <a
              href={getProjectUrl(project.slug || project.id)}
              className="button is-primary is-outlined"
            >
              {txtViewProject}
            </a>
          </div>
        </div>
      </div>
    ));
  }, [projects]);

  return projectElements;
};

FinishedProjects.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FinishedProjects;
