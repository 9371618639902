// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

// Routes
import Routes from 'routes';

// Utilities
import { populatePath, renderMarkdown } from 'common/utils/helpers';

const DiscussionTopicHeader = ({
  title,
  description,
  match,
  backgroundImageUrl,
  backgroundImagePosition,
  projectPublic,
  single,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('discussion-topic-header', {
        'discussion-topic-header--image': backgroundImageUrl,
        'discussion-topic-header--single': single,
      })}
      style={{
        backgroundImage: backgroundImageUrl && `url("${backgroundImageUrl}")`,
        backgroundPosition: `${backgroundImagePosition || 'center'}`,
      }}
      role="banner"
      aria-label={title}
    >
      {backgroundImageUrl && <div className="discussion-topic-header--dim" />}
      {projectPublic && (
        <div className="container inner">
          <a href={populatePath(Routes.PROJECT, match.params)} className="back-link">
            {t('ideation.project_page')}
          </a>
        </div>
      )}
      <div className="discussion-topic-header__inner container inner inner--narrow">
        <h1 className="discussion-topic-header__title title is-1 has-text-white">{title}</h1>
        {description && (
          <div className="discussion-topic-header__content has-text-white has-text-weight-bold">
            <h2
              className="introduction markdown"
              dangerouslySetInnerHTML={{ __html: renderMarkdown(description) }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

DiscussionTopicHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  backgroundImagePosition: PropTypes.string,
  projectPublic: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string).isRequired,
  }).isRequired,
  single: PropTypes.bool,
};

DiscussionTopicHeader.defaultProps = {
  backgroundImageUrl: '',
  backgroundImagePosition: 'center',
  description: '',
  single: null,
};

export default withRouter(DiscussionTopicHeader);
