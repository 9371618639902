// Libraries
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

// Utilities
import { classicTheme, generateHtmlIdForLabel, toggleArrayValue } from 'common/utils/helpers';

// Components
import CheckBox from 'common/components/CheckBox';

/**
 * Checkbox group component. Used to render multiple checkboxes in a group.
 */
const CheckboxGroup = ({ options, initialValue, value, onChange }) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ value: initialValue, lastId: null });

  const checkboxOnChange = (id) => () => {
    if (value) {
      onChange(id);
    } else {
      setState((currentState) => ({
        value: toggleArrayValue(currentState.value, id),
        lastId: id,
      }));
    }
  };

  useEffect(() => {
    if (!value && state.value !== initialValue) {
      onChange(state.value, state.lastId);
    }
  }, [state]);

  return (
    <div className="checkbox-group" role="group">
      {options.map(({ label, id }) => (
        <div key={id} className="checkbox">
          <label
            className="checkbox__control-label"
            htmlFor={generateHtmlIdForLabel(`${label}-${id}`)}
          >
            <CheckBox
              id={generateHtmlIdForLabel(`${label}-${id}`)}
              checked={(value || state.value).includes(id)}
              onChange={checkboxOnChange(id)}
            />
            <div className="checkbox__control-display" />
            <span
              className={classNames('checkbox__control-text', {
                'is-primary-color': classicTheme(),
              })}
            >
              {t(label, { defaultValue: label })}
            </span>
          </label>
        </div>
      ))}
    </div>
  );
};

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  onChange: PropTypes.func,
};

CheckboxGroup.defaultProps = {
  initialValue: [],
  value: null,
  onChange: () => {},
};

export default CheckboxGroup;
