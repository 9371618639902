// Libraries
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import Notification from 'common/components/Notification';

const PendingNotification = forwardRef(({ onClose }, ref) => {
  const { t } = useTranslation();

  return (
    <Notification
      className="idea-submission-notification"
      title={t('ideation.idea_pending_notification_title')}
      onClose={onClose}
      ref={ref}
      isInfo
    >
      {t('ideation.idea_pending_notification')}
    </Notification>
  );
});

PendingNotification.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PendingNotification;
