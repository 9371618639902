// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageOverlay } from 'react-leaflet';
import { CRS } from 'leaflet';

// Utilities
import { getImageDimensions } from 'common/utils/helpers';

// Components
import AnnotationInput from '../AnnotationInput';

/**
 * Image annotation input component.
 */
export class ImageAnnotationInput extends Component {
  static propTypes = {
    initialMarkers: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Marker's position as [y, x].
         */
        position: PropTypes.arrayOf(PropTypes.number).isRequired,
        /**
         * Marker's description.
         */
        description: PropTypes.string,
      })
    ),
    /**
     * Image URL.
     */
    imageUrl: PropTypes.string.isRequired,
    /**
     * Map padding (how far can user drag the map outside of image) in pixels.
     */
    padding: PropTypes.number,
    /**
     * Initial map position. Used when no initial markers are passed.
     */
    initialPosition: PropTypes.arrayOf(PropTypes.number),
    /**
     * Initial zoom.
     */
    initialZoom: PropTypes.number,
    /**
     * (Optional) Callback to run whenever user interacts with markers.
     * Array of marker objects is passed as first argument.
     */
    onChange: PropTypes.func,
  };

  static defaultProps = {
    initialMarkers: [],
    initialPosition: null,
    initialZoom: 0,
    onChange: null,
    padding: 500,
  };

  constructor(props) {
    super(props);

    this.state = {
      imageLoaded: false,
    };

    this.mapProps = {
      crs: CRS.Simple,
      minZoom: -2,
      bounds: null,
      maxBounds: null,
      center: null,
      zoomSnap: 0,
      zoomDelta: 0.5,
    };

    getImageDimensions(props.imageUrl).then(({ width, height }) => {
      const { padding, initialPosition } = props;

      this.mapProps.bounds = [
        [0, 0],
        [height, width],
      ];
      this.mapProps.maxBounds = [
        [-padding, -padding],
        [height + padding, width + padding],
      ];
      this.mapProps.center = initialPosition || [height / 2, width / 2];

      this.setState({
        imageLoaded: true,
      });
    });
  }

  render() {
    const { imageUrl, padding, initialPosition, ...props } = this.props;
    const { imageLoaded } = this.state;

    if (!imageLoaded) {
      return null;
    }

    return (
      <div className="image-annotation-input">
        <AnnotationInput {...props} mapProps={this.mapProps}>
          <ImageOverlay url={imageUrl} bounds={this.mapProps.bounds} />
        </AnnotationInput>
      </div>
    );
  }
}

export default ImageAnnotationInput;
