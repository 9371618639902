// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { arrayMove } from 'react-sortable-hoc';
import { useMediaQuery } from 'react-responsive';

// Media Queries
import mediaQueries from 'common/utils/mediaQueries';

// Components
import SortableList from './SortableList';
import MobileSortableList from './MobileSortableList';

function SortableInput(props) {
  const { initialItems, onChange } = props;
  const [items, setItems] = useState(initialItems.slice());
  const [itemHeights, setItemHeights] = useState([]);
  const isMobile = useMediaQuery({ ...mediaQueries.mobile });

  const onSortOver = ({ oldIndex, newIndex }) => {
    setItemHeights(arrayMove(itemHeights, oldIndex, newIndex));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(items, oldIndex, newIndex);
    setItems(newItems);
    onChange?.(newItems.map((item) => item.id));
  };

  const renderContent = () => {
    if (isMobile)
      return <MobileSortableList items={items} orderedItems={items} onSortEnd={onSortEnd} />;

    return (
      <SortableList
        items={items}
        itemHeights={itemHeights}
        setItemHeights={setItemHeights}
        onSortEnd={onSortEnd}
        onSortEndKeyboard={onSortEnd}
        onSortOver={onSortOver}
      />
    );
  };

  return <div className="sortable-input">{renderContent()}</div>;
}

SortableInput.propTypes = {
  initialItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      imageUrl: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func,
};

SortableInput.defaultProps = {
  onChange: null,
};

export default SortableInput;
