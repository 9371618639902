// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import IdeaCard from 'ideation/components/ideaBoard/IdeaCard';

// Enums
import { IdeaType } from 'ideation/enums/propTypes';
import { useTranslation } from 'react-i18next';

const IdeaCards = ({ publicStatus, ideaBoardId, data, mutateIdea }) => {
  const { t } = useTranslation();

  return (
    <section className="idea-board-items">
      <div className="tile is-ancestor">
        {!data[0]?.deserialized.length && (
          <div className="tile is-parent is-12">
            <h2 className="tile is-child has-text-centered is-secondary-color is-size-4 has-text-weight-bold">
              {t('ideation.no_ideas_yet')}
            </h2>
          </div>
        )}
        {data.map((page, pageIndex) =>
          page.deserialized.map((idea, ideaIndex) => (
            <IdeaCard
              key={idea.id}
              idea={idea}
              ideaBoardId={ideaBoardId}
              publicStatus={publicStatus}
              onIdeaUpdate={(updatedIdea) => mutateIdea(pageIndex, ideaIndex, updatedIdea)}
            />
          ))
        )}
      </div>
    </section>
  );
};

IdeaCards.propTypes = {
  ideaBoardId: PropTypes.number.isRequired,
  publicStatus: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      deserialized: PropTypes.arrayOf(IdeaType),
    })
  ).isRequired,
  mutateIdea: PropTypes.func.isRequired,
};

export default IdeaCards;
