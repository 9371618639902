// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utilities
import { formatUnitValue } from 'common/utils/helpers';

function OpenBudgetComparison({
  value,
  comparisonValue,
  unitLabel,
  comparisonValueLabel,
  unitLayout,
}) {
  if (comparisonValue === 0) return null;

  const change = Math.round((value / comparisonValue - 1) * 10000) / 100;
  const changePercentage = `${change > 0 ? '+' : ''}${change}%`;

  return (
    <div className="open-budget-comparison">
      <div className="open-budget-comparison__value">
        <span className={classNames({ 'is-hidden-tablet': unitLayout === 'header' })}>
          {comparisonValueLabel}{' '}
        </span>
        {formatUnitValue(comparisonValue, unitLabel)}
      </div>
      <div
        className={classNames('open-budget-comparison__change', {
          'open-budget-comparison__change--positive': change > 0,
          'open-budget-comparison__change--negative': change < 0,
        })}
      >
        {changePercentage}
        {change !== 0 && (
          <i
            className={classNames('open-budget-comparison__change-icon fas', {
              'fa-long-arrow-alt-up': change > 0,
              'fa-long-arrow-alt-down': change < 0,
            })}
          />
        )}
      </div>
    </div>
  );
}

OpenBudgetComparison.propTypes = {
  value: PropTypes.number.isRequired,
  comparisonValue: PropTypes.number.isRequired,
  unitLabel: PropTypes.string.isRequired,
  comparisonValueLabel: PropTypes.string.isRequired,
  unitLayout: PropTypes.string.isRequired,
};

export default OpenBudgetComparison;
