// Libraries
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Waypoint } from 'react-waypoint';
import useSWRInfinite from 'swr/infinite';
import { useTranslation } from 'react-i18next';
import camelcase from 'lodash.camelcase';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// I18n
import { currentLanguage } from 'i18n';

// Api
import { getIdeasFetcher, getIdeasUrl } from 'api/v3/private';

// Components
import Spinner from 'common/components/Spinner';
import Header from 'header/components/Header';
import Footer from 'common/components/Footer';
import SocialFooter from 'common/components/SocialFooter';
import NotificationsSignup from 'common/components/NotificationsSignup';
import CreateIdeaModal from 'ideation/components/createIdea/CreateIdeaModal';
import IdeaBoardActions from 'ideation/components/ideaBoard/IdeaBoardActions';
import IdeaBoardHeader from 'ideation/components/ideaBoard/IdeaBoardHeader';
import IdeaBoardSearch from 'ideation/components/ideaBoard/IdeaBoardSearch';
import IdeaBoardSort from 'ideation/components/ideaBoard/IdeaBoardSort';
import IdeaCards from 'ideation/components/ideaBoard/IdeaCards';
import SubmissionNotification from 'ideation/components/ideaBoard/SubmissionNotification';
import ClosedNotification from 'ideation/components/ideaBoard/ClosedNotification';

// Utils
import { determineImageSize, featureFlagEnabled } from 'common/utils/helpers';
import { userAuthenticated } from 'ideation/utils/userAuthenticate';
import { authorizeAction } from 'ideation/utils/userAuthorize';

// Enums
import * as sorts from 'ideation/enums/sortOrder';
import PublicStatus from 'common/enums/PublicStatus';
import { IdeaBoardType } from 'ideation/enums/propTypes';

// Constants
const IDEA_LIMIT = 24;

const IdeaBoard = ({ ideaBoard }) => {
  const { t } = useTranslation();
  const { register, reset: resetForm, handleSubmit, setValue } = useForm();
  const [sort, setSort] = useState(sorts.MOST_POPULAR);
  const [searchValue, setSearchValue] = useState('');
  const [volume, setVolume] = useState(0);
  const [displayCreateIdeaModal, setDisplayCreateIdeaModal] = useState(
    Boolean(userAuthenticated() && localStorage.getItem('createIdeaModalTitle'))
  );
  const language = camelcase(currentLanguage);
  const { project } = ideaBoard;
  const { listening, transcript, finalTranscript, resetTranscript } = useSpeechRecognition();
  const speechRecognitionEnabled = featureFlagEnabled('speech_recognition');

  const { data, size, setSize, mutate } = useSWRInfinite(
    (pageIndex, previousPageData) => {
      if (previousPageData && !previousPageData.links.next) return null;

      const params = {
        page: { number: pageIndex + 1, size: IDEA_LIMIT },
        sort: sort.param,
        filter: searchValue,
        include: 'hero_image',
      };

      return [getIdeasUrl(ideaBoard.id, params), ideaBoard.id];
    },
    getIdeasFetcher,
    { revalidateAll: true }
  );

  const resetSearch = () => {
    setSearchValue('');
    resetForm();
  };

  const mutateIdea = (pageIndex, ideaIndex, updatedIdea) => {
    mutate((cachedData) =>
      Object.assign([...cachedData], {
        [pageIndex]: {
          ...cachedData[pageIndex],
          deserialized: Object.assign([...cachedData[pageIndex].deserialized], {
            [ideaIndex]: updatedIdea,
          }),
        },
      })
    );
  };

  const renderIdeas = () => {
    if (!data) {
      return (
        <div className="padded-spinner">
          <Spinner />
        </div>
      );
    }

    const ideasFetched = Boolean(data.length && !data[data.length - 1].links.next);

    return (
      <>
        <IdeaCards
          publicStatus={ideaBoard.publicStatus}
          ideaBoardId={Number(ideaBoard.id)}
          data={data}
          mutateIdea={mutateIdea}
        />
        {!ideasFetched && (
          <Waypoint bottomOffset="-100%" onEnter={() => setSize(size + 1)} key={data.length} />
        )}
      </>
    );
  };

  const openModal = () => {
    const authenticated = userAuthenticated();
    if (!authenticated) localStorage.setItem('createIdeaModalTitle', searchValue);
    authorizeAction(authenticated, () => setDisplayCreateIdeaModal(true), window.location.pathname);
  };

  const closeModal = () => {
    setDisplayCreateIdeaModal(false);
    localStorage.removeItem('createIdeaModalTitle');
    resetTranscript();
  };

  useEffect(() => {
    if (!speechRecognitionEnabled) return;

    let frameRequestId = 0;
    let stream;

    const onStart = async () => {
      window.scrollTo(0, 0);
      document.documentElement.classList.add('is-clipped');
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      const context = new AudioContext();
      const source = context.createMediaStreamSource(stream);
      const analyser = context.createAnalyser();
      const frequencyData = new Uint8Array(analyser.frequencyBinCount);
      source.connect(analyser);

      const calculateVolume = () => {
        analyser.getByteFrequencyData(frequencyData);
        const squaredSum = frequencyData.reduce((sum, value) => sum + value ** 2);
        return Math.sqrt(squaredSum / frequencyData.length) / 255;
      };

      const frame = () => {
        setVolume(calculateVolume());
        frameRequestId = window.requestAnimationFrame(frame);
      };

      frame();
    };

    const onEnd = () => {
      document.documentElement.classList.remove('is-clipped');
      window.cancelAnimationFrame(frameRequestId);
      stream?.getTracks().forEach((track) => track.stop());
      setVolume(0);
    };

    const recognition = SpeechRecognition.getRecognition();
    recognition.addEventListener('start', onStart);
    recognition.addEventListener('end', onEnd);
  }, []);

  useEffect(() => {
    if (listening) {
      setValue('ideaSearch', transcript);
    } else {
      setValue('ideaSearch', '');
      if (finalTranscript) openModal();
    }
  }, [transcript, finalTranscript, listening]);

  return (
    <div className="idea-board">
      <CreateIdeaModal
        setSort={setSort}
        resetSearchValues={resetSearch}
        searchValue={searchValue || localStorage.getItem('createIdeaModalTitle')}
        ideaBoardId={ideaBoard.id}
        projectId={project.id}
        allowDescription={ideaBoard.allowDescription}
        allowImage={ideaBoard.allowImage}
        isOpen={displayCreateIdeaModal}
        onClose={closeModal}
        finalTranscript={finalTranscript}
      />
      <Header ideation ideaBoard={ideaBoard} showAuth />
      <main id="main">
        <IdeaBoardHeader
          title={ideaBoard.titleTranslations[language]}
          description={ideaBoard.longDescriptionTranslations[language]}
          backgroundImageUrl={
            ideaBoard.heroImage?.imageUrls &&
            encodeURI(determineImageSize(ideaBoard.heroImage.imageUrls))
          }
          backgroundImagePosition={ideaBoard?.heroImage?.objectPosition}
          projectPublic={ideaBoard.project.public}
        />
        <IdeaBoardSearch
          publicStatus={ideaBoard.publicStatus}
          register={register}
          searchValue={searchValue}
          reset={resetSearch}
          handleSubmit={handleSubmit((values) => setSearchValue(values.ideaSearch))}
          searchSubmitted={Boolean(searchValue)}
          listening={listening}
          volume={volume}
        />
        {!searchValue && (
          <IdeaBoardSort
            handleSortClick={(sortTab) => setSort(sorts[sortTab])}
            activeTab={sort.sortTab}
          />
        )}
        <section className="container inner">
          <div className="idea-board__content content-container" id="idea-board-content">
            {ideaBoard.publicStatus !== PublicStatus.CLOSED && searchValue && (
              <IdeaBoardActions openModal={openModal} />
            )}
            {ideaBoard.publicStatus === PublicStatus.CLOSED && <ClosedNotification />}
            <SubmissionNotification />
            {renderIdeas()}
          </div>
          {project && project.notificationSignUpEnabled && (
            <div className="signup">
              <div className="signup__body">
                <h2 className="signup__title title is-primary-color">
                  {t('project.stay_informed')}
                </h2>
                <p className="signup__message">{t('project.get_notified_message')}</p>
              </div>
              <NotificationsSignup title={ideaBoard.project.title} />
            </div>
          )}
        </section>
      </main>
      <SocialFooter project={ideaBoard.project} />
      <Footer />
      {listening && <div className="idea-board__listening-overlay" />}
    </div>
  );
};

IdeaBoard.propTypes = {
  ideaBoard: IdeaBoardType.isRequired,
};

export default IdeaBoard;
