// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

// State
import {
  getCurrentQuestionValues,
  getCurrentSectionPosition,
  getQuestionErrors,
  getQuestionValues,
  updateQuestionValue,
} from 'state/ducks/engagement';

//  Enumerables
import { Validations } from 'engagement/enums/singleTextBox';
import QuestionFormat from 'engagement/enums/QuestionFormat';
import Rules from 'engagement/enums/Rules';

// Components
import BasicQuestion from './questions/BasicQuestion';
import SingleSelectQuestion from './questions/SingleSelectQuestion';
import MultiSelectQuestion from './questions/MultiSelectQuestion';
import DropdownQuestion from './questions/DropdownQuestion';
import MatrixQuestion from './questions/MatrixQuestion';
import GeolocationQuestion from './questions/GeolocationQuestion';
import OrderedQuestion from './questions/OrderedQuestion';
import ImageQuestion from './questions/ImageQuestion';
import BudgetQuestion from './questions/Budget/BudgetQuestion';
import NumericQuestion from './questions/NumericQuestion';
import SliderQuestion from './questions/SliderQuestion';
import RatingQuestion from './questions/RatingQuestion';
import OpenBudgetQuestion from './questions/OpenBudgetQuestion';

const questionComponentMap = {
  [QuestionFormat.BASIC]: BasicQuestion,
  [QuestionFormat.SINGLE_SELECT]: SingleSelectQuestion,
  [QuestionFormat.MULTI_SELECT]: MultiSelectQuestion,
  [QuestionFormat.ORDERED]: OrderedQuestion,
  [QuestionFormat.MATRIX]: MatrixQuestion,
  [QuestionFormat.GEOLOCATION]: GeolocationQuestion,
  [QuestionFormat.IMAGE]: ImageQuestion,
  [QuestionFormat.BUDGET]: BudgetQuestion,
  [QuestionFormat.DROPDOWN]: DropdownQuestion,
  [QuestionFormat.NUMERIC]: NumericQuestion,
  [QuestionFormat.SLIDER]: SliderQuestion,
  [QuestionFormat.RATING]: RatingQuestion,
  [QuestionFormat.BUDGET_OPEN]: OpenBudgetQuestion,
};

const renderQuestion = (
  question,
  questionValues,
  currentSectionPosition,
  dispatchUpdateQuestionValue,
  currentQuestionValues
) => {
  if (questionComponentMap[question.format]) {
    const QuestionComponent = questionComponentMap[question.format];
    return (
      <QuestionComponent
        question={question}
        questionValues={questionValues}
        currentSectionPosition={currentSectionPosition}
        dispatchUpdateQuestionValue={dispatchUpdateQuestionValue}
        currentQuestionValues={currentQuestionValues}
      />
    );
  }

  return null;
};

const renderErrors = (question, questionErrors, t) => {
  const errors = questionErrors[question.id]?.map((error) => {
    switch (error) {
      case Rules.REQUIRED:
        return <p key={question.id + error}>{t('engagement.errors.question_required_error')}</p>;
      case Rules.ALL_ROWS_REQUIRED:
        return <p key={question.id + error}>{t('engagement.errors.all_rows_required_error')}</p>;
      case Rules.BUDGET:
        return <p key={question.id + error}>{t('engagement.errors.over_budget_error')}</p>;
      case Rules.NUMBER:
        return <p key={question.id + error}>{t('engagement.errors.number_error')}</p>;
      case Rules.NUMBER_RANGE: {
        const { rangeStart, rangeEnd } = question.displayData;
        return (
          <p key={question.id + error}>
            {t('engagement.errors.number_range_error', { rangeStart, rangeEnd })}
          </p>
        );
      }
      case Rules.LENGTH_RANGE: {
        const { characterCountMin, characterCountMax } = question.displayData;
        return (
          <p key={question.id + error}>
            {t('engagement.errors.length_range_error', {
              characterCountMin,
              characterCountMax,
            })}
          </p>
        );
      }
      case Rules.VALIDATION_TYPE: {
        const { validationType } = question.displayData;
        return (
          <p key={question.id + error}>
            {t('engagement.errors.validation_type_error', {
              validationType: t(Validations[validationType]?.label),
            })}
          </p>
        );
      }
      case Rules.REGULAR_EXPRESSION:
        return <p key={question.id + error}>{t('engagement.errors.regular_expression_error')}</p>;
      case Rules.EXCEEDED_MAX_CHOICES:
        return <p key={question.id + error}>{t('engagement.errors.max_choices_limit_error')}</p>;
      default:
        return null;
    }
  });

  if (errors?.length) {
    return <div className="errors">{errors}</div>;
  }

  return null;
};

export const QuestionContainer = ({
  question,
  questionErrors,
  questionValues,
  currentSectionPosition,
  dispatchUpdateQuestionValue,
  currentQuestionValues,
  t,
}) => {
  return (
    <div
      className={classNames('question', {
        'question--open-budget': question.format === QuestionFormat.BUDGET_OPEN,
      })}
    >
      <h2 className="question__title is-primary-color">
        {question.title}
        {question.required && <span className="required is-primary-color"> *</span>}
      </h2>
      {renderErrors(question, questionErrors, t)}
      {renderQuestion(
        question,
        questionValues,
        currentSectionPosition,
        dispatchUpdateQuestionValue,
        currentQuestionValues
      )}
    </div>
  );
};

QuestionContainer.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    title: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    required: PropTypes.bool,
    format: PropTypes.string.isRequired,
  }).isRequired,
  questionErrors: PropTypes.shape({}).isRequired,
  questionValues: PropTypes.shape({}).isRequired,
  currentQuestionValues: PropTypes.shape({}),
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
  currentSectionPosition: PropTypes.number.isRequired,
};

QuestionContainer.defaultProps = {
  currentQuestionValues: null,
};

const mapStateToProps = (state) => ({
  questionValues: getQuestionValues(state),
  currentSectionPosition: getCurrentSectionPosition(state),
  currentQuestionValues: getCurrentQuestionValues(state),
  questionErrors: getQuestionErrors(state),
});

const mapDispatchToProps = {
  dispatchUpdateQuestionValue: updateQuestionValue,
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(QuestionContainer);

// questionErrors
export default withTranslation()(ConnectedComponent);
