// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Components
import Button from '../../Button';

export const Success = ({ hideModal, t }) => (
  <div className="content success">
    <h1>{t('common.great')}</h1>
    <i className="far fa-check-circle" />
    <p>{t('common.you_have_signed_up')}</p>
    <Button primary onClick={hideModal}>
      {t('common.continue')}
    </Button>
  </div>
);

Success.propTypes = {
  hideModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Success);
