// Libraries
import React from 'react';
import PropTypes from 'prop-types';

function OpenBudgetChartTooltip({ active, payload }) {
  if (!active) return null;

  const { percent } = payload[0].payload;

  return <div className="open-budget-chart-tooltip">{percent.toFixed(2)}%</div>;
}

OpenBudgetChartTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        percent: PropTypes.number.isRequired,
      }).isRequired,
    })
  ),
};

OpenBudgetChartTooltip.defaultProps = {
  active: false,
  payload: [],
};

export default OpenBudgetChartTooltip;
