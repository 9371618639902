// Libraries
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import sortBy from 'lodash/sortBy';

// State
import { upsertResponseEntry } from 'state/ducks/engagement';

// Utilities
import { parseInteger, determineImageSize } from 'common/utils/helpers';

// Components
import SortableInput from 'engagement/components/inputs/SortableInput';
import SortableAccessibility from 'engagement/components/inputs/SortableInput/SortableAccessibility';

export function OrderedQuestion(props) {
  const { question, currentQuestionValues, dispatchUpdateQuestionValue } = props;
  const dispatch = useDispatch();
  const initialItems = useMemo(() => {
    const questionValues = currentQuestionValues?.[question.id];
    const inputsSorted = sortBy(question.inputs, [(input) => questionValues?.[input.id]?.value]);
    const items = inputsSorted.map((input) => ({
      id: parseInteger(input.id),
      title: input.label,
      imageUrl: input.media ? determineImageSize(input.media.imageUrls) : null,
    }));

    return items;
  }, [question.inputs]);

  const onChange = (inputIds) => {
    const newQuestionValue = inputIds.reduce((accumulator, inputId, inputIndex) => {
      accumulator[inputId] = {
        value: inputIndex,
      };

      return accumulator;
    }, {});

    dispatchUpdateQuestionValue(question.id, newQuestionValue);

    for (let i = 0; i < inputIds.length; i += 1) {
      const params = { inputId: inputIds[i], questionId: question.id, inputAnswer: i + 1 };
      dispatch(upsertResponseEntry(params));
    }
  };

  useEffect(() => {
    // Save value to the store immediately, no action taken is still valid answer in ordered question
    onChange(initialItems.map((item) => item.id));
  }, []);

  return (
    <div className="ordered-question">
      <SortableAccessibility />
      <SortableInput initialItems={initialItems} onChange={onChange} />
    </div>
  );
}

OrderedQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  currentQuestionValues: PropTypes.shape({}),
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
};

OrderedQuestion.defaultProps = {
  currentQuestionValues: {},
};

export default OrderedQuestion;
