// Libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

// Components
import CheckboxInput from 'ideation/components/base/CheckboxInput';
import TextInput from 'ideation/components/base/TextInput';
import TextAreaInput from 'ideation/components/base/TextAreaInput';
import InputLengthDisplay from 'ideation/components/base/InputLengthDisplay';
import FileInput from 'ideation/components/base/FileInput';

// Errors & Validations
import {
  formValidations,
  processCreateError,
  processUserError,
} from 'ideation/validations/ideaValidations';

// Utils
import { createIdea } from 'api/v3/private';
import { logParticipantSubmission } from 'engagement/utils/analytics';

// State
import { setSubmissionStatus } from 'state/ducks/ideation';

// Enums
import { NEWEST } from 'ideation/enums/sortOrder';
import { readFileAsDataUrl } from 'common/utils/helpers';

const renderInputLengthDisplay = (name, watchedValues) => (
  <InputLengthDisplay
    minLength={formValidations[name].minLength.value}
    currentLength={watchedValues[name] ? watchedValues[name].length : 0}
    maxLength={formValidations[name].maxLength.value}
  />
);

const CreateIdeaForm = ({
  ideaBoardId,
  projectId,
  allowDescription,
  allowImage,
  onClose,
  searchValue,
  resetSearchValues,
  setSort,
  finalTranscript,
}) => {
  const dispatch = useDispatch();
  const [defaultError, setDefaultError] = useState(false);
  const { register, watch, handleSubmit, formState, setError } = useForm({
    defaultValues: {
      title: finalTranscript || searchValue,
      consent: Boolean(finalTranscript),
    },
  });
  const { t } = useTranslation();
  const watchedValues = watch();
  const { errors, isSubmitting } = formState;
  const errorValues = Object.values(errors);
  const forceSubmission =
    errorValues.length && errorValues.every((error) => error.type === 'TOXIC');

  const submitForm = async ({ title, description, image }) => {
    try {
      const data = { title, description };

      if (image?.length) data.image = await readFileAsDataUrl(image[0]);
      if (forceSubmission) data.force = true;

      const idea = await createIdea(ideaBoardId, data);

      logParticipantSubmission('Public Board', ideaBoardId, projectId);
      onClose();
      dispatch(setSubmissionStatus({ status: idea.deserialized.status }));
      resetSearchValues();
      setSort(NEWEST);
    } catch (error) {
      processUserError(error, dispatch, onClose);
      const formErrors = processCreateError(error);

      if (formErrors.length) {
        for (const formError of formErrors) {
          setError(...formError);
        }
      } else {
        setDefaultError(true);
      }
    }
  };

  useEffect(() => {
    const error = errors.title || errors.description || errors.image;

    if (error?.isManual) {
      error.ref.scrollIntoView();
    }
  }, [errors.title, errors.description, errors.image]);

  return (
    <>
      <h1 className="modal-title is-primary-color" tabIndex="0">
        {t('ideation.what_is_your_idea_heading')}
      </h1>
      <form className="modal-form-section" onSubmit={handleSubmit(submitForm)}>
        <TextInput
          id="title"
          name="title"
          label={t('ideation.idea_title_label')}
          register={register}
          validations={formValidations.title}
          renderHelpItems={() => renderInputLengthDisplay('title', watchedValues)}
          error={errors.title}
        />
        {allowDescription && (
          <TextAreaInput
            id="description"
            name="description"
            label={t('ideation.idea_description_label')}
            register={register}
            validations={formValidations.description}
            renderHelpItems={() => renderInputLengthDisplay('description', watchedValues)}
            error={errors.description}
          />
        )}
        {allowImage && (
          <FileInput
            id="image"
            name="image"
            label={t('ideation.idea_image_label')}
            register={register}
            validations={formValidations.image}
            error={errors.image}
            accept="image/jpeg, image/gif, image/webp, image/png"
          />
        )}
        <div
          className="create-idea-modal__privacy-policy"
          role="contentinfo"
          aria-label="privacy policy"
        >
          <Trans i18nKey="ideation.idea_collection">
            This form collects information about your idea. Check out our
            <a
              className="is-secondary-color"
              href="https://zencity.io/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              privacy policy
            </a>
            to see how we protect and manage your submitted data.
          </Trans>
        </div>
        <CheckboxInput
          id="consent"
          name="consent"
          label={t('ideation.terms_and_conditions')}
          register={register}
          validations={formValidations.consent}
          error={errors.consent}
        />
        {defaultError && (
          <div className="has-text-danger mt-4">{t('ideation.errors.idea.default')}</div>
        )}
        <div className="modal-form-actions">
          <button
            className="button is-primary is-primary-background"
            type="submit"
            disabled={isSubmitting}
          >
            {t('ideation.submit_idea_button')}
          </button>
        </div>
      </form>
      <div onClick={onClose} className="modal-action-link is-secondary-color">
        {t('ideation.cancel')}
      </div>
    </>
  );
};

CreateIdeaForm.propTypes = {
  ideaBoardId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  allowDescription: PropTypes.bool.isRequired,
  allowImage: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  resetSearchValues: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  finalTranscript: PropTypes.string.isRequired,
};

CreateIdeaForm.defaultProps = {
  searchValue: '',
};

export default CreateIdeaForm;
