// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useController, useForm } from 'react-hook-form';
import classNames from 'classnames';

// Utilities
import * as rules from 'common/utils/rules';
import { subscribeUser } from 'api/rest';

// Components
import Button from 'common/components/Button';
import Input from 'common/components/Input';
import RadioGroup from 'common/components/RadioGroup';
import CheckboxGroup from 'common/components/CheckboxGroup';

const notifications = Object.freeze({
  PROJECT: 'project',
  FUTURE_PROJECTS: 'future_projects',
});

function Form({ projectTitle, onSuccess, hideModal }) {
  const { t } = useTranslation();
  const params = useParams();
  const [error, setError] = useState(false);
  const { register, control, handleSubmit, formState } = useForm({
    defaultValues: { type: notifications.PROJECT, consent: false },
  });
  const { errors } = formState;
  const { field: typeField } = useController({ name: 'type', control });
  const { field: consentField } = useController({
    name: 'consent',
    rules: { required: t('common.consent_is_required') },
    control,
  });

  async function onSubmit(formValues) {
    try {
      const { name, email, type } = formValues;
      const projectId = type === notifications.PROJECT ? params.projectId : undefined;
      await subscribeUser(name, email, projectId);
      onSuccess();
    } catch {
      setError(true);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="content">
        <h1>{t('common.stay_notified')}</h1>
        <p className="description">{t('common.sign_up_for_notifications')}</p>
        <div className="columns">
          <div className="column p-75">
            <div className="field">
              <label className="label is-primary-color" htmlFor="notifications-signup-name">
                {t('common.your_name')}
              </label>
              <Input
                id="notifications-signup-name"
                className={classNames({ 'is-danger': errors.name })}
                {...register('name', { required: t('common.your_name_required') })}
              />
              {errors.name && <p className="help is-danger">{errors.name.message}</p>}
            </div>
          </div>
          <div className="column p-75">
            <div className="field">
              <label className="label is-primary-color" htmlFor="notifications-signup-email">
                {t('common.your_email')}
              </label>
              <Input
                id="notifications-signup-email"
                className={classNames({ 'is-danger': errors.email })}
                {...register('email', {
                  required: t('common.your_email_required'),
                  ...rules.email(t('common.invalid_email_message')),
                })}
              />
              {errors.email && <p className="help is-danger">{errors.email.message}</p>}
            </div>
          </div>
        </div>

        <div className="field">
          <div className="field__title is-primary-color">{t('common.stay_informed_about')}</div>
          <RadioGroup
            options={[
              {
                id: notifications.PROJECT,
                label: projectTitle || t('common.only_this_project'),
              },
              {
                id: notifications.FUTURE_PROJECTS,
                label: `${t('common.all')} ${gon.whitelabelName || 'future'} ${t(
                  'common.projects'
                )}`,
              },
            ]}
            initialValue={typeField.value}
            onChange={typeField.onChange}
          />
        </div>

        <div className="consent">
          <p>
            <Trans i18nKey="common.name_email_collection">
              This form collects your name and email so that we can send you updates about your
              idea. Check out our
              <a
                className="is-secondary-color"
                href="https://zencity.io/privacy-policy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                privacy policy
              </a>
              to see how we protect and manage your submitted data.
            </Trans>
          </p>

          <div className="field">
            <CheckboxGroup
              options={[
                {
                  id: 'consent',
                  label: `${t('common.notification_consent_message', {
                    brand: gon.whitelabelName || 'Zencity Engage',
                  })}*`,
                },
              ]}
              onChange={([value]) => consentField.onChange(Boolean(value))}
            />
            {errors.consent && <p className="help is-danger">{errors.consent.message}</p>}
          </div>
        </div>
      </div>

      <div className="notifications-signup-modal__footer">
        <Button type="submit" primary>
          {t('common.notification_sign_up_button')}
        </Button>
        {error && (
          <div className="container mt-1 has-text-centered" role="alert">
            <p className="high-contrast-error-color mb-0">
              <Trans i18nKey="common.something_went_wrong_try_again">
                Something went wrong
                <br />
                Close and try again
              </Trans>
            </p>
          </div>
        )}
        <Button onClick={hideModal} text>
          {t('common.cancel')}
        </Button>
      </div>
    </form>
  );
}

Form.propTypes = {
  projectTitle: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

Form.defaultProps = {
  projectTitle: '',
};

export default Form;
