const reasons = {
  COMMENT_OFFENSIVE: {
    label: 'COMMENT_OFFENSIVE',
    body: 'Report as Offensive',
    id: 1,
  },
  COMMENT_SPAM: {
    label: 'COMMENT_SPAM',
    body: 'Report as Marketing/Spam',
    id: 2,
  },
  COMMENT_OTHER: {
    label: 'COMMENT_OTHER',
    body: 'Report for other reasons',
    id: 3,
  },
};

export const { COMMENT_OFFENSIVE, COMMENT_SPAM, COMMENT_OTHER } = reasons;
