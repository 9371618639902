// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { StreetMapLayer } from 'common/components/StreetMapLayer';
import AnnotationInput from '../AnnotationInput';

/**
 * Geolocation annotation input component.
 */
const GeolocationAnnotationInput = (props) => (
  <div className="geolocation-annotation-input">
    <AnnotationInput {...props}>
      <StreetMapLayer />
    </AnnotationInput>
  </div>
);

GeolocationAnnotationInput.propTypes = {
  initialMarkers: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Marker's position as [lat, lng].
       */
      position: PropTypes.arrayOf(PropTypes.number).isRequired,
      /**
       * Marker's description.
       */
      description: PropTypes.string,
    })
  ),
  /**
   * Initial geolocation position. Used when no initial markers are passed.
   */
  initialPosition: PropTypes.arrayOf(PropTypes.number),
  /**
   * Initial zoom.
   */
  initialZoom: PropTypes.number,
  /**
   * (Optional) Callback to run whenever user interacts with markers.
   * Array of marker objects is passed as first argument.
   */
  onChange: PropTypes.func,
};

GeolocationAnnotationInput.defaultProps = {
  initialMarkers: [],
  initialPosition: [0, 0],
  initialZoom: 17,
  onChange: null,
};

export default GeolocationAnnotationInput;
