// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// State
import {
  getNextSectionPosition,
  deserializeEngagement,
  checkPreviewMode,
  getCurrentSection,
  updateCurrentPosition,
  getPreviousSectionPosition,
  getQuestionErrors,
  getCurrentSectionPosition,
} from 'state/ducks/engagement';

// Context
import ModalProvider from 'contexts/Modal/ModalProvider';

// Components
import Header from 'header/components/Header';
import Footer from 'common/components/Footer';
import PreviewModeBanner from 'common/components/PreviewModeBanner';
import Section from 'engagement/components/SectionsContainer/Section';
import ProgressBarContainer from 'engagement/components/ProgressBarContainer';
import NavigationContainer from 'engagement/components/NavigationContainer';
import CompleteEngagementFooter from 'engagement/components/CompleteEngagementFooter';
import SocialShareModal from 'engagement/components/SocialShareModal';

// Utils
import { classicTheme, parseInteger, storyTheme } from 'common/utils/helpers';
import { withPersistContext } from 'common/utils/reduxPersist';

export class SectionsContainer extends PureComponent {
  componentDidMount() {
    const {
      match: { params },
      dispatchUpdateCurrentPosition,
    } = this.props;
    dispatchUpdateCurrentPosition(parseInteger(params.position));
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
      deserializedEngagement,
      nextSectionPosition,
      dispatchUpdateCurrentPosition,
      persistContext,
      currentSectionPosition,
    } = this.props;
    const previousPosition = prevProps.match.params.position;

    if (!deserializedEngagement.introEnabled && currentSectionPosition === 1) {
      dispatchUpdateCurrentPosition(2);
      return;
    }

    // sync url position with store position
    if (previousPosition !== params.position) {
      dispatchUpdateCurrentPosition(parseInteger(params.position));
    }

    if (!nextSectionPosition && !deserializedEngagement.keepSubmission) {
      setTimeout(persistContext.clearStorage);
    }
  }

  render() {
    const {
      meta,
      currentSection,
      questionErrors,
      deserializedEngagement,
      nextSectionPosition,
      previewMode,
      t,
      currentSectionPosition,
    } = this.props;
    const { nextEngagement, project, collectDigitalFingerprint, introEnabled, keepSubmission } =
      deserializedEngagement;

    const isFirstSection = () => {
      if (introEnabled && currentSectionPosition === 1) return true;
      if (!introEnabled && currentSectionPosition === 2) return true;
      return false;
    };

    return (
      <>
        {previewMode && <PreviewModeBanner message={t('engagement.preview_mode')} />}
        <div
          className={classNames('sections-container', {
            'sections-container--preview': previewMode,
          })}
        >
          <a className="skip-main" href="#main">
            {t('engagement.skip_to_main')}
          </a>
          {storyTheme() && <ProgressBarContainer />}
          <Header
            meta={meta}
            previewMode={previewMode}
            currentSection={currentSection}
            engagement={deserializedEngagement}
            isLastSection={!nextSectionPosition}
          />
          {classicTheme() && <ProgressBarContainer />}
          <main id="main">
            <Section
              questionErrors={questionErrors}
              currentSection={currentSection}
              isFirstSection={isFirstSection()}
              isLastSection={!nextSectionPosition}
              collectDigitalFingerprint={collectDigitalFingerprint}
              introEnabled={introEnabled}
            />
          </main>
          <Footer>
            {nextSectionPosition ? (
              <NavigationContainer
                projectPublic={project.public}
                projectHubEnabled={project.projectHubEnabled}
              />
            ) : (
              <CompleteEngagementFooter
                nextEngagement={nextEngagement}
                keepSubmission={keepSubmission}
              />
            )}
          </Footer>
          {project.socialSharing && (
            <ModalProvider>
              <SocialShareModal
                isLastSection={!nextSectionPosition}
                engagement={deserializedEngagement}
              />
            </ModalProvider>
          )}
        </div>
      </>
    );
  }
}

SectionsContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      position: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }).isRequired,
  persistContext: PropTypes.shape({
    clearStorage: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
  dispatchUpdateCurrentPosition: PropTypes.func,
  nextSectionPosition: PropTypes.number,
  meta: PropTypes.shape({
    preview: PropTypes.bool,
  }),
  currentSection: PropTypes.shape({}),
  questionErrors: PropTypes.shape({}),
  deserializedEngagement: PropTypes.shape({
    nextEngagement: PropTypes.shape({}),
    collectDigitalFingerprint: PropTypes.bool.isRequired,
    keepSubmission: PropTypes.bool.isRequired,
    project: PropTypes.shape({
      public: PropTypes.bool,
      socialSharing: PropTypes.bool,
      projectHubEnabled: PropTypes.bool,
    }),
    introEnabled: PropTypes.bool,
  }),
  previewMode: PropTypes.bool,
  currentSectionPosition: PropTypes.number.isRequired,
};

SectionsContainer.defaultProps = {
  dispatchUpdateCurrentPosition: null,
  nextSectionPosition: null,
  meta: { preview: false },
  currentSection: {},
  questionErrors: {},
  deserializedEngagement: {},
  previewMode: false,
};

const mapStateToProps = (state) => ({
  deserializedEngagement: deserializeEngagement(state),
  previousSectionPosition: getPreviousSectionPosition(state),
  nextSectionPosition: getNextSectionPosition(state),
  previewMode: checkPreviewMode(state),
  questionErrors: getQuestionErrors(state),
  currentSection: getCurrentSection(state),
  currentSectionPosition: getCurrentSectionPosition(state),
});

const mapDispatchToProps = {
  dispatchUpdateCurrentPosition: updateCurrentPosition,
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(SectionsContainer);

export default withPersistContext(withTranslation()(ConnectedComponent));
