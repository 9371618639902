// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import camelcase from 'lodash.camelcase';
import { useTranslation } from 'react-i18next';

// I18n
import { currentLanguage } from 'i18n';

// Utils
import { determineImageSize, isEmbedded } from 'common/utils/helpers';
import { userAuthenticated } from 'ideation/utils/userAuthenticate';
import { logParticipantSubmission } from 'engagement/utils/analytics';

// Emuns
import DiscussableTypes from 'common/enums/DiscussableTypes';
import PublicStatus from 'common/enums/PublicStatus';

// Components
import Notification from 'common/components/Notification';
import Header from 'header/components/Header';
import Footer from 'common/components/Footer';
import SocialFooter from 'common/components/SocialFooter';
import DiscussionTopicHeader from 'discussionTopic/components/DiscussionTopicHeader';
import StayInformed from 'discussionTopic/components/StayInformed';
import CommunityRules from 'discussionTopic/components/CommunityRules';
import LoginSection from 'discussion/components/LoginSection';
import ProfileSection from 'discussion/components/ProfileSection';
import DiscussionBoard from 'discussion/components/DiscussionBoard';

const DiscussionTopic = ({ discussionTopic }) => {
  const { t } = useTranslation();
  const language = camelcase(currentLanguage);

  const {
    id,
    approvedAndModeratedCount,
    heroImage,
    project,
    publicStatus,
    titleTranslations,
    longDescriptionTranslations,
    communityRulesTranslations,
  } = discussionTopic;

  const title = titleTranslations?.[language] || discussionTopic.title;
  const longDescription =
    longDescriptionTranslations?.[language] || discussionTopic.longDescription;
  const communityRules = communityRulesTranslations?.[language] || discussionTopic.communityRules;

  const backgroundImageUrl =
    heroImage?.imageUrls && encodeURI(determineImageSize(heroImage.imageUrls));
  const projectPublic = project.public;
  const isLoggedIn = userAuthenticated();
  const disabled = publicStatus !== PublicStatus.OPEN;

  return (
    <div className="discussion-topic">
      {!isEmbedded() && (
        <>
          <Header ideation discussionTopic={discussionTopic} showAuth />
          <DiscussionTopicHeader
            title={title}
            description={longDescription}
            backgroundImageUrl={backgroundImageUrl}
            backgroundImagePosition={heroImage?.objectPosition || 'center'}
            projectPublic={projectPublic}
          />
        </>
      )}
      <main id="main">
        <section className="discussion-topic__container container inner inner--narrow">
          <div className="discussion-topic__content content-container">
            {isLoggedIn ? <ProfileSection /> : <LoginSection />}
            {isLoggedIn && <CommunityRules rules={communityRules} />}
            {disabled && (
              <div className="mb-6">
                <Notification className="idea-submission-notification" isClosed noDismiss>
                  {t('ideation.discussion_topic_closed_notification')}
                </Notification>
              </div>
            )}
            <DiscussionBoard
              discussableType={DiscussableTypes.DiscussionTopic}
              discussableId={id}
              isLoggedIn={isLoggedIn}
              disabled={disabled}
              approvedAndModeratedCount={approvedAndModeratedCount}
              onRootCommentSubmit={() =>
                logParticipantSubmission('Discussion Topic', id, project.id)
              }
            />
            {!isEmbedded() && <StayInformed project={project} />}
          </div>
        </section>
      </main>
      {!isEmbedded() && (
        <>
          <SocialFooter project={project} />
          <Footer />
        </>
      )}
    </div>
  );
};

DiscussionTopic.propTypes = {
  discussionTopic: PropTypes.shape({
    id: PropTypes.string.isRequired,
    projectId: PropTypes.number.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string,
    title: PropTypes.string.isRequired,
    approvedAndModeratedCount: PropTypes.number,
    longDescription: PropTypes.string,
    shortDescription: PropTypes.string.isRequired,
    publicStatus: PropTypes.string.isRequired,
    premoderation: PropTypes.string.isRequired,
    communityRules: PropTypes.string,
    communityRulesTranslations: PropTypes.shape({}),
    titleTranslations: PropTypes.shape({}),
    shortDescriptionTranslations: PropTypes.shape({}),
    longDescriptionTranslations: PropTypes.shape({}),
    heroImage: PropTypes.shape({
      imageUrls: PropTypes.shape({}),
      objectPosition: PropTypes.string,
    }),
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
      public: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DiscussionTopic;
