// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Rows extends Component {
  renderRow = (row) => {
    const { options } = this.props;

    return (
      <div key={row.id} className="matrix-input__row" role="radiogroup" aria-label={row.label}>
        <div className="matrix-input__question is-primary-color">{row.label}</div>
        {options.map((option) => this.renderRowButton(row, option))}
      </div>
    );
  };

  renderRowButton = (row, option) => {
    const { values } = this.props;
    const label = `${row.label} - ${option.label}`;
    const htmlId = `matrix-input_${row.id}_${option.id}`;
    const name = `matrix-input_${row.id}`;
    const checked = values[row.id] !== undefined && values[row.id].value === option.id;

    return (
      <div key={option.id} className="matrix-input__control-container">
        <label htmlFor={htmlId} className="matrix-input__control">
          {label}
          <input
            name={name}
            aria-label={label}
            className="matrix-input__control-input"
            type="radio"
            checked={checked}
            aria-checked={checked}
            onChange={this.getOnChangeHandler(row.id, option.id)}
            id={htmlId}
          />
          <div className="matrix-input__control-display" />
        </label>
      </div>
    );
  };

  getOnChangeHandler = (inputId, value) => () => {
    const { onChange } = this.props;

    onChange(inputId, value);
  };

  render() {
    const { rows } = this.props;

    return <>{rows.map(this.renderRow)}</>;
  }
}

Rows.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default Rows;
