// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

// Utilities
import { rtlLanguage, translateDate } from 'common/utils/helpers';

const AnnouncementHero = ({
  projectLink,
  title,
  subtitle,
  date,
  backToProjectText,
  projectUpdateText,
}) => {
  const { t } = useTranslation();

  return (
    <div
      id="page-hero"
      className="announcement-hero is-primary is-large"
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
      role="region"
    >
      <div className="container inner">
        <div className="announcement-hero__container">
          <div className="announcement-hero__info">
            {projectLink && (
              <p className="announcement-hero__info-link">
                {projectLink && (
                  <a href={projectLink} className="is-secondary-color">
                    <i
                      className={classNames('fas', {
                        'fa-long-arrow-alt-right': rtlLanguage(),
                        'fa-long-arrow-alt-left': !rtlLanguage(),
                      })}
                    />
                    {backToProjectText}
                  </a>
                )}
              </p>
            )}
            {title && <h1 className="announcement-hero__info-title is-primary-color">{title}</h1>}
            {subtitle && (
              <h2 className="announcement-hero__info-subtitle is-primary-color">{subtitle}</h2>
            )}
            {date && (
              <p className="announcement-hero__info-date">
                {projectUpdateText}
                {projectUpdateText && <span>-</span>}
                {translateDate(date, t)}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AnnouncementHero.propTypes = {
  projectLink: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  date: PropTypes.string,
  backToProjectText: PropTypes.string,
  projectUpdateText: PropTypes.string,
};

AnnouncementHero.defaultProps = {
  projectLink: null,
  title: null,
  subtitle: null,
  date: null,
  backToProjectText: 'Back to Project Page',
  projectUpdateText: 'Project Update',
};

export default AnnouncementHero;
