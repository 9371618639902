// Libraries
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

// State
import { upsertResponseEntry } from 'state/ducks/engagement';

// Utilities
import { roundStepValue } from 'common/utils/helpers';

// Components
import OpenBudgetOverview from './OpenBudgetOverview';
import OpenBudgetInputsHeader from './OpenBudgetInputsHeader';
import OpenBudgetInput from './OpenBudgetInput';
import OpenBudgetChart from './OpenBudgetChart';

function OpenBudgetQuestion({ question, currentQuestionValues, dispatchUpdateQuestionValue }) {
  const { unitLabel, supportText } = question;
  const { budget, capType, comparisonValueLabel, unitLayout } = question.displayData;
  const dispatch = useDispatch();
  const dispatchDebounced = useCallback(debounce(dispatch, 300), []);

  const sanitizedInputs = useMemo(
    () =>
      question.inputs.map((input) => {
        const { displayData } = input;
        const stepSize = Math.abs(Number(displayData.stepSize));
        const minValue = Math.max(Number(displayData.minValue), 0);
        const maxValueRaw = Math.max(Number(displayData.maxValue), 0);
        const maxValue = roundStepValue(maxValueRaw, stepSize, minValue, maxValueRaw);
        const fixed = Boolean(Number(displayData.fixed));
        return { ...input, displayData: { ...displayData, stepSize, minValue, maxValue, fixed } };
      }),
    [question.inputs]
  );

  const maxInputValue = useMemo(
    () => Math.max(sanitizedInputs.map((input) => input.displayData.maxValue)),
    [sanitizedInputs]
  );

  const onChangeHandler = (inputId, value, initialSubmit = false) => {
    dispatchUpdateQuestionValue(question.id, { [inputId]: { value } }, true);

    const entry = upsertResponseEntry({ inputId, questionId: question.id, inputAnswer: value });
    if (initialSubmit) dispatch(entry);
    else dispatchDebounced(entry);
  };

  return (
    <div className="open-budget-question">
      <div className="open-budget-question__content">
        <div className="open-budget-question__support-text">{supportText}</div>
        <OpenBudgetOverview
          budget={Number(budget)}
          capType={capType}
          unitLabel={unitLabel}
          currentValues={currentQuestionValues?.[question.id]}
        />
        {unitLayout === 'header' && (
          <OpenBudgetInputsHeader
            unitLabel={unitLabel}
            comparisonValueLabel={comparisonValueLabel}
          />
        )}
        {sanitizedInputs.map((input) => (
          <OpenBudgetInput
            key={input.id}
            input={input}
            unitLabel={unitLabel}
            comparisonValueLabel={comparisonValueLabel}
            unitLayout={unitLayout}
            maxInputValue={maxInputValue}
            defaultValue={currentQuestionValues?.[question.id]?.[input.id]?.value}
            onChange={(value, initialSubmit) => onChangeHandler(input.id, value, initialSubmit)}
          />
        ))}
      </div>
      <div className="open-budget-question__sidebar">
        <OpenBudgetChart
          inputs={question.inputs}
          currentValues={currentQuestionValues?.[question.id]}
          unitLabel={unitLabel}
        />
      </div>
    </div>
  );
}

OpenBudgetQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
    required: PropTypes.bool,
    unitLabel: PropTypes.string,
    supportText: PropTypes.string,
    displayData: PropTypes.shape({
      budget: PropTypes.string.isRequired,
      capType: PropTypes.oneOf(['soft_cap', 'hard_cap']).isRequired,
      comparisonValueLabel: PropTypes.string,
      unitLayout: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  currentQuestionValues: PropTypes.shape({}),
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
};

OpenBudgetQuestion.defaultProps = {
  currentQuestionValues: null,
};

export default OpenBudgetQuestion;
