// Libraries
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { currentLanguage } from 'i18n';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

// API
import { createDiscussion } from 'api/v3/private/discussion';

// Components
import CommentActionBar from 'discussion/components/CommentInput/CommentActionBar';
import CommentInputToast from 'discussion/components/CommentInput/CommentInputToast';

const CommentInput = (props) => {
  const { discussableType, discussableId, parentId, disabled, onSubmit, onCancel } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [forceSubmit, setForceSubmit] = useState(false);
  const maxLength = 500;

  const submitComment = async () => {
    setLoading(true);
    try {
      const response = await createDiscussion(
        discussableType,
        discussableId,
        comment,
        parentId,
        forceSubmit,
        currentLanguage
      );

      setComment('');
      setLoading(false);
      setForceSubmit(false);
      toast.remove();

      const requireModeration = response?.deserialized?.requireModeration;
      const toxicityScore = response?.deserialized?.toxicityScore;
      if (requireModeration || (toxicityScore && Number(toxicityScore) > 0.5)) {
        toast.custom(
          (toastObj) => (
            <CommentInputToast id={toastObj.id}>
              <p>{t('ideation.pre_moderate_warning_msg')}</p>
            </CommentInputToast>
          ),
          { position: 'bottom-center', duration: 5000 }
        );
      }

      onSubmit();
    } catch (err) {
      setLoading(false);

      if (err.status === 400) {
        if (!err.body?.errors?.length) return;
        setForceSubmit(true);

        const errorMsg = [];

        if (Array.isArray(err.body.errors)) {
          err.body.errors.forEach((msg) => errorMsg.push(msg));
        }

        if (typeof err.body.errors === 'string') {
          errorMsg.push(err.body.errors);
        }

        errorMsg.forEach((msg) =>
          toast.custom(
            (toastObj) => (
              <CommentInputToast id={toastObj.id}>
                <p>{msg}</p>
              </CommentInputToast>
            ),
            { position: 'bottom-center', duration: 60 * 60 * 24 }
          )
        );
      }
    }
  };

  const cancelReply = () => {
    setComment('');
    onCancel();
  };

  const onCommentChange = (e) => {
    setForceSubmit(false);
    setComment(e.target.value);
  };

  return (
    <div className="comment-input">
      <textarea
        aria-label={t('ideation.comment_textarea')}
        rows={5}
        value={comment}
        maxLength={maxLength}
        placeholder={t('ideation.share_your_thoughts')}
        onChange={onCommentChange}
        disabled={loading || disabled}
      />
      <div className="comment-input__bottom-bar">
        <CommentActionBar
          isReply={!!parentId}
          disabled={loading || comment.length === 0 || disabled}
          onSubmit={submitComment}
          onCancel={cancelReply}
        />
        <div className="comment-input__info">
          <b>{comment.length}</b>/{maxLength}
        </div>
      </div>
    </div>
  );
};

CommentInput.propTypes = {
  discussableType: PropTypes.string.isRequired,
  discussableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

CommentInput.defaultProps = {
  parentId: null,
  disabled: false,
  onSubmit: () => {},
  onCancel: () => {},
};

export default CommentInput;
