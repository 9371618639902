// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Image = (props) => {
  const { src, alt } = props;
  const [hasError, setHasError] = useState(false);

  const onErrorHandler = () => {
    setHasError(true);
  };

  if (hasError || !src) return null;

  return <img src={src} alt={alt} onError={onErrorHandler} />;
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

Image.defaultProps = {
  src: '',
  alt: '',
};

export default Image;
