// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const CommentActionBar = (props) => {
  const { isReply, disabled, onSubmit, onCancel } = props;
  const { t } = useTranslation();

  if (isReply) {
    return (
      <div className="comment-input__actions">
        <button
          type="button"
          className="button is-primary is-primary-background is-small comment-input__actions__reply-btn"
          onClick={onSubmit}
          disabled={disabled}
        >
          {t('ideation.reply_button')}
        </button>
        <button type="button" className="comment-input__actions__cancel-btn" onClick={onCancel}>
          {t('ideation.cancel_button')}
        </button>
      </div>
    );
  }

  return (
    <div className="comment-input__actions">
      <button
        type="button"
        className="button is-primary is-primary-background"
        onClick={onSubmit}
        disabled={disabled}
      >
        {t('ideation.comment_button')}
      </button>
    </div>
  );
};

CommentActionBar.propTypes = {
  isReply: PropTypes.bool,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

CommentActionBar.defaultProps = {
  isReply: false,
  disabled: false,
  onSubmit: () => {},
  onCancel: () => {},
};

export default CommentActionBar;
