// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// State
import { getCurrentProgress } from 'state/ducks/engagement';

// Utilities
import { getInternetExplorerVersion } from 'common/utils/helpers';

const IEVersion = getInternetExplorerVersion();

export const ProgressBarContainer = ({ currentProgress }) =>
  IEVersion === 8 || IEVersion === 9 ? null : (
    <progress className="progress" value={`${currentProgress}`} max="100">
      {`${currentProgress}%`}
    </progress>
  );

ProgressBarContainer.propTypes = {
  currentProgress: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({ currentProgress: getCurrentProgress(state) });

const ConnectedComponent = connect(mapStateToProps, null)(ProgressBarContainer);

export default ConnectedComponent;
