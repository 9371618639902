// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import compareDesc from 'date-fns/compareDesc';
import parseISO from 'date-fns/parseISO';

// Utilities
import { classicTheme, storyTheme } from 'common/utils/helpers';

// Components
import SponsorQuote from 'project/components/SponsorQuote';
import Phases from 'project/components/Phases';
import StoryPhases from 'project/components/StoryPhases';
import Announcements from 'project/components/Announcements';
import Events from 'project/components/Events';
import Documents from 'project/components/Documents';

export const Sidebar = ({ project, t, match }) => {
  const { quote, phases, events, documents, activeAnnouncements: announcements } = project;
  const sortedAnnouncements =
    announcements &&
    announcements.sort((first, second) =>
      compareDesc(parseISO(first.publishedAt), parseISO(second.publishedAt))
    );
  const phasesLabel = project.phasesLabel || t('project.project_phase_label');

  return (
    <>
      {quote && classicTheme() && <SponsorQuote quote={quote} />}
      {Boolean(phases?.length) && (
        <>
          {classicTheme() && <Phases heading={phasesLabel} phases={phases} />}
          {storyTheme() && <StoryPhases heading={phasesLabel} phases={phases} />}
        </>
      )}
      {Boolean(announcements?.length) && (
        <Announcements announcements={sortedAnnouncements} match={match} />
      )}
      {Boolean(events?.length) && <Events events={events} />}
      {Boolean(documents?.length) && <Documents documents={documents} />}
    </>
  );
};

Sidebar.propTypes = {
  project: PropTypes.shape({
    quote: PropTypes.shape({
      avatarUrls: PropTypes.shape({
        desktop: PropTypes.string,
        card: PropTypes.string,
        mobile: PropTypes.string,
      }),
      message: PropTypes.string,
      name: PropTypes.string,
      jobTitle: PropTypes.string,
    }),
    phases: PropTypes.arrayOf(PropTypes.any),
    events: PropTypes.arrayOf(PropTypes.any),
    documents: PropTypes.arrayOf(PropTypes.any),
    activeAnnouncements: PropTypes.arrayOf(PropTypes.any),
    phasesLabel: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withTranslation()(Sidebar);
