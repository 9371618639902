/* eslint-disable react/forbid-prop-types */
// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

// Utilities
import { populatePath } from 'common/utils/helpers';

// Enumerables
import PublicStatus from 'common/enums/PublicStatus';

// Routes
import Routes from 'routes';

// Components
import CallToActionTile from 'project/components/CallToActionTile';

const buttonLabel = (publicStatus, t) => {
  if (publicStatus === PublicStatus.OPEN) {
    return t('project.participate_now');
  }

  if (publicStatus === PublicStatus.CLOSED) {
    return t('project.view_discussion_topic');
  }

  return null;
};

const DiscussionTopicTile = ({ discussionTopic, match, t }) => {
  const { id, publicStatus, title, shortDescription, heroImage, startTime, endTime } =
    discussionTopic;

  const discussionTopicUrl = populatePath(Routes.DISCUSSION_TOPIC, {
    ...match.params,
    discussionTopicId: id,
  });

  return (
    <CallToActionTile
      publicStatus={publicStatus}
      title={title}
      id={id}
      description={shortDescription}
      heroImage={heroImage}
      url={discussionTopicUrl}
      startTime={startTime}
      endTime={endTime}
      buttonLabel={buttonLabel(publicStatus, t)}
    />
  );
};

DiscussionTopicTile.propTypes = {
  discussionTopic: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(DiscussionTopicTile));
