// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import camelCaseKeys from 'camelcase-keys';

// Components
import Header from 'header/components/Header';
import Footer from 'common/components/Footer';
import SocialShareToolbar from 'common/components/SocialShareToolbar';
import SocialFooter from 'common/components/SocialFooter';
import ActiveProjects from 'project/components/ProjectsContainer/ActiveProjects';
import FinishedProjects from 'project/components/ProjectsContainer/FinishedProjects';
import Opportunities from 'project/components/ProjectsContainer/Opportunities';
import NotificationsSignup from 'common/components/NotificationsSignup';

// Utilities
import { populatePath, determineImageSize } from 'common/utils/helpers';

// Routes
import routes from 'routes';

// Enumerables
import ProjectStatus from 'project/enums/ProjectStatus';
import ProjectGrouping from 'project/enums/ProjectGrouping';

export const CategoryContainer = (props) => {
  const { projects, category, opportunities, match, t } = props;
  const getProjectUrl = (projectId) => populatePath(routes.PROJECT, { ...match.params, projectId });

  const {
    showCallsToAction,
    featuredProjectId,
    facebookUrl,
    twitterUrl,
    contactEmail,
    socialSharing,
  } = camelCaseKeys(gon.brand, { deep: true });

  for (const p of projects) p.category = category;
  const active = projects.filter(({ status }) => status === ProjectStatus.ACTIVE);
  const finished = projects.filter(({ status }) => status === ProjectStatus.FINISHED);
  const featuredIndex = active.findIndex((element) => {
    return (
      Number(element.id) === featuredProjectId && String(element.categoryId) === String(category.id)
    );
  });
  const featured = active[featuredIndex];
  if (featured) {
    active.splice(featuredIndex, 1);
  }

  const heroImage = category.heroImage?.imageUrls
    ? `url(${determineImageSize(category.heroImage.imageUrls)})`
    : null;

  const txtSkipToMain = t('project.skip_to_main');
  const txtFeaturedProject = t('project.featured_projects');
  const txtViewProject = t('project.view_project');
  const txtActiveProjects = t('project.active_projects');
  const txtFinishedProjects = t('project.finished_projects');
  const txtOpportunitiesToEngage = t('project.opportunities_to_engage');

  return (
    <div className="has-background-light projects-container">
      <div className="project-index">
        <a className="skip-main" href="#main">
          {txtSkipToMain}
        </a>

        <Header />
        <main id="main">
          {/* Headline & welcome message */}
          <div
            className="content-hero hero is-primary"
            style={{
              backgroundImage: heroImage,
              backgroundPosition: category?.heroImage?.objectPosition || 'center',
            }}
          >
            <div className={heroImage ? 'content-hero__dim' : ''}>
              <div className="content-hero__intro container">
                <h1>{category.title}</h1>
                <div className="content-hero__intro-body">
                  <p>{category.description}</p>
                </div>
              </div>
            </div>
          </div>

          {socialSharing && <SocialShareToolbar />}

          {/* Featured project */}
          {featuredProjectId && featured && (
            <section className="section" aria-label={txtFeaturedProject}>
              <div className="featured-project container inner">
                <div className="content">
                  <h2>{txtFeaturedProject}</h2>
                </div>

                <div className="featured-project__card">
                  <div className="featured-project__card__image">
                    {featured.heroImage && (
                      <img
                        src={determineImageSize(featured.heroImage?.imageUrls)}
                        alt={featured.heroImage.altText}
                        title={featured.heroImage?.caption}
                        style={{ objectPosition: featured?.heroImage?.objectPosition || 'center' }}
                      />
                    )}
                  </div>
                  <div className="featured-project__card__body">
                    <div>
                      <div className="featured-project__card__body__category">
                        {featured.category?.title}
                      </div>
                      <div className="featured-project__card__body__info">
                        <h3 className="title is-primary-color" id={`featured_${featured.id}`}>
                          {featured.title}
                        </h3>
                        {featured.teaser && <p>{featured.teaser}</p>}
                      </div>
                    </div>
                    <div className="featured-project__card__body__action">
                      <a
                        href={getProjectUrl(featured.slug || featured.id)}
                        className="button is-primary is-primary-background"
                        aria-labelledby={`featured_${featured.id}`}
                      >
                        {txtViewProject}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Active projects */}
          {!!active.length && (
            <section className="section" aria-label={txtActiveProjects}>
              <div className="container">
                <div className="content">
                  <h2>{txtActiveProjects}</h2>
                  <div className="mt-6">
                    <ActiveProjects projects={active} grouping={ProjectGrouping.SINGLE_LIST} />
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Opportunities */}
          {!!opportunities?.length && showCallsToAction && (
            <section
              className="section opportunities-container has-background-white"
              aria-label={txtOpportunitiesToEngage}
            >
              <div className="container">
                <div className="content">
                  <h2>{txtOpportunitiesToEngage}</h2>
                  <div className="opportunities">
                    <Opportunities projects={projects} opportunities={opportunities} />
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Finished projects */}
          {!!finished.length && (
            <section className="section" aria-label={txtFinishedProjects}>
              <div className="container">
                <div className="content">
                  <h2>{txtFinishedProjects}</h2>
                  <div className="projects-tiles finished-project-tiles">
                    <FinishedProjects projects={finished} />
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className="section social-footer" aria-label={t('project.stay_informed')}>
            <div className="container">
              <div className="content">
                <div className="projects-stay-informed">
                  <div className="has-text-white">
                    <h2>
                      <span className="has-text-white">{t('project.stay_informed')}</span>
                    </h2>
                    <p>{t('project.stay_informed_desc')}</p>
                  </div>
                  <div>
                    <NotificationsSignup title="" t={t} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {(facebookUrl || twitterUrl || contactEmail) && (
            <div className="projects-container-footer">
              <SocialFooter
                className="has-text-white"
                project={{
                  facebookUrl,
                  twitterUrl,
                  contactEmail,
                }}
              />
            </div>
          )}
        </main>
        <Footer />
      </div>
    </div>
  );
};

CategoryContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      locale: PropTypes.string,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      slug: PropTypes.string,
      filter: PropTypes.string,
    })
  ).isRequired,
  category: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    description: PropTypes.string,
    heroImage: PropTypes.shape({
      imageUrls: PropTypes.shape({}),
      objectPosition: PropTypes.string,
    }),
  }).isRequired,
  opportunities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default withRouter(withTranslation()(CategoryContainer));
