/* eslint no-underscore-dangle: 0 */
// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, useMapEvents } from 'react-leaflet';
import { defer } from 'underscore';
import { v4 as uuid } from 'uuid';

// Utilities
import { clamp, rtlLanguage } from 'common/utils/helpers';

// Components
import Marker from './Marker';
import Tooltip from './Tooltip';

/**
 * Annotation input component.
 */
class AnnotationInput extends Component {
  leafletMap = null;

  markerDragging = false;

  constructor(props) {
    super(props);

    this.state = {
      markers: new Map(
        props.initialMarkers.map(({ guid, ...marker }) => [
          guid,
          {
            ...marker,
            popupVisible: false,
          },
        ])
      ),
      popupVisible: false,
      tooltipPosition: null,
      tooltipVisible: false,
    };
  }

  handleWhenCreated = (map) => {
    this.leafletMap = map;
    const { markers } = this.state;
    const { mapProps } = this.props;

    if (mapProps.bounds) {
      map.fitBounds(mapProps.bounds, { animate: false });
      map.setMinZoom(map.getZoom());
    } else if (markers.size > 0) {
      this.fitMarkers();
    }
  };

  /**
   * Fire onChange handler.
   */
  fireOnChange = (meta) => {
    const { onChange } = this.props;
    const { markers } = this.state;
    onChange?.(Object.fromEntries(markers), meta);
  };

  /**
   * Map click handler.
   */
  handleClick = ({ latlng: { lat, lng } }) => {
    const { markers, popupVisible } = this.state;
    const { mapProps } = this.props;

    markers.forEach((marker) => {
      marker.popupVisible = false;
    });

    if (popupVisible) {
      this.setState({
        markers: new Map(markers),
        popupVisible: false,
        tooltipVisible: true,
      });
      return;
    }

    const markerUuid = uuid();
    let position = [lat, lng];

    if (mapProps.bounds) {
      const [[minLat, minLng], [maxLat, maxLng]] = mapProps.bounds;
      position = [clamp(lat, minLat, maxLat), clamp(lng, minLng, maxLng)];
    }

    markers.set(markerUuid, {
      position,
      comment: '',
      popupVisible: true,
    });

    this.setState(
      {
        markers: new Map(markers),
        popupVisible: true,
        tooltipVisible: false,
      },
      () => this.fireOnChange({ created: markerUuid })
    );
  };

  /**
   * Popup open handler.
   */
  handlePopupOpen = ({ popup, target: map }) => {
    defer(() => {
      const position = map.project(popup.getLatLng());
      position.y += popup._container.clientHeight / 2;
      position.x += (popup._container.clientWidth / 2 - 40) * (rtlLanguage() ? -1 : 1);
      map.panTo(map.unproject(position));
    });
  };

  /**
   * Return marker's position change handler.
   */
  getMarkerDragEndHandler =
    (id) =>
    ({ target }) => {
      this.markerDragging = false;

      const { markers } = this.state;
      const { mapProps } = this.props;
      const { lat, lng } = target.getLatLng();

      let position = [lat, lng];

      if (mapProps.bounds) {
        const [[minLat, minLng], [maxLat, maxLng]] = mapProps.bounds;
        position = [clamp(lat, minLat, maxLat), clamp(lng, minLng, maxLng)];
      }

      markers.get(id).position = position;

      this.setState({ markers: new Map(markers) }, () => this.fireOnChange({ updated: id }));
    };

  /**
   * Return marker's click handler.
   */
  getMarkerClickHandler = (id) => () => {
    const { markers } = this.state;
    const { popupVisible } = markers.get(id);

    markers.forEach((marker) => {
      marker.popupVisible = false;
    });

    markers.get(id).popupVisible = !popupVisible;

    this.setState({
      markers: new Map(markers),
      popupVisible: !popupVisible,
    });
  };

  /**
   * Return marker's comment input change handler.
   */
  getMarkerCommentChangeHandler =
    (id) =>
    ({ target }) => {
      const { markers } = this.state;

      markers.get(id).comment = target.value;

      this.setState({ markers: new Map(markers) }, () => this.fireOnChange({ updated: id }));
    };

  /**
   * Return marker's close button click handler.
   */
  getMarkerPopupCloseHandler = (id) => () => {
    const { markers } = this.state;

    markers.get(id).popupVisible = false;

    this.setState({
      markers: new Map(markers),
      popupVisible: false,
      tooltipVisible: true,
    });
  };

  /**
   * Return marker's delete button click handler.
   */
  getMarkerDeleteHandler = (id) => () => {
    const { markers } = this.state;
    const removed = { uuid: id, ...markers.get(id) };
    markers.delete(id);

    this.setState(
      {
        markers: new Map(markers),
        popupVisible: false,
      },
      () => this.fireOnChange({ removed })
    );
  };

  hideTooltip = () => {
    if (!this.markerDragging) {
      this.setState({ tooltipVisible: false });
    }
  };

  showTooltip = () => {
    if (!this.markerDragging) {
      this.setState({ tooltipVisible: true });
    }
  };

  updateTooltipPosition = ({ containerPoint: { x, y } }) => {
    this.setState({ tooltipPosition: { x, y } });
  };

  handleMarkerDragStart = () => {
    this.markerDragging = true;
  };

  /**
   * Fit all markers inside map's view.
   */
  fitMarkers() {
    const { markers } = this.state;
    const { initialZoom } = this.props;

    if (markers.size === 1) {
      this.leafletMap.setView([...markers.values()][0].position, initialZoom);
    }

    if (markers.size >= 2) {
      this.leafletMap.fitBounds(
        [...markers.values()].map(({ position }) => position),
        {
          animate: false,
        }
      );
    }
  }

  render() {
    const { markers, popupVisible, tooltipPosition, tooltipVisible } = this.state;
    const {
      children,
      mapProps: { bounds, ...mapProps },
      initialPosition,
      initialZoom,
    } = this.props;

    return (
      <div className="annotation-input">
        <MapContainer
          center={initialPosition}
          zoom={initialZoom}
          scrollWheelZoom={false}
          doubleClickZoom={false}
          whenCreated={this.handleWhenCreated}
          {...mapProps}
        >
          <MapEvents
            onClick={this.handleClick}
            onPopupOpen={this.handlePopupOpen}
            onMouseOver={this.showTooltip}
            onMouseOut={this.hideTooltip}
            onMouseMove={this.updateTooltipPosition}
          />
          {children}
          {[...markers.entries()].map(([id, marker]) => (
            <Marker
              key={id}
              position={marker.position}
              comment={marker.comment}
              popupVisible={marker.popupVisible}
              onDragStart={this.handleMarkerDragStart}
              onDragEnd={this.getMarkerDragEndHandler(id)}
              onClick={this.getMarkerClickHandler(id)}
              onCommentChange={this.getMarkerCommentChangeHandler(id)}
              onPopupClose={this.getMarkerPopupCloseHandler(id)}
              onDelete={this.getMarkerDeleteHandler(id)}
              onMouseOver={this.hideTooltip}
              onMouseOut={this.showTooltip}
              onFocus={() => {}}
              onBlur={() => {}}
            />
          ))}
          <Tooltip visible={tooltipVisible && !popupVisible} position={tooltipPosition} />
        </MapContainer>
      </div>
    );
  }
}

function MapEvents({ onClick, onPopupOpen, onMouseOver, onMouseOut, onMouseMove }) {
  useMapEvents({
    click: onClick,
    popupopen: onPopupOpen,
    mouseover: onMouseOver,
    mouseout: onMouseOut,
    mousemove: onMouseMove,
  });
  return null;
}

AnnotationInput.propTypes = {
  initialMarkers: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Marker's unique ID.
       */
      guid: PropTypes.string.isRequired,
      /**
       * Marker's position as [lat, lng].
       */
      position: PropTypes.arrayOf(PropTypes.number).isRequired,
      /**
       * Marker's comment.
       */
      comment: PropTypes.string,
    })
  ),
  /**
   * Initial position. Used when no initial markers are passed.
   */
  initialPosition: PropTypes.arrayOf(PropTypes.number),
  /**
   * Initial zoom.
   */
  initialZoom: PropTypes.number,
  /**
   * (Optional) Callback to run whenever user interacts with markers.
   * Array of marker objects is passed as first argument.
   */
  onChange: PropTypes.func,
  /**
   * Pass any tiles/layers to be rendered within leaflet component.
   */
  children: PropTypes.node.isRequired,
  /**
   * Any extra props to be passed to Map component.
   */
  mapProps: PropTypes.objectOf(PropTypes.any),
};

AnnotationInput.defaultProps = {
  initialMarkers: [],
  initialPosition: null,
  initialZoom: 0,
  onChange: null,
  mapProps: {},
};

export default AnnotationInput;
