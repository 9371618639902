// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { determineImageSize } from 'common/utils/helpers';

// Components
import Modal from 'ideation/components/base/Modal';
import Button from 'common/components/Button';

const BudgetMediaModal = (props) => {
  const {
    budgetOption: {
      label,
      media,
      displayData: { amount, description },
    },
    isOpen,
    onClose,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="engagement-modal budget-media-modal auto-fit"
    >
      <div className="modal-content-body">
        {media && media.imageUrls && (
          <div
            className="image"
            style={{ backgroundImage: `url(${determineImageSize(media.imageUrls)})` }}
          />
        )}
        <div className="modal-budget-label is-primary-color">{label}</div>
        <div className="modal-budget-amount">{Number(amount).toLocaleString()}</div>
        <div className="modal-budget-description">{description}</div>
        <div className="modal-button-container">
          <Button className="is-primary is-primary-background" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

BudgetMediaModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  budgetOption: PropTypes.shape({
    label: PropTypes.string,
    media: PropTypes.shape({
      imageUrls: PropTypes.shape({}),
    }),
    displayData: PropTypes.shape({
      amount: PropTypes.string,
      description: PropTypes.string,
    }),
  }).isRequired,
};

export default BudgetMediaModal;
