// Libraries
import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import PropTypes from 'prop-types';

// Utilities
import { colors, formatUnitValue, sumQuestionValues } from 'common/utils/helpers';

// Components
import OpenBudgetChartTooltip from '../OpenBudgetChartTooltip';

function OpenBudgetChart({ inputs, currentValues, unitLabel }) {
  const used = sumQuestionValues(currentValues);
  const chartData = inputs.reduce((data, input, index) => {
    const value = Number(currentValues[input.id]?.value);

    if (value) {
      data.push({
        name: input.label,
        colorIndex: index,
        percent: (value / used) * 100,
        value,
      });
    }

    return data;
  }, []);

  return (
    <div className="open-budget-chart">
      <PieChart width={292} height={292} accessibilityLayer>
        <Pie
          data={chartData}
          innerRadius={102}
          outerRadius={146}
          fill="#8884d8"
          paddingAngle={0.5}
          dataKey="value"
          isAnimationActive={false}
        >
          {chartData.map(({ name, colorIndex }) => (
            <Cell key={name} fill={colors[colorIndex % colors.length]} />
          ))}
        </Pie>
        <Tooltip content={<OpenBudgetChartTooltip />} />
      </PieChart>
      <Legend
        layout="vertical"
        width="100%"
        wrapperStyle={{ marginTop: '40px', position: 'static' }}
        payload={inputs.map(({ id, label }, index) => ({
          id,
          type: 'square',
          value: `${label} ${formatUnitValue(Number(currentValues[id]?.value) || 0, unitLabel)}`,
          color: colors[index % colors.length],
        }))}
      />
    </div>
  );
}

Legend.propTypes = {
  width: PropTypes.string.isRequired,
};

OpenBudgetChart.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentValues: PropTypes.shape({}),
  unitLabel: PropTypes.string,
};

OpenBudgetChart.defaultProps = {
  currentValues: {},
  unitLabel: '',
};

export default OpenBudgetChart;
