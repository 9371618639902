// Libraries
import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SpeechRecognition from 'react-speech-recognition';
import classNames from 'classnames';

// Hooks
import { useKeyListener } from 'common/hooks/useKeyListener';

// Utils
import { featureFlagEnabled } from 'common/utils/helpers';

// Enums
import Keys from 'common/enums/Key';

const SearchInput = ({ name, register, placeholder, handleSubmit, listening, volume }) => {
  const searchIcon = useRef(null);
  const { t } = useTranslation();
  const speechRecognitionEnabled = featureFlagEnabled('speech_recognition');

  const keyboardSubmit = useCallback((event) => {
    if (searchIcon.current.matches(':focus')) {
      event.preventDefault();
      handleSubmit();
    }
  }, []);

  const stopListening = useCallback(() => {
    SpeechRecognition.stopListening();
  }, []);

  useKeyListener(Keys.ENTER, keyboardSubmit);
  useKeyListener(Keys.ESCAPE, stopListening);

  return (
    <div
      className={classNames('search-input', { 'search-input--listening': listening })}
      style={{
        boxShadow: [
          `0px 0px 0px ${volume * 60}px #24d394`,
          `0px 0px 0px ${volume * 140}px #7ce5bf`,
          `0px 0px 0px ${volume * 250}px #f4fdfa`,
        ].join(),
      }}
    >
      <div className="search-input__input-container">
        <input
          aria-label="search input"
          placeholder={listening ? 'Listening...' : placeholder}
          type="text"
          className="input is-medium"
          id="search-input"
          {...register(name)}
        />
        {speechRecognitionEnabled && !listening && (
          <button
            type="button"
            className="search-input__microphone"
            tabIndex={0}
            onClick={() => SpeechRecognition.startListening({ language: 'en-US' })}
          >
            <i className="fas fa-microphone" />
          </button>
        )}
      </div>
      {!listening && (
        <button
          aria-label="search submit"
          type="submit"
          ref={searchIcon}
          tabIndex={0}
          onClick={handleSubmit}
          className="is-primary-background"
          disabled={listening}
          aria-controls={'idea-board-content'}
        >
          {t('ideation.search')}
        </button>
      )}
    </div>
  );
};

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  listening: PropTypes.bool.isRequired,
  volume: PropTypes.number.isRequired,
};

SearchInput.defaultProps = {
  placeholder: '',
};

export default SearchInput;
