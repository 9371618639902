// Libraries
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Utilities
import { fetchProject } from 'api/rest';
import { isEmbedded, jsonaDeserialize } from 'common/utils/helpers';

// Components
import Header from 'header/components/Header';
import Footer from 'common/components/Footer';
import NotificationsSignup from 'common/components/NotificationsSignup';
import VisitHubLink from '../VisitHubLink';

const ClosedPageContainer = ({ match, t }) => {
  const [project, setProject] = useState(null);

  useEffect(() => {
    const getProject = async () => {
      const fetchedProject = await fetchProject(match.params.projectId);
      setProject(jsonaDeserialize(fetchedProject));
    };
    getProject();
  }, []);

  return (
    <div className="closed-page">
      <Header project={project} />
      <section className="hero">
        <div className="hero-body">
          <div className="container inner">
            <h1 className="title">{t('engagement.engagement_now_closed')}</h1>
            <p>{t('engagement.engagement_thanks_for_coming')}</p>
            {project?.public && project?.projectHubEnabled && !isEmbedded() && (
              <div className="actions">
                <VisitHubLink button />
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer>
        <div className="footer-wrapper">
          <div className="container inner">
            <div className="columns is-multiline">
              <div className="column is-full">
                <h2 className="title">{t('engagement.stay_involved')}</h2>
              </div>
              {project?.notificationSignUpEnabled && (
                <div className="column">
                  <p>{t('engagement.get_notified_message')}</p>
                  <NotificationsSignup title={project.title} />
                </div>
              )}
              {project?.public && project?.projectHubEnabled && !isEmbedded() && (
                <div className="column">
                  <p>{t('engagement.learn_more')}</p>
                  <VisitHubLink button />
                </div>
              )}
            </div>
          </div>
        </div>
      </Footer>
    </div>
  );
};

ClosedPageContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(ClosedPageContainer));
