// Libraries
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

// State
import { upsertResponseEntry } from 'state/ducks/engagement';

// Enumerables
import InputFormat from 'engagement/enums/InputFormat';
import { ValidationEnable, ValidationTypes } from 'engagement/enums/singleTextBox';

// Components
import TextInput from './inputs/TextInput';
import TextareaInput from './inputs/TextareaInput';

const inputComponentMap = {
  [InputFormat.TEXT]: TextInput,
  [InputFormat.TEXTAREA]: TextareaInput,
};

function BasicQuestion(props) {
  const { question, currentQuestionValues, dispatchUpdateQuestionValue } = props;
  const { id: questionId, title } = question;
  const dispatch = useDispatch();
  const dispatchDebounced = useCallback(debounce(dispatch, 300), []);

  const getInputChangeHandler = (inputId) => (inputValue) => {
    dispatchUpdateQuestionValue(questionId, { [inputId]: { value: inputValue } });
    dispatchDebounced(upsertResponseEntry({ inputId, questionId, inputAnswer: inputValue }));
  };

  const renderInput = ({ id, format, placeholder }) => {
    const InputComponent = inputComponentMap[format];
    if (!InputComponent) return null;

    return (
      <InputComponent
        key={id}
        id={id}
        label={title}
        placeholder={placeholder}
        value={currentQuestionValues?.[questionId]?.[id]?.value}
        {...question.displayData}
        onChange={getInputChangeHandler(id)}
      />
    );
  };

  return <div className="basic-question">{question.inputs.map(renderInput)}</div>;
}

BasicQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    displayData: PropTypes.shape({
      enableValidation: PropTypes.oneOf(Object.values(ValidationEnable)),
      validationType: PropTypes.oneOf(Object.values(ValidationTypes)),
      characterCountMin: PropTypes.string,
      characterCountMax: PropTypes.string,
    }),
  }).isRequired,
  currentQuestionValues: PropTypes.shape({}),
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
};

BasicQuestion.defaultProps = {
  currentQuestionValues: {},
};

export default BasicQuestion;
