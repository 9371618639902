// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Utilities
import { populatePath, storyTheme } from 'common/utils/helpers';

// Enumerables
import PublicStatus from 'common/enums/PublicStatus';

// Routes
import Routes from 'routes';

// Components
import CallToActionTile from 'project/components/CallToActionTile';

const buttonLabel = (publicStatus, displayEngagementReport, t) => {
  if (publicStatus === PublicStatus.OPEN) {
    if (storyTheme()) {
      return t('project.read_more');
    }

    return t('project.participate_now');
  }

  if (displayEngagementReport) {
    return t('engagement_report.view_results');
  }

  return null;
};

const EngagementTile = ({ engagement, match, t }) => {
  const {
    id,
    publicStatus,
    title,
    description,
    heroImage,
    minutesToComplete,
    startTime,
    endTime,
    slug,
    project,
    engagementReport,
  } = engagement;

  const engagementReportUrl =
    window.location.origin +
    populatePath(Routes.ENGAGEMENT_REPORT, {
      locale: 'en', // TODO: Remove hardcoded locale
      projectId: project?.slug || project?.id,
      engagementReportId: engagementReport?.slug,
    });

  const engagementUrl = populatePath(Routes.ENGAGEMENT, {
    ...match.params,
    engagementId: slug || id,
  });

  const displayEngagementReport =
    publicStatus === PublicStatus.CLOSED &&
    engagementReport?.id &&
    engagementReport?.publiclyVisible;

  return (
    <CallToActionTile
      publicStatus={publicStatus}
      title={title}
      id={id}
      description={description}
      heroImage={heroImage}
      url={displayEngagementReport ? engagementReportUrl : engagementUrl}
      startTime={startTime}
      endTime={endTime}
      minutesToComplete={minutesToComplete}
      buttonLabel={buttonLabel(publicStatus, displayEngagementReport, t)}
    />
  );
};

EngagementTile.propTypes = {
  engagement: PropTypes.objectOf({}).isRequired,
  match: PropTypes.objectOf({}).isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(EngagementTile));
