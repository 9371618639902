// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { determineImageSize } from 'common/utils/helpers';

const SponsorQuote = ({ quote }) => {
  const headshotImg = determineImageSize(quote.avatarUrls);

  return (
    <blockquote className="sponsor-quote">
      {quote.message && <p className="sponsor-quote__body is-primary-color">{quote.message}</p>}
      <div className="sponsor-quote__footer">
        {headshotImg && (
          <div className="sponsor-quote__headshot">
            <img src={headshotImg} alt="headshot" />
          </div>
        )}
        <cite>
          <p>
            <span className="sponsor-quote__name">{quote.name}</span>
            <span className="sponsor-quote__title">{quote.jobTitle}</span>
          </p>
        </cite>
      </div>
    </blockquote>
  );
};

SponsorQuote.propTypes = {
  quote: PropTypes.shape({
    avatarUrls: PropTypes.shape({
      desktop: PropTypes.string,
      card: PropTypes.string,
      mobile: PropTypes.string,
    }),
    message: PropTypes.string,
    name: PropTypes.string,
    jobTitle: PropTypes.string,
  }).isRequired,
};

export default SponsorQuote;
