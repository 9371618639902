// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import startCase from 'lodash.startcase';

// Utilities
import { classicTheme, getVariationByLocale, storyTheme } from 'common/utils/helpers';

// Components
import ProjectMeta from 'project/components/ProjectMeta';

export const Documents = (props) => {
  const { documents, t } = props;
  let documentsInSelectLocale = documents;

  if (!gon.inDefaultLanguage) {
    documentsInSelectLocale = documents?.map((document) => {
      return getVariationByLocale(document.variations, gon.currentLocale, document);
    });
  }

  const renderDocument = (document) => {
    const {
      file: {
        id,
        attributes: { filename, fileUrl },
      },
    } = document;

    const displayName = startCase(filename.substring(0, filename.lastIndexOf('.')) || filename);

    return (
      <li className="project-documents__item" key={id}>
        <a href={fileUrl} className="is-secondary-color">
          {classicTheme() && <i className="fas fa-file" />}
          <span>{displayName}</span>
          {storyTheme() && <i className="fas fa-download" />}
        </a>
      </li>
    );
  };

  return (
    <ProjectMeta
      heading={t('project.supporting_documents_heading')}
      icon="fas fa-file"
      className="project-documents"
      headingClassName="project-documents__heading"
    >
      <ul className="project-documents__items">{documentsInSelectLocale.map(renderDocument)}</ul>
    </ProjectMeta>
  );
};

Documents.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Documents);
