import React from 'react';
import classNames from 'classnames';

const CheckBox = ({ className, type, checked, ...props }) => (
  <input
    tabIndex={0}
    aria-checked={checked}
    checked={checked}
    className={classNames(className, 'checkbox__control-input')}
    type="checkbox"
    {...props}
  />
);

export default CheckBox;
