// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Trans, withTranslation } from 'react-i18next';

// Enumerables
import PublicStatus from 'common/enums/PublicStatus';

// Helpers
import { classicTheme, determineImageSize, storyTheme } from 'common/utils/helpers';

// Components
import Link from 'common/components/Link';
import Tag from 'project/components/Tag';
import DateInfo from 'project/components/DateInfo';

const CallToActionTile = ({
  publicStatus,
  title,
  id,
  description,
  heroImage,
  startTime = null,
  endTime,
  url,
  minutesToComplete,
  buttonLabel,
  t,
}) => {
  const hasHeroImage = storyTheme() && heroImage?.imageUrls;

  return (
    <>
      <div
        className={classNames('call-to-action-tile', { 'call-to-action-tile--hero': hasHeroImage })}
        style={{
          backgroundImage: hasHeroImage && `url(${determineImageSize(heroImage.imageUrls)})`,
          backgroundPosition: heroImage?.objectPosition || 'center',
        }}
      >
        <div className="box">
          <Tag publicStatus={publicStatus} />

          <h2 className="call-to-action-tile__title is-primary-color" id={`cta-title-${id}`}>
            {buttonLabel ? <a href={url}>{title}</a> : `${title}`}
          </h2>

          <div className="call-to-action-tile__date">
            <DateInfo publicStatus={publicStatus} startTime={startTime} endTime={endTime} />
          </div>

          <div className="call-to-action-tile__body">
            <p>{description}</p>
          </div>

          <div className="columns call-to-action-tile__footer">
            {buttonLabel && (
              <>
                <div className="column is-narrow">
                  <Link
                    href={url}
                    className="call-to-action-tile__button"
                    button
                    primary={classicTheme()}
                    outlined={storyTheme()}
                    ariaLabelledBy={`cta-title-${id}`}
                  >
                    {buttonLabel}
                  </Link>
                </div>
                {classicTheme() && minutesToComplete && (
                  <div className="column">
                    <p>
                      <Trans t={t} i18nKey="project.approximate_time" count={minutesToComplete}>
                        approximate time
                        <strong>
                          {{ count: minutesToComplete }}
                          minutes
                        </strong>
                      </Trans>
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {hasHeroImage && (
        <div
          className="call-to-action-tile--responsive-hero"
          style={{ backgroundImage: `url(${determineImageSize(heroImage.imageUrls)})` }}
        />
      )}
    </>
  );
};

CallToActionTile.propTypes = {
  publicStatus: PropTypes.oneOf(Object.values(PublicStatus)).isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({
    objectPosition: PropTypes.string,
    imageUrls: PropTypes.shape({
      desktop: PropTypes.string,
      card: PropTypes.string,
      mobile: PropTypes.string,
    }),
  }),
  url: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  minutesToComplete: PropTypes.number,
  buttonLabel: PropTypes.string,
  t: PropTypes.func.isRequired,
};

CallToActionTile.defaultProps = {
  heroImage: null,
  startTime: null,
  endTime: null,
  minutesToComplete: null,
  buttonLabel: null,
};

export default withTranslation()(CallToActionTile);
