// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import NotificationsSignup from 'common/components/NotificationsSignup';

const StayInformed = (props) => {
  const { project } = props;
  const { t } = useTranslation();

  if (!project?.notificationSignUpEnabled) return false;

  return (
    <div className="signup mb-0">
      <div className="signup__body">
        <h2 className="signup__title title is-primary-color">{t('project.stay_informed')}</h2>
        <p className="signup__message">{t('project.get_notified_message')}</p>
      </div>
      <NotificationsSignup title={project.title} />
    </div>
  );
};

StayInformed.propTypes = {
  project: PropTypes.shape({
    title: PropTypes.string.isRequired,
    notificationSignUpEnabled: PropTypes.bool,
  }).isRequired,
};

export default StayInformed;
