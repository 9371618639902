/* eslint-disable react/forbid-prop-types */
// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

// Utilities
import { determineImageSize } from 'common/utils/helpers';

// Components
import Header from 'header/components/Header';
import Footer from 'common/components/Footer';
import SocialFooter from 'common/components/SocialFooter';
import Hero from 'project/components/Hero';
import ProjectDetail from 'project/components/ProjectDetail';
import Sidebar from 'project/components/Sidebar';
import PreviewModeBanner from 'common/components/PreviewModeBanner';

const ProjectContainer = ({ project, t, match }) => (
  <>
    {project.meta?.preview && <PreviewModeBanner message={t('project.preview_mode')} />}
    <div
      className={classNames('project', 'project--landing', {
        'project--landing--no-hero': !project.heroImage || !project.heroImage.links.self,
        'project--preview': project.meta?.preview,
      })}
    >
      <a className="skip-main" href="#main">
        {t('project.skip_to_main')}
      </a>
      <Header project={project} projectHub />
      <main id="main">
        <Hero
          backgroundImageUrl={project.heroImage && determineImageSize(project.heroImage.imageUrls)}
          backgroundImagePosition={project?.heroImage?.objectPosition || 'center'}
          title={project.title}
          subheading={project.subheading}
        />
        <section className="container">
          <div className="columns is-desktop project__content content-container">
            <div className="column is-three-fifths-desktop is-full-tablet project__body">
              <ProjectDetail project={project} />
            </div>
            <div className="column project__sidebar is-two-fifths-desktop">
              <Sidebar project={project} match={match} />
            </div>
          </div>
        </section>
        <SocialFooter project={project} />
      </main>
      <Footer />
    </div>
  </>
);

ProjectContainer.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withTranslation()(ProjectContainer);
