// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { propertyOf } from 'underscore';

// State
import {
  setPersistedEngagement,
  deserializeEngagement,
  generateSkipSections,
  checkPreviewMode,
  getStateHydrated,
  getCurrentSectionPosition,
  getSubmission,
  getState,
} from 'state/ducks/engagement';

// Routes
import Routes from 'routes';

// Utilities
import { hydrateEngagementState, persistState } from 'common/utils/reduxPersist';
import { populatePath, parseInteger } from 'common/utils/helpers';
import NotFoundPageContainer from 'common/components/NotFoundPageContainer';
import { isEngagementClosed } from 'engagement/utils/helpers';

// Components
import Spinner from 'common/components/Spinner';
import SectionsContainer from 'engagement/components/SectionsContainer';
import QuitPageContainer from 'engagement/components/QuitPageContainer';
import ClosedPageContainer from 'engagement/components/ClosedPageContainer';
import ErrorPageContainer from 'engagement/components/ErrorPageContainer';

const keyPrefix = ({ match: { params } }) => `state/engagement[${params.engagementId}]`;

const propsToPersist = [
  'questionValues',
  'currentSectionPosition',
  'engagementSerialized',
  'submission',
  'sectionsSubmitted',
  'fingerprintConsent',
  'responseHistory',
  'responseInputsMap',
];

const shouldClearStorage = (props, persistedState) => {
  const {
    engagement: { meta },
  } = props;
  if (meta && meta.preview) {
    return true;
  }

  const statusPath = ['data', 'attributes', 'public_status'];
  const status = propertyOf(props)(['engagement', ...statusPath]);
  const persistedStatus = propertyOf(persistedState)(['engagementSerialized', ...statusPath]);

  return persistedStatus && status !== persistedStatus;
};

const persistEnabled = (props) => !props.engagement.meta?.preview;
const persistedState = persistState(getState, keyPrefix, propsToPersist, persistEnabled);

export class EngagementContainer extends PureComponent {
  componentDidMount() {
    const { dispatchSetPersistedEngagement } = this.props;
    hydrateEngagementState(
      keyPrefix,
      propsToPersist,
      this.props,
      shouldClearStorage,
      dispatchSetPersistedEngagement
    );
  }

  render() {
    const {
      engagement: { meta },
      stateHydrated,
      currentSectionPosition,
      skipSections,
      deserializedEngagement,
      previewMode,
      submission,
      match,
    } = this.props;

    if (!stateHydrated) {
      return <Spinner />;
    }

    return (
      <div className="engagement-container">
        <Switch>
          <Redirect
            exact
            strict
            push
            from={Routes.ENGAGEMENT_PREVIEW}
            to={
              meta && meta.section
                ? populatePath(Routes.ENGAGEMENT_SECTION, {
                    ...match.params,
                    position: meta.section,
                  })
                : populatePath(Routes.ENGAGEMENT, match.params)
            }
          />
          <Route
            exact
            path={Routes.ENGAGEMENT}
            render={() => {
              const redirectUrl = populatePath(Routes.ENGAGEMENT_SECTION, {
                ...match.params,
                position: currentSectionPosition,
              });

              return (
                <Redirect
                  to={{
                    pathname: redirectUrl,
                    search: window.location.search,
                  }}
                />
              );
            }}
          />
          <Route
            exact
            strict
            path={Routes.ENGAGEMENT_SECTION}
            render={(props) => {
              const position = parseInteger(props.match.params.position);

              if (!deserializedEngagement) return null;

              if (!position || position < 1 || position > deserializedEngagement.sections.length) {
                return <NotFoundPageContainer />;
              }

              if (!previewMode && isEngagementClosed(deserializedEngagement)) {
                return <Redirect to={populatePath(Routes.ENGAGEMENT_CLOSED, match.params)} />;
              }

              // If a user manually alters the URL, redirect them to the intended section
              // if this is a preview and the URL is altered by skipLogic, return the next section
              if (skipSections.includes(position)) {
                return (
                  <Redirect
                    to={populatePath(Routes.ENGAGEMENT_SECTION, {
                      ...match.params,
                      position: previewMode ? position + 1 : currentSectionPosition,
                    })}
                  />
                );
              }

              // Redirect first-time users to the first section
              if (!previewMode && !submission && position > 1) {
                return (
                  <Redirect
                    to={populatePath(Routes.ENGAGEMENT_SECTION, { ...match.params, position: 1 })}
                  />
                );
              }

              return <SectionsContainer {...props} meta={meta} />;
            }}
          />
          <Route exact strict path={Routes.ENGAGEMENT_QUIT} component={QuitPageContainer} />
          <Route exact strict path={Routes.ENGAGEMENT_CLOSED} component={ClosedPageContainer} />
          <Route exact strict path={Routes.ENGAGEMENT_ERROR} component={ErrorPageContainer} />
          <Route component={NotFoundPageContainer} />
        </Switch>
      </div>
    );
  }
}

EngagementContainer.propTypes = {
  engagement: PropTypes.objectOf(PropTypes.any).isRequired,
  currentSectionPosition: PropTypes.number.isRequired,
  skipSections: PropTypes.arrayOf(PropTypes.number).isRequired,
  deserializedEngagement: PropTypes.objectOf(PropTypes.any),
  previewMode: PropTypes.bool.isRequired,
  submission: PropTypes.objectOf(PropTypes.any),
  stateHydrated: PropTypes.bool.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatchSetPersistedEngagement: PropTypes.func.isRequired,
};

EngagementContainer.defaultProps = {
  deserializedEngagement: {},
  submission: null,
};

const mapStateToProps = (state) => ({
  stateHydrated: getStateHydrated(state),
  currentSectionPosition: getCurrentSectionPosition(state),
  skipSections: generateSkipSections(state),
  deserializedEngagement: deserializeEngagement(state),
  previewMode: checkPreviewMode(state),
  submission: getSubmission(state),
});

const mapDispatchToProps = {
  dispatchSetPersistedEngagement: setPersistedEngagement,
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(EngagementContainer);

export default persistedState(ConnectedComponent);
