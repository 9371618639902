// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';

// Api
import { getRequest } from 'api/v3';
import { getDiscussionTopicUrl } from 'api/v3/private';

// Components
import DiscussionTopic from 'discussionTopic/components/DiscussionTopic';

const DiscussionTopicContainer = (props) => {
  const { discussionTopic } = props;
  const discussionTopicId = discussionTopic.id;
  const { data, error } = useSWR(getDiscussionTopicUrl(discussionTopicId), getRequest);

  if (error || !data?.deserialized) return false;

  return (
    <div className="discussion-topic-container">
      <DiscussionTopic discussionTopic={data.deserialized} />
    </div>
  );
};

DiscussionTopicContainer.propTypes = {
  discussionTopic: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default DiscussionTopicContainer;
