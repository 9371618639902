/* eslint-disable react/forbid-prop-types */
// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import isPast from 'date-fns/isPast';
import isFuture from 'date-fns/isFuture';
import parseISO from 'date-fns/parseISO';
import classNames from 'classnames';

// Utilities
import { urlFormatter } from 'common/utils/helpers';

// Components
import ProjectMeta from 'project/components/ProjectMeta';

export const Phases = ({ heading, phases, t }) => {
  const renderPhase = (phase) => {
    const { id, timeFrame, title, link } = phase;
    const startDate = parseISO(phase.startDate);
    const endDate = parseISO(phase.endDate);
    const isCurrent = isPast(startDate) && isFuture(endDate);
    const isComplete = !isCurrent && isPast(endDate);

    return (
      <li
        className={classNames('project-timeline__step', {
          'project-timeline__step--current': isCurrent,
          'project-timeline__step--complete': isComplete,
        })}
        key={id}
      >
        <div
          className={classNames('project-timeline__step-content', {
            'is-primary-background': isCurrent,
          })}
        >
          {link ? (
            <a
              className={classNames({ 'is-secondary-color': !isCurrent })}
              href={urlFormatter(link)}
            >
              <h3>{title}</h3>
            </a>
          ) : (
            <h3>{title}</h3>
          )}
          <span>{t(timeFrame)}</span>
        </div>
      </li>
    );
  };

  return (
    <ProjectMeta
      heading={heading}
      icon="fas fa-align-left"
      className="project-timeline"
      headingClassName="project-timeline__heading"
    >
      <ul className="project-timeline__steps">{phases.map(renderPhase)}</ul>
    </ProjectMeta>
  );
};

Phases.propTypes = {
  heading: PropTypes.string.isRequired,
  phases: PropTypes.arrayOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Phases);
