// Libraries
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useSWRInfinite from 'swr/infinite';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

// Utils
import { redirectToSignInPath } from 'common/utils/authHelpers';
import { isStaff, updateDiscussionLikeCache, updateDiscussionReportCache } from 'discussion/utils';

// APIs
import { getRequest } from 'api/v3/helpers';
import {
  getDiscussionRepliesURL,
  createDiscussionLike,
  removeDiscussionLike,
  createDiscussionReport,
} from 'api/v3/private/discussion';

// Components
import CommentInput from 'discussion/components/CommentInput';
import ReportButton from 'discussion/components/DiscussionComment/ReportButton';

const DiscussionComment = (props) => {
  const {
    id,
    discussableType,
    discussableId,
    discussionAuthorId,
    approved,
    requireModeration,
    body,
    discussionLikesCount,
    discussionReportsCount,
    username,
    userRole,
    createdAt,
    level,
    isLoggedIn,
    replyCount,
    parentId,
    userDiscussionLikeId,
    userDiscussionReportId,
    disableActions,
    discussionStates,
    onStateChange,
    onCommentSubmit,
    onCreateLike,
    onRemoveLike,
    onCreateReport,
  } = props;
  const { t } = useTranslation();
  const [showReply, setShowReply] = useState(false);
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(!!replyCount);
  const {
    data: replyPages,
    size,
    setSize,
    mutate,
  } = useSWRInfinite((pageIndex, previousPageData) => {
    if (!discussionStates[id]?.shouldLoadMore) return null;
    if (previousPageData && !previousPageData.length) return null;
    return getDiscussionRepliesURL(id, pageIndex + 1);
  }, getRequest);

  const maxLevel = 5;
  const componentClassName = `discussion-topic-comment${
    level > 1 && level <= maxLevel ? '__child-comment' : ''
  }`;

  const loadMoreReplies = () => {
    if (!discussionStates[id]?.shouldLoadMore) {
      onStateChange(id, { shouldLoadMore: true });
      setShowLoadMoreBtn(false);
      return;
    }
    setSize(size + 1);
  };

  const formattedCreatedAt = format(new Date(createdAt), 'yyyy-MM-dd hh:mm:ss');

  const onCommentSubmitted = () => {
    setShowReply(false);
    onCommentSubmit();
    loadMoreReplies();
  };

  const replyCommentElements = useMemo(() => {
    if (!replyPages?.length) {
      return [];
    }
    return replyPages
      .map((replyPage, page) => {
        if (size >= replyPage.meta.totalPages) setShowLoadMoreBtn(false);
        if (!replyPage.deserialized?.length) return false;

        return replyPage.deserialized.map((data) => {
          return (
            <DiscussionComment
              key={data.id}
              {...data}
              id={data.id}
              parentId={id}
              level={level + 1}
              isLoggedIn={isLoggedIn}
              onCommentSubmit={onCommentSubmitted}
              onCreateLike={(discussionId, discussionLikeId) =>
                updateDiscussionLikeCache(mutate, page, discussionId, discussionLikeId)
              }
              onRemoveLike={(discussionId) =>
                updateDiscussionLikeCache(mutate, page, discussionId, null)
              }
              onCreateReport={(discussionId, discussionReportId) =>
                updateDiscussionReportCache(mutate, page, discussionId, discussionReportId)
              }
              onStateChange={onStateChange}
              discussableId={discussableId}
              disableActions={disableActions}
              discussionStates={discussionStates}
            />
          );
        });
      })
      .flat();
  }, [replyPages, discussionStates]);

  /**
   * Like or dislike a comment
   */
  const likeDiscussionAction = async () => {
    if (!isLoggedIn) {
      redirectToSignInPath(window.location.pathname);
    }

    if (userDiscussionLikeId) {
      await removeDiscussionLike(id, userDiscussionLikeId);
      onRemoveLike(id);
      return;
    }

    const response = await createDiscussionLike(id);
    if (response?.deserialized?.id) {
      // discussionId, discussionLikeId
      onCreateLike(id, response?.deserialized?.id);
    }
  };

  /**
   * Report a comment
   */
  const reportDiscussionAction = async (reasonId, description) => {
    if (!isLoggedIn) {
      redirectToSignInPath(window.location.pathname);
    }

    const response = await createDiscussionReport(id, reasonId, description);
    if (response?.deserialized?.id) {
      onCreateReport(id, response?.deserialized?.id);
    }
  };

  const showReplyInput = () => {
    if (!isLoggedIn) {
      redirectToSignInPath(window.location.pathname);
      return;
    }

    setShowReply(true);
  };

  const displayBody = () => {
    // pending comment
    if (!approved && requireModeration)
      return (
        <>
          <p>{body}</p>
          <span className="comment-reminder">
            <i className="fas fa-exclamation-triangle" />
            {t('ideation.pending_comment_reminder')}
          </span>
        </>
      );

    // rejected comment
    if (!approved)
      return (
        <span className="comment-reminder">
          <i className="fas fa-exclamation-triangle" />
          {t('ideation.moderator_message')}
        </span>
      );

    return <p>{body}</p>;
  };

  const shouldDisplayActions = approved && !requireModeration;

  return (
    <div
      className={componentClassName}
      data-id={id}
      data-topic-id={discussableId}
      data-approved={approved}
      data-level={level}
    >
      <div className="discussion-topic-comment__user-info" data-user-id={discussionAuthorId}>
        {isStaff(userRole) ? (
          <span className="staff-badge">Staff</span>
        ) : (
          <i className="zc zc24 zc-users" />
        )}
        <b>{username || '--'}・</b>
        <span>{formattedCreatedAt}</span>
      </div>
      <div>{displayBody()}</div>
      {shouldDisplayActions && (
        <div data-testid="comment-actions" className="discussion-topic-comment__actions">
          <div>
            {level < maxLevel && (
              <button type="button" disabled={disableActions} onClick={showReplyInput}>
                <i className="fas fa-reply" />
                <span>{t('ideation.reply')}</span>
              </button>
            )}
            <button type="button" disabled={disableActions} onClick={likeDiscussionAction}>
              <i
                className={classNames(
                  {
                    far: !userDiscussionLikeId,
                    fas: userDiscussionLikeId,
                  },
                  'fa-thumbs-up'
                )}
              />
              <span>
                {t('ideation.like')} {!!discussionLikesCount && `(${discussionLikesCount})`}
              </span>
            </button>
          </div>
          {/* Report Flag */}
          <div>
            <ReportButton
              userDiscussionReportId={userDiscussionReportId}
              discussionReportsCount={discussionReportsCount}
              reportDiscussionAction={reportDiscussionAction}
              disabled={disableActions}
              commentId={id}
            />
          </div>
        </div>
      )}
      {showReply && (
        <div className="discussion-topic-comment__reply">
          <CommentInput
            discussableType={discussableType}
            discussableId={discussableId}
            parentId={level >= maxLevel ? parentId : id}
            disabled={disableActions}
            onSubmit={onCommentSubmitted}
            onCancel={() => setShowReply(false)}
          />
        </div>
      )}
      {!!replyCommentElements.length && (
        <div className="discussion-topic-comment__replies">{replyCommentElements}</div>
      )}
      {showLoadMoreBtn && (
        <div className="discussion-topic-comment__load-more">
          <button type="button" onClick={loadMoreReplies}>
            <i className="fa far fa-comments" />
            {t(`ideation.${replyCount === 1 ? 'view_1_reply' : 'view_more_replies'}`, {
              count: replyCount,
            })}
          </button>
        </div>
      )}
    </div>
  );
};

const commentPropTypes = {
  id: PropTypes.string.isRequired,
  discussableId: PropTypes.number.isRequired,
  discussionAuthorId: PropTypes.number.isRequired,
  approved: PropTypes.bool.isRequired,
  body: PropTypes.string.isRequired,
  discussionLikesCount: PropTypes.number.isRequired,
  discussionReportsCount: PropTypes.number.isRequired,
  username: PropTypes.string,
  createdAt: PropTypes.string,
  level: PropTypes.number,
  userDiscussionLikeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userDiscussionReportId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DiscussionComment.propTypes = {
  ...commentPropTypes,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  level: PropTypes.number,
  isLoggedIn: PropTypes.bool,
  disableActions: PropTypes.bool,
  onCommentSubmit: PropTypes.func,
  onCreateLike: PropTypes.func,
  onRemoveLike: PropTypes.func,
  onCreateReport: PropTypes.func,
};

DiscussionComment.defaultProps = {
  parentId: null,
  level: 1,
  isLoggedIn: false,
  disableActions: false,
  onCommentSubmit: () => {},
  onCreateLike: () => {},
  onRemoveLike: () => {},
  onCreateReport: () => {},
};

export default DiscussionComment;
