// Libraries
import React from 'react';
import PropTypes from 'prop-types';

function OpenBudgetInputsHeader({ unitLabel, comparisonValueLabel }) {
  return (
    <div className="open-budget-inputs-header is-hidden-mobile">
      <div className="open-budget-inputs-header__unit">{unitLabel}</div>
      <div className="open-budget-inputs-header__comparison">{comparisonValueLabel}</div>
    </div>
  );
}

OpenBudgetInputsHeader.propTypes = {
  unitLabel: PropTypes.string,
  comparisonValueLabel: PropTypes.string,
};

OpenBudgetInputsHeader.defaultProps = {
  unitLabel: '',
  comparisonValueLabel: '',
};

export default OpenBudgetInputsHeader;
