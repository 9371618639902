// Libraries
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import Notification from 'common/components/Notification';

const UserSuspendedNotification = forwardRef(({ onClose }, ref) => {
  const { t } = useTranslation();

  return (
    <Notification
      className="idea-submission-notification"
      title="Attention!"
      onClose={onClose}
      ref={ref}
      isDanger
    >
      {t('ideation.user_suspended_notification')}
    </Notification>
  );
});

UserSuspendedNotification.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default UserSuspendedNotification;
