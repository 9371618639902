// Libraries
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import Modal from 'ideation/components/base/Modal';
import Button from 'common/components/Button';
import SocialShareLinks from 'common/components/SocialShareLinks';

const SocialShareModal = ({ isLastSection, engagement }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const onClose = () => setIsOpen(false);

  useEffect(() => {
    if (engagement.visible && isLastSection) {
      setIsOpen(true);
    }
  }, [isLastSection]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="social-share-modal" skipFocusTrap>
      <div className="social-share-modal__content">
        <h1 className="social-share-modal__heading title is-primary-color">
          {t('engagement.completion_thanks')}
        </h1>
        <p className="social-share-modal__text">{t('engagement.share_with_friends')}</p>
        <h2 className="social-share-modal__subheading">{t('common.share')}</h2>
        <div className="social-share-modal__links">
          <SocialShareLinks className="social-share-modal__link is-primary-background" />
        </div>
        <Button onClick={onClose} className="social-share-modal__skip" outlined>
          {t('engagement.skip_share')}
        </Button>
      </div>
    </Modal>
  );
};

SocialShareModal.propTypes = {
  engagement: PropTypes.shape({
    visible: PropTypes.bool,
  }).isRequired,
  isLastSection: PropTypes.bool.isRequired,
};

export default SocialShareModal;
