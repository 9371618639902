// Libraries
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

// State
import {
  getNextSectionPosition,
  getPreviousSectionPosition,
  deserializeEngagement,
  checkPreviewMode,
  saveResponses,
  validateCurrentSection,
  ensureSubmission,
  getCurrentSectionPosition,
  getResponseHistory,
  resetResponseHistory,
} from 'state/ducks/engagement';

// Routes
import Routes from 'routes';

// Contexts
import ModalProvider from 'contexts/Modal/ModalProvider';

// Components
import QuitConfirmationModal from 'engagement/components/QuitConfirmationModal';
import Button from 'common/components/Button';
import VisitHubLink from 'engagement/components/VisitHubLink';

// Utilities
import {
  classicTheme,
  isEmbedded,
  populatePath,
  rtlLanguage,
  storyTheme,
} from 'common/utils/helpers';
import { logError } from 'common/utils/errors';
import { isEngagementClosed } from 'engagement/utils/helpers';

const NavigationContainer = ({ projectPublic, projectHubEnabled }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const deserializedEngagement = useSelector(deserializeEngagement);
  const currentSectionPosition = useSelector(getCurrentSectionPosition);
  const previousSectionPosition = useSelector(getPreviousSectionPosition);
  const nextSectionPosition = useSelector(getNextSectionPosition);
  const previewMode = useSelector(checkPreviewMode);
  const responseHistory = useSelector(getResponseHistory);

  const [quitConfirmationModalVisible, setQuitConfirmationModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exitLinkContainer, setExitLinkContainer] = useState();
  const shouldRenderExitLink = Boolean(exitLinkContainer && (projectPublic || nextSectionPosition));

  const handlePreviousClick = () => {
    history.push({
      pathname: populatePath(Routes.ENGAGEMENT_SECTION, {
        ...match.params,
        position: previousSectionPosition,
      }),
      search: window.location.search,
    });
  };

  const handleNextClick = async () => {
    const goToNextSection = () =>
      history.push({
        pathname: populatePath(Routes.ENGAGEMENT_SECTION, {
          ...match.params,
          position: nextSectionPosition,
        }),
        search: window.location.search,
      });

    if (previewMode) {
      goToNextSection();
      return;
    }

    if (isEngagementClosed(deserializedEngagement)) {
      history.push(populatePath(Routes.ENGAGEMENT_CLOSED, match.params));
      return;
    }

    const validationErrors = dispatch(validateCurrentSection());
    if (!isEmpty(validationErrors)) return;

    try {
      setLoading(true);
      await dispatch(ensureSubmission());
      dispatch(resetResponseHistory());
      await dispatch(saveResponses(currentSectionPosition, nextSectionPosition, responseHistory));
      goToNextSection();
    } catch (error) {
      history.push(populatePath(Routes.ENGAGEMENT_ERROR, match.params));
      logError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setExitLinkContainer(document.getElementById('exit-links'));
  }, []);

  return (
    <div className="navigation-container">
      <div className="container inner">
        <div
          className={classNames('nav-button-container', {
            'nav-button-container__next-button-right': !previousSectionPosition,
          })}
        >
          {previousSectionPosition && (
            <Button
              data-test="previous-btn"
              onClick={handlePreviousClick}
              outlined={classicTheme()}
              className="navigation-container__steps-button navigation-container__steps-button--prev"
            >
              <div className="nav-button-content">
                <span className="icon">
                  <i
                    className={classNames('fas', {
                      'fa-long-arrow-alt-left': storyTheme() && !rtlLanguage(),
                      'fa-arrow-left': classicTheme() && !rtlLanguage(),
                      'fa-long-arrow-alt-right': storyTheme() && rtlLanguage(),
                      'fa-arrow-right': classicTheme() && rtlLanguage(),
                    })}
                  />
                </span>
                <span>{t('engagement.previous')}</span>
              </div>
            </Button>
          )}
          {nextSectionPosition && (
            <Button
              data-test="next-btn"
              onClick={handleNextClick}
              primary={classicTheme()}
              className={classNames(
                'navigation-container__steps-button navigation-container__steps-button--next',
                { 'is-loading': loading }
              )}
              disabled={loading}
            >
              <div className="nav-button-content">
                <span>{t('engagement.next')}</span>
                <span className="icon">
                  <i
                    className={classNames('fas', {
                      'fa-long-arrow-alt-right': storyTheme() && !rtlLanguage(),
                      'fa-arrow-right': classicTheme() && !rtlLanguage(),
                      'fa-long-arrow-alt-left': storyTheme() && rtlLanguage(),
                      'fa-arrow-left': classicTheme() && rtlLanguage(),
                    })}
                  />
                </span>
              </div>
            </Button>
          )}
        </div>
      </div>

      <ModalProvider>
        <QuitConfirmationModal
          isOpen={quitConfirmationModalVisible}
          onClose={() => setQuitConfirmationModalVisible(false)}
          onConfirm={() => history.push(populatePath(Routes.ENGAGEMENT_QUIT, match.params))}
        />
      </ModalProvider>

      {shouldRenderExitLink &&
        createPortal(
          <div className={classNames('is-secondary-color exit-links-container')}>
            {projectPublic && projectHubEnabled && !isEmbedded() && (
              <div className="exit-link-container-link">
                <VisitHubLink
                  icon={
                    <i
                      className={classNames('fas', {
                        'fa-long-arrow-alt-left': !rtlLanguage(),
                        'fa-long-arrow-alt-right': rtlLanguage(),
                      })}
                    />
                  }
                />
              </div>
            )}
            <div className="exit-link-divider" />
            {nextSectionPosition && (
              <div
                className="exit-link-container-link"
                onClick={() => setQuitConfirmationModalVisible(true)}
                role="button"
                tabIndex="0"
              >
                <i className="fas fa-times" />
                {t('engagement.quit_survey')}
              </div>
            )}
          </div>,
          exitLinkContainer
        )}
    </div>
  );
};

NavigationContainer.propTypes = {
  projectPublic: PropTypes.bool,
  projectHubEnabled: PropTypes.bool,
};

NavigationContainer.defaultProps = {
  projectPublic: false,
  projectHubEnabled: false,
};

export default NavigationContainer;
