/* eslint-disable react/forbid-prop-types */
// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

// Utilities
import { populatePath, rtlLanguage, translateDate } from 'common/utils/helpers';

// Routes
import Routes from 'routes';

// Components
import Link from 'common/components/Link';

const AnnouncementListItem = ({ t, match, announcement }) => {
  const {
    id,
    title,
    shortDescription,
    titleTranslations,
    shortDescriptionTranslations,
    publishedAt,
    slug,
  } = announcement;
  const {
    params: { locale },
  } = match;
  const announcementTitle = titleTranslations ? titleTranslations[locale] : title;
  const announcementDescription = shortDescriptionTranslations
    ? shortDescriptionTranslations[locale]
    : shortDescription;
  return (
    <li className="project-updates__item" key={id}>
      <Link
        href={populatePath(Routes.ANNOUNCEMENT, { ...match.params, announcementId: slug })}
        className="project-updates__item-heading is-secondary-color"
      >
        <span>
          {announcementTitle}
          <i
            className={classNames('fas', {
              'fa-long-arrow-alt-right': !rtlLanguage(),
              'fa-long-arrow-alt-left': rtlLanguage(),
            })}
          />
        </span>
      </Link>
      {publishedAt && (
        <p className="project-updates__item-subheading">{translateDate(publishedAt, t)}</p>
      )}
      {announcementDescription && (
        <p className="project-updates__item-description">{announcementDescription}</p>
      )}
    </li>
  );
};

AnnouncementListItem.propTypes = {
  t: PropTypes.func.isRequired,
  announcement: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    titleTranslations: PropTypes.objectOf(PropTypes.any),
    shortDescription: PropTypes.string,
    shortDescriptionTranslations: PropTypes.objectOf(PropTypes.any),
    publishedAt: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      locale: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withTranslation()(AnnouncementListItem);
