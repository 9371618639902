import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Options extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
  };

  render() {
    const { options } = this.props;

    return (
      <div className="matrix-input__header">
        {options.map(({ label, id }) => (
          <div key={id} className="matrix-input__header-option is-primary-color">
            <span>{label}</span>
          </div>
        ))}
      </div>
    );
  }
}

export default Options;
