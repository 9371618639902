// Libraries
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useSWRInfinite from 'swr/infinite';

// API
import { getRequest } from 'api/v3/helpers';
import { getDiscussionTopicDiscussionsURL, getIdeaDiscussionsURL } from 'api/v3/private';

// Utils
import { updateDiscussionLikeCache, updateDiscussionReportCache } from 'discussion/utils';

// Enums
import { DiscussionTopic, Idea } from 'common/enums/DiscussableTypes';

// Components
import DiscussionComment from 'discussion/components/DiscussionComment';
import SortOptions from 'discussion/components/DiscussionCommentList/SortOptions';

const DiscussionCommentList = (props) => {
  const {
    discussableType,
    discussableId,
    approvedAndModeratedCount,
    isLoggedIn,
    sorting,
    disableActions,
    onSortingChange,
    onCommentSubmit,
  } = props;
  const { t } = useTranslation();
  const [discussionStates, setDiscussionStates] = useState({});
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false);

  const {
    data: commentPages,
    size,
    setSize,
    mutate,
  } = useSWRInfinite((pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.deserialized.length) return null;

    switch (discussableType) {
      case DiscussionTopic:
        return getDiscussionTopicDiscussionsURL(discussableId, pageIndex + 1, 25, sorting);
      case Idea:
        return getIdeaDiscussionsURL(discussableId, pageIndex + 1, 25, sorting);
      default:
        return '';
    }
  }, getRequest);

  const changeDiscussionState = (discussionId, value) => {
    const state = discussionStates[discussionId]
      ? { ...discussionStates[discussionId], ...value }
      : value;

    const states = { ...discussionStates };
    states[discussionId] = state;
    setDiscussionStates(states);
  };

  const commentElements = useMemo(() => {
    if (!commentPages?.length) {
      return [];
    }

    return commentPages
      .map((commentPage, page) => {
        if (size < commentPage.meta.totalPages) {
          setShowLoadMoreBtn(true);
        } else {
          setShowLoadMoreBtn(false);
        }
        if (!commentPage.deserialized?.length) return false;

        return commentPage.deserialized.map((c) => {
          if (c.approved === false) {
            if (!c.requireModeration && c.replyCount === 0) return null;
          }

          return (
            <DiscussionComment
              key={c.id}
              onCommentSubmit={onCommentSubmit}
              onCreateLike={(discussionId, discussionLikeId) =>
                updateDiscussionLikeCache(mutate, page, discussionId, discussionLikeId)
              }
              onRemoveLike={(discussionId) =>
                updateDiscussionLikeCache(mutate, page, discussionId, null)
              }
              onCreateReport={(discussionId, discussionReportId) =>
                updateDiscussionReportCache(mutate, page, discussionId, discussionReportId)
              }
              onStateChange={changeDiscussionState}
              isLoggedIn={isLoggedIn}
              disableActions={disableActions}
              {...c}
              discussionStates={discussionStates}
            />
          );
        });
      })
      .flat();
  }, [commentPages, discussionStates]);

  const changeSorting = (sortingOption) => {
    onSortingChange(sortingOption);
  };

  const commentCountSuffix = `ideation.comment_${
    approvedAndModeratedCount > 1 ? 'plural' : 'single'
  }`;

  return (
    <div className="discussion-topic-discussions">
      <div className="discussion-topic-discussions__top-bar">
        <div>{`${approvedAndModeratedCount} ${t(commentCountSuffix)}`}</div>
        <div className="discussion-topic-discussions__top-bar__sort-options">
          {t('ideation.sort_by')} :
          <SortOptions
            defaultSorting={sorting}
            onSortingChange={changeSorting}
            ariaLabel={t('ideation.sort_by')}
          />
        </div>
      </div>
      {commentElements}
      {showLoadMoreBtn && (
        <div className="discussion-topic-discussions__load-more">
          <button
            type="button"
            className="button is-small is-primary is-primary-background"
            onClick={() => setSize(size + 1)}
          >
            {t('ideation.view_more_comments')}
          </button>
        </div>
      )}
    </div>
  );
};

DiscussionCommentList.propTypes = {
  discussableType: PropTypes.string.isRequired,
  discussableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  approvedAndModeratedCount: PropTypes.number.isRequired,
  isLoggedIn: PropTypes.bool,
  sorting: PropTypes.string.isRequired,
  disableActions: PropTypes.bool,
  onSortingChange: PropTypes.func,
  onCommentSubmit: PropTypes.func,
};

DiscussionCommentList.defaultProps = {
  isLoggedIn: false,
  disableActions: false,
  onSortingChange: () => {},
  onCommentSubmit: () => {},
};

export default DiscussionCommentList;
