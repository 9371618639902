// Libraries
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import Notification from 'common/components/Notification';

const ApprovedNotification = forwardRef(({ onClose }, ref) => {
  const { t } = useTranslation();

  return (
    <Notification
      className="idea-submission-notification"
      title={t('ideation.idea_approved_notification')}
      onClose={onClose}
      ref={ref}
      isSuccess
    >
      {t('ideation.idea_upvoted_message')}
    </Notification>
  );
});

ApprovedNotification.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ApprovedNotification;
