// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Utilities
import {
  renderMarkdown,
  determineImageSize,
  classicTheme,
  storyTheme,
  getVariationByLocale,
  getMediaDataByLocale,
} from 'common/utils/helpers';

// Components
import YoutubeEmbedder from 'common/components/YoutubeEmbedder';
import NotificationsSignup from 'common/components/NotificationsSignup';
import EngagementTile from 'project/components/EngagementTile';
import IdeaBoardTile from 'project/components/IdeaBoardTile';
import DiscussionTopicTile from 'project/components/DiscussionTopicTile';
import SponsorQuote from 'project/components/SponsorQuote';

const ProjectDetail = (props) => {
  const { project } = props;
  const {
    title,
    welcomeText,
    introduction,
    video,
    supportingImage,
    sortedAssociatedRecords,
    notificationSignUpEnabled,
    quote,
    category,
  } = project;
  const { t } = useTranslation();
  const { title: categoryTitle } = category || {};
  const { inDefaultLanguage } = gon;
  let supportingImageInSelectedLocale = supportingImage;
  let videoInSelectedLocale = video;

  if (!inDefaultLanguage) {
    supportingImageInSelectedLocale = getMediaDataByLocale(supportingImage, gon.currentLocale);
    videoInSelectedLocale = getVariationByLocale(video?.variations, gon.currentLocale, video);
  }

  return (
    <div className="project-detail">
      {classicTheme() && <h1 className="title is-2 is-size-1-desktop is-primary-color">{title}</h1>}
      <div className="content">
        <div className="content__info" role="region" aria-label={title}>
          {storyTheme() && welcomeText && (
            <div className="content__welcome-text">{welcomeText}</div>
          )}
          {categoryTitle && <div className="content__category">{categoryTitle}</div>}
          {introduction && (
            <div
              className="content__introduction introduction markdown"
              dangerouslySetInnerHTML={{ __html: renderMarkdown(introduction) }}
            />
          )}
          {supportingImageInSelectedLocale && (
            <img
              className="content__supporting-image"
              src={determineImageSize(supportingImageInSelectedLocale.imageUrls)}
              alt={supportingImageInSelectedLocale.altText}
              title={supportingImageInSelectedLocale.caption}
            />
          )}
          {videoInSelectedLocale && videoInSelectedLocale.youtubeId && (
            <div className="content__video">
              <YoutubeEmbedder id={videoInSelectedLocale.youtubeId} />
            </div>
          )}
        </div>
        {sortedAssociatedRecords.map((item) => {
          if (item.type === 'engagement')
            return <EngagementTile key={`${item.id}-${item.type}`} engagement={item} />;
          if (item.type === 'idea_board')
            return <IdeaBoardTile key={`${item.id}-${item.type}`} ideaBoard={item} />;
          if (item.type === 'discussion_topic')
            return <DiscussionTopicTile key={`${item.id}-${item.type}`} discussionTopic={item} />;
          return '';
        })}
        {storyTheme() && quote && <SponsorQuote quote={quote} />}
      </div>
      {notificationSignUpEnabled && (
        <div className="signup">
          <div className="signup__body">
            <h2 className="signup__title title is-primary-color">{t('project.stay_informed')}</h2>
            <p className="signup__message">{t('project.get_notified_message')}</p>
          </div>
          <NotificationsSignup title={title} />
        </div>
      )}
    </div>
  );
};

ProjectDetail.propTypes = {
  project: PropTypes.shape({
    title: PropTypes.string.isRequired,
    welcomeText: PropTypes.string,
    introduction: PropTypes.string,
    notificationSignUpEnabled: PropTypes.bool.isRequired,
    video: PropTypes.shape({
      youtubeId: PropTypes.string,
      variations: PropTypes.arrayOf(PropTypes.shape),
    }),
    supportingImage: PropTypes.shape({
      imageUrls: PropTypes.shape({
        desktop: PropTypes.string,
        desktopMed: PropTypes.string,
        mobile: PropTypes.string,
      }),
      links: PropTypes.shape({
        self: PropTypes.string,
      }),
      altText: PropTypes.string,
      caption: PropTypes.string,
      variations: PropTypes.arrayOf(PropTypes.shape),
    }),
    sortedAssociatedRecords: PropTypes.arrayOf(PropTypes.shape),
    quote: PropTypes.shape({
      avatarUrls: PropTypes.shape({
        desktop: PropTypes.string,
        card: PropTypes.string,
        mobile: PropTypes.string,
      }),
      message: PropTypes.string,
      name: PropTypes.string,
      jobTitle: PropTypes.string,
    }),
    category: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

ProjectDetail.defaultProps = {
  project: {
    introduction: null,
    video: null,
    sortedAssociatedRecords: [],
    category: null,
  },
};

export default ProjectDetail;
