// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Utilities
import { renderMarkdown, translateDate } from 'common/utils/helpers';

// Components
import ProjectMeta from 'project/components/ProjectMeta';

export const Events = ({ events, t }) => (
  <ProjectMeta
    heading={t('project.important_dates_heading')}
    icon="fas fa-calendar-day"
    className="project-dates"
    headingClassName="project-dates__heading"
  >
    <ul className="project-dates__items">
      {events.map(({ id, date, title, description }) => (
        <li className="project-dates__item" key={id}>
          <div className="project-dates__item-headings">
            <h3 className="project-dates__item-heading is-primary-color">
              {translateDate(date, t)}
            </h3>
            <h4 className="project-dates__item-subheading">{title}</h4>
          </div>
          {description && (
            <div
              className="introduction markdown"
              dangerouslySetInnerHTML={{ __html: renderMarkdown(description) }}
            />
          )}
        </li>
      ))}
    </ul>
  </ProjectMeta>
);

Events.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Events);
