// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

// Components
import ProjectTile from 'project/components/ProjectsContainer/ProjectTile';

const CategoryGrouping = (props) => {
  const { projects } = props;
  const { t } = useTranslation();

  const categories = Object.values(
    _.keyBy(
      projects.filter((p) => p.category).map((p) => p.category),
      (c) => c.id
    )
  );
  const prioritizedCategorySortedByTitle = _.sortBy(
    categories.filter((c) => c.prioritize),
    ['title']
  );
  const nonPrioritizedCategorySortedByTitle = _.sortBy(
    categories.filter((c) => !c.prioritize),
    ['title']
  );

  const sortedCategories = [
    ...prioritizedCategorySortedByTitle,
    ...nonPrioritizedCategorySortedByTitle,
  ];

  // Handle projects without category
  sortedCategories.push({
    title: t('project.other_active_projects'),
    id: String(null),
  });

  const projectsByCategoryIds = projects.reduce((obj, project) => {
    const o = obj;
    const projectCategoryId = String(project.category ? project.category.id : null);

    if (!o[projectCategoryId]) o[projectCategoryId] = [];

    o[projectCategoryId].push(project);

    return o;
  }, {});

  // Handle if there is no 'other active project '
  if (!projectsByCategoryIds[String(null)]) sortedCategories.pop();

  // Handle when there is only 'other active project' on the page,
  // then hide the redundant title
  if (sortedCategories.length === 1 && sortedCategories[0].id === String(null)) {
    sortedCategories[0].title = null;
  }

  const elements = sortedCategories.map((category) => {
    const categoryId = String(category.id);
    const categoryProjects = projectsByCategoryIds[categoryId];

    if (!categoryProjects?.length) return [];

    const categoryProjectElements = categoryProjects.map((categoryProject) => {
      return (
        <ProjectTile
          key={`${categoryId}-${categoryProject.id}`}
          project={categoryProject}
          showEmptyCategoryTitle={false}
        />
      );
    });

    return (
      <div key={`${category.id}-${category.title}`} className="category-group">
        <h3>{category.title}</h3>
        <p>{category.description}</p>
        <div className="projects-tiles active-project-tiles">{categoryProjectElements}</div>
      </div>
    );
  });

  return <div className="actives-projects__category-grouping">{elements}</div>;
};

CategoryGrouping.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CategoryGrouping;
