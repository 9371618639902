// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// State
import { deleteResponseEntry, upsertResponseEntry } from 'state/ducks/engagement';

function DropdownQuestion({
  question: { id, inputs, displayData },
  questionValues,
  currentSectionPosition,
  dispatchUpdateQuestionValue,
}) {
  const isMulti = Boolean(Number(displayData.multiSelectDropdown));
  const options = inputs.map((input) => ({ value: input.id, label: input.label }));
  const questionValuesBySection = questionValues[currentSectionPosition];
  const questionValuesByQuestion =
    questionValuesBySection && questionValuesBySection[id] ? questionValuesBySection[id] : {};
  const selectedInputs = Object.keys(questionValuesByQuestion).map((value) => {
    return options.find((option) => option.value === value);
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(selectedInputs);

  const onChangeMulti = (selectedOptions, meta) => {
    const questionValue = selectedOptions.reduce((accumulator, { value }) => {
      return { ...accumulator, [value]: { value: true } };
    }, {});

    dispatchUpdateQuestionValue(id, questionValue);
    setSelected(selectedOptions);

    if (meta.removedValue?.value)
      dispatch(deleteResponseEntry({ inputId: meta.removedValue.value, questionId: id }));
    if (meta.removedValues?.length > 0)
      for (const removedValue of meta.removedValues)
        dispatch(deleteResponseEntry({ inputId: removedValue.value, questionId: id }));
    if (meta.option?.value)
      dispatch(upsertResponseEntry({ inputId: meta.option.value, questionId: id }));
  };

  const onChangeSingle = (selectedOption, meta) => {
    if (selectedOption)
      onChangeMulti([selectedOption], { option: selectedOption, removedValue: selected[0] });
    else onChangeMulti([], meta);
  };

  const onChange = (value, meta) => {
    if (isMulti) onChangeMulti(value, meta);
    else onChangeSingle(value, meta);
  };

  return (
    <label data-testid="dropdown-question">
      <Select
        closeMenuOnSelect={!isMulti}
        components={makeAnimated()}
        isMulti={isMulti}
        options={options}
        value={selected}
        onChange={onChange}
        placeholder={t('engagement.dropdown_placeholder')}
        menuPortalTarget={document.body}
        isClearable
      />
    </label>
  );
}

DropdownQuestion.propTypes = {
  // Disabled eslint for questionValues as the object key/values are dynamic
  questionValues: PropTypes.object, // eslint-disable-line
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
    displayData: PropTypes.shape({
      multiSelectDropdown: PropTypes.string,
    }).isRequired,
  }).isRequired,
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
  currentSectionPosition: PropTypes.number.isRequired,
};

DropdownQuestion.defaultProps = {
  questionValues: {},
};

export default DropdownQuestion;
