// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const PreviewModeBanner = ({ message }) => (
  <div className="preview-mode-banner">
    <div className="container inner">
      <i className="fas fa-exclamation-circle" />
      {message}
    </div>
  </div>
);

PreviewModeBanner.propTypes = {
  message: PropTypes.string.isRequired,
};

export default PreviewModeBanner;
