// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Components
import Modal from 'ideation/components/base/Modal';
import Button from 'common/components/Button';

export const QuitConfirmationModal = ({ isOpen, onClose, onConfirm, t }) => (
  <Modal isOpen={isOpen} onClose={onClose} className="narrow-full-screen">
    <div className="confirmation-modal">
      <div className="content">
        <h1>{t('engagement.quit_question')}</h1>
        <div className="warning-icon">
          <i className="fas fa-exclamation-triangle" />
        </div>
        <p className="centered-text">{t('engagement.quit_confirm')}</p>
        <div className="actions">
          <Button onClick={onClose} outlined>
            {t('engagement.no_continue')}
          </Button>
          <Button onClick={onConfirm} primary>
            {t('engagement.yes_quit')}
          </Button>
        </div>
      </div>
    </div>
  </Modal>
);

QuitConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(QuitConfirmationModal);
