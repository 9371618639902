// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Enums
import ProjectGrouping from 'project/enums/ProjectGrouping';

import SingleList from './SingleList';
import CategoryGrouping from './CategoryGrouping';

const ActiveProjects = (props) => {
  const { projects, grouping } = props;

  let content = null;

  switch (grouping) {
    case ProjectGrouping.CATEGORY_GROUP:
      content = <CategoryGrouping projects={projects} />;
      break;
    case ProjectGrouping.SINGLE_LIST:
    default:
      content = <SingleList projects={projects} />;
  }

  return <div className="actives-projects">{content}</div>;
};

ActiveProjects.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  grouping: PropTypes.oneOf(Object.values(ProjectGrouping)).isRequired,
};

export default ActiveProjects;
