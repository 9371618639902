// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Rater from 'react-rater';
import { range } from 'underscore';

// Components
import RatingQuestionStar from '../RatingQuestionStar';

const RatingQuestionInput = ({ question, defaultValue, onChange }) => {
  const { displayData } = question;
  const scale = Number(displayData.scale);
  const [rating, setRating] = useState(defaultValue && Number(defaultValue));

  const onRate = (event) => {
    setRating(event.rating);
    onChange(String(event.rating));
  };

  return (
    <div className="rating-question__input">
      <Rater total={scale} rating={rating} onRate={onRate}>
        {range(scale).map((value) => (
          <RatingQuestionStar rating={value + 1} key={value} />
        ))}
      </Rater>
    </div>
  );
};

RatingQuestionInput.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(PropTypes.any).isRequired,
    required: PropTypes.bool,
    displayData: PropTypes.shape({
      scale: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

RatingQuestionInput.defaultProps = {
  defaultValue: null,
};

export default RatingQuestionInput;
