// Libraries
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { propertyOf } from 'underscore';

// Components
import CheckboxGroup from 'common/components/CheckboxGroup';
import Input from 'common/components/Input';

// Enumerables
import InputFormat from '../../../enums/InputFormat';

export class CheckboxSelectInput extends Component {
  textChoiceInputRef = createRef();

  // TODO: Move setup out of constructor
  constructor(props) {
    super(props);

    const { options, initialValue } = props;
    const selectedOptionIds = Object.keys(initialValue);
    const textChoiceIndex = options.findIndex(({ format }) => format === InputFormat.TEXT_CHOICE);
    let textChoiceOptionId = null;
    let textChoiceValue = '';

    if (textChoiceIndex !== -1) {
      const textChoiceOption = options.splice(textChoiceIndex, 1)[0];
      // Move text choice option to the end
      options.push(textChoiceOption);
      textChoiceOptionId = textChoiceOption.id;
      textChoiceValue = propertyOf(initialValue)([textChoiceOptionId, 'value']) || '';
    }

    this.state = {
      options,
      textChoiceOptionId,
      selectedOptionIds,
      textChoiceValue,
    };
  }

  fireOnChange = (lastOptionId) => {
    const { onChange } = this.props;
    const { selectedOptionIds, textChoiceOptionId, textChoiceValue } = this.state;
    const newValues = selectedOptionIds.reduce(
      (accumulator, optionId) => ({
        ...accumulator,
        [optionId]: { value: optionId === textChoiceOptionId ? textChoiceValue : true },
      }),
      {}
    );
    onChange?.(newValues, lastOptionId || textChoiceOptionId);
  };

  handleChange = (selectedOptionIds, lastOptionId) => {
    const { textChoiceOptionId } = this.state;

    this.setState({ selectedOptionIds }, () => {
      this.fireOnChange(lastOptionId);

      if (lastOptionId === textChoiceOptionId && this.textChoiceInputRef.current) {
        this.textChoiceInputRef.current.focus();
      }
    });
  };

  handleTextChoiceChange = ({ target: { value: textChoiceValue } }) => {
    this.setState({ textChoiceValue }, this.fireOnChange);
  };

  render() {
    const { t, choicesLimit } = this.props;
    const { options, selectedOptionIds, textChoiceOptionId, textChoiceValue } = this.state;

    return (
      <div className="checkbox-select-input">
        {choicesLimit && <p>{t('engagement.responses_allowed', { choicesLimit })}</p>}
        <CheckboxGroup
          options={options}
          initialValue={selectedOptionIds}
          onChange={this.handleChange}
        />
        {textChoiceOptionId && (
          <Input
            value={textChoiceValue}
            onChange={this.handleTextChoiceChange}
            disabled={!selectedOptionIds.includes(textChoiceOptionId)}
            ref={this.textChoiceInputRef}
            ariaLabel={t('Other (please specify)')}
          />
        )}
      </div>
    );
  }
}

CheckboxSelectInput.propTypes = {
  t: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
      format: PropTypes.string.isRequired,
    })
  ).isRequired,
  initialValue: PropTypes.shape({}),
  onChange: PropTypes.func,
  choicesLimit: PropTypes.string,
};

CheckboxSelectInput.defaultProps = {
  initialValue: {},
  onChange: null,
  choicesLimit: null,
};

export default withTranslation()(CheckboxSelectInput);
