// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// State
import { upsertResponseEntry } from 'state/ducks/engagement';

// Components
import SliderQuestionInput from './SliderQuestionInput';

function SliderQuestion({ question, currentQuestionValues, dispatchUpdateQuestionValue }) {
  const dispatch = useDispatch();

  const onChangeHandler = (input, value) => {
    dispatchUpdateQuestionValue(question.id, { [input.id]: { value } });
    dispatch(
      upsertResponseEntry({ inputId: input.id, questionId: question.id, inputAnswer: value })
    );
  };

  return (
    <div className="slider-question">
      {question.inputs.map((input) => (
        <SliderQuestionInput
          question={question}
          defaultValue={currentQuestionValues?.[question.id]?.[input.id]?.value}
          onChange={(value) => onChangeHandler(input, value)}
          key={input.id}
        />
      ))}
    </div>
  );
}

SliderQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    required: PropTypes.bool,
    displayData: PropTypes.shape({
      rangeEnd: PropTypes.string.isRequired,
      stepSize: PropTypes.string.isRequired,
      rangeStart: PropTypes.string.isRequired,
      sliderPosition: PropTypes.string.isRequired,
    }).isRequired,
    unitLabel: PropTypes.string,
  }).isRequired,
  currentQuestionValues: PropTypes.shape({}),
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
};

SliderQuestion.defaultProps = {
  currentQuestionValues: null,
};

export default SliderQuestion;
