// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ReactSlider from 'react-slider';
import classNames from 'classnames';

const Slider = ({ className, thumbClassName, trackClassName, ...props }) => {
  return (
    <ReactSlider
      className={classNames('slider', className)}
      thumbClassName={classNames('slider__thumb', thumbClassName)}
      trackClassName={classNames('slider__track', trackClassName)}
      {...props}
    />
  );
};

Slider.propTypes = {
  className: PropTypes.string,
  thumbClassName: PropTypes.string,
  trackClassName: PropTypes.string,
};

Slider.defaultProps = {
  className: null,
  thumbClassName: null,
  trackClassName: null,
};

export default Slider;
