// Libraries
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Enumerables
import PublicStatus from 'common/enums/PublicStatus';
import { translateDateTime } from 'common/utils/helpers';

const DateInfo = ({ publicStatus, startTime, endTime, t }) => {
  if (startTime && startTime === endTime) {
    return `${t('project.date')}: ${translateDateTime(startTime)}`;
  }

  if (endTime && publicStatus === PublicStatus.CLOSED) {
    return `${t('project.closed')}: ${translateDateTime(endTime)}`;
  }

  if (endTime && publicStatus === PublicStatus.OPEN) {
    return `${t('project.closes')}: ${translateDateTime(endTime)}`;
  }

  if (startTime && publicStatus === PublicStatus.UPCOMING) {
    return `${t('project.opens')}: ${translateDateTime(startTime)}`;
  }

  return null;
};

DateInfo.propTypes = {
  publicStatus: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  t: PropTypes.func.isRequired,
};

DateInfo.defaultProps = {
  startTime: null,
  endTime: null,
};

export default withTranslation()(DateInfo);
