import { apiCall } from './helper';

export const subscribeUser = (name, email, projectId) => {
  const body = {
    data: {
      type: 'subscriber',
      attributes: {
        name,
        email,
        project_id: projectId,
        all_projects: !projectId,
      },
    },
  };

  return apiCall('/subscribers', 'POST', JSON.stringify(body));
};
