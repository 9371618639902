// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { getMediaDataByLocale, getVariationByLocale, renderMarkdown } from 'common/utils/helpers';

// Components
import YoutubeEmbedder from 'common/components/YoutubeEmbedder';
import Media from './Media';

const Content = (props) => {
  const { content } = props;
  const { media, video, title, description } = content;

  let mediaInSelectedLocale = media;
  let videoInSelectedLocale = video;

  if (!gon.inDefaultLanguage) {
    mediaInSelectedLocale = media?.map((m) => getMediaDataByLocale(m, gon.currentLocale));
    videoInSelectedLocale = getVariationByLocale(video?.variations, gon.currentLocale, video);
  }

  return (
    <div className="content">
      {title && <h2 className="content__title">{title}</h2>}
      {description && (
        <div
          className="content__description description markdown"
          dangerouslySetInnerHTML={{ __html: renderMarkdown(description) }}
        />
      )}
      {Boolean(mediaInSelectedLocale?.length) && (
        <div className="content__media">
          <Media media={mediaInSelectedLocale} />
        </div>
      )}
      {videoInSelectedLocale && (
        <div className="content__video">
          <YoutubeEmbedder id={videoInSelectedLocale.youtubeId} />
        </div>
      )}
    </div>
  );
};

Content.propTypes = {
  content: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default Content;
