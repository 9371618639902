import PropTypes from 'prop-types';

const IdeaType = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  totalCommentCount: PropTypes.number,
});

const IdeaBoardType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  longDescription: PropTypes.string,
  publicStatus: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({
    imageUrls: PropTypes.shape({
      desktop: PropTypes.string,
      card: PropTypes.string,
      mobile: PropTypes.string,
    }),
  }),
  allowDescription: PropTypes.bool.isRequired,
  allowImage: PropTypes.bool.isRequired,
});

export { IdeaType, IdeaBoardType };
