// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

// Helpers
import { rtlLanguage } from 'common/utils/helpers';

// Constants
const SPACE = 'Space';

class SortableAccessibility extends PureComponent {
  state = { expanded: false };

  handleExpand = () => {
    this.setState((currentState) => ({
      expanded: !currentState.expanded,
    }));
  };

  addKeyDownListener = () => {
    document.addEventListener('keydown', this.handleKeyDown);
  };

  removeKeyDownListener = () => {
    document.removeEventListener('keydown', this.handleKeyDown);
  };

  handleKeyDown = (event) => {
    if (event.code === SPACE) {
      event.preventDefault();
      this.handleExpand();
    }
  };

  render() {
    const { t } = this.props;
    const { expanded } = this.state;

    return (
      <div>
        <div
          tabIndex={0}
          className="accessibility-toggle"
          onClick={this.handleExpand}
          aria-expanded={expanded}
          aria-controls="ordered-accessibility-text"
          onFocus={this.addKeyDownListener}
          onBlur={this.removeKeyDownListener}
          id="accessibility-toggle"
        >
          {t('engagement.how_to_rank_keyboard')}
          <i
            className={classNames('fas', {
              'fa-chevron-down': expanded,
              'fa-chevron-right': !expanded && !rtlLanguage(),
              'fa-chevron-left': !expanded && rtlLanguage(),
            })}
          />
        </div>
        {expanded && (
          <div
            tabIndex={0}
            role="region"
            id="ordered-accessibility-text"
            aria-labelledby="accessibility-toggle"
          >
            {t('engagement.rank_keyboard_instructions')}
          </div>
        )}
      </div>
    );
  }
}

SortableAccessibility.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SortableAccessibility);
