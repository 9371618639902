// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Routes
import Routes from 'routes';

// Utils
import { populatePath, determineImageSize } from 'common/utils/helpers';
import { currentLanguage } from 'i18n';

// Components
import IdeaActionContainer from 'ideation/components/ideaActions/IdeaActionContainer';
import WarningMessage from 'ideation/components/ideaBoard/IdeaCard/WarningMessage';

// Enums
import { IdeaType } from 'ideation/enums/propTypes';

const IdeaCard = ({ ideaBoardId, idea, publicStatus, onIdeaUpdate }) => {
  const { t } = useTranslation();
  const ideaRoute = populatePath(Routes.IDEA, {
    locale: currentLanguage,
    ideaBoardId,
    ideaId: idea.id,
  });

  const renderBody = () => {
    if (idea.responseText) {
      return (
        <div className="idea-card__response">
          <div className="idea-card__response-header">
            <i className="fas fa-comment-dots" />
            {gon.brand.name_translations[currentLanguage]}
          </div>
          <div className="idea-card__response-text">
            <p>{idea.responseText}</p>
          </div>
        </div>
      );
    }

    if (idea.heroImage?.imageUrls) {
      return (
        <img
          className="idea-card__image"
          src={determineImageSize(idea.heroImage.imageUrls)}
          alt="idea card"
        />
      );
    }

    if (idea.description) {
      return (
        <div className="idea-card__description">
          <p>{idea.description}</p>
        </div>
      );
    }

    return null;
  };

  const renderFooter = () => {
    if (idea.status === 'approved') {
      return (
        <IdeaActionContainer
          publicStatus={publicStatus}
          displayCommentCount
          ideaRoute={ideaRoute}
          idea={idea}
          onIdeaUpdate={onIdeaUpdate}
        />
      );
    }

    return <WarningMessage status={idea.status} />;
  };

  return (
    <div className="tile is-parent">
      <div className="idea-card">
        {idea.responseIcon && (
          <div className="idea-card__response-icon">
            <i className={`fas fa-${idea.responseIcon}`} />
            {t(`ideation.idea_response_icon_labels.${idea.responseIcon}`)}
          </div>
        )}
        <a href={ideaRoute} className="idea-card__content">
          <h2 className="idea-card__title is-secondary-color has-text-weight-bold">{idea.title}</h2>
          <div className="idea-card__body">{renderBody()}</div>
        </a>
        <div className="idea-card__footer">{renderFooter()}</div>
      </div>
    </div>
  );
};

IdeaCard.propTypes = {
  ideaBoardId: PropTypes.number.isRequired,
  idea: IdeaType.isRequired,
  publicStatus: PropTypes.string.isRequired,
  onIdeaUpdate: PropTypes.func.isRequired,
};

export default IdeaCard;
