// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Utilities
import { getMediaDataByLocale, getVariationByLocale, populatePath } from 'common/utils/helpers';

// Routes
import Routes from 'routes';

// Components
import Header from 'header/components/Header';
import Footer from 'common/components/Footer';
import SocialFooter from 'common/components/SocialFooter';
import AnnouncementHero from '../AnnouncementHero';
import AnnouncementDetails from '../AnnouncementDetails';
import AnnouncementSidebar from '../AnnouncementSidebar';

const AnnouncementContainer = ({ announcement, match, t }) => {
  const { project, heroImage, video, otherAnnouncements } = announcement;
  const projectLink = populatePath(Routes.PROJECT, match.params);

  let heroImageInSelectedLocale = heroImage;
  let videoInSelectedLocale = video;

  if (!gon.inDefaultLanguage) {
    heroImageInSelectedLocale = getMediaDataByLocale(heroImage, gon.currentLocale);
    videoInSelectedLocale = getVariationByLocale(video?.variations, gon.currentLocale, video);
  }

  return (
    <div className="announcement-container announcement">
      <Header project={project} />
      <main>
        <AnnouncementHero
          title={announcement.title}
          subtitle={announcement.shortDescription}
          date={announcement.publishedAt || announcement.createdAt}
          projectLink={projectLink}
          backToProjectText={t('announcement.back_to_project_page')}
          projectUpdateText={''}
        />
        <section className="container">
          <div className="columns is-desktop content-container">
            <div className="column is-three-fifths-desktop is-full-tablet">
              <AnnouncementDetails
                announcement={announcement}
                heroImage={heroImageInSelectedLocale}
                video={videoInSelectedLocale}
                t={t}
                project={project}
              />
            </div>
            <div className="column is-two-fifths-desktop">
              {otherAnnouncements.length > 0 && (
                <AnnouncementSidebar
                  title={t('announcement.more_project_updates')}
                  announcements={otherAnnouncements}
                  match={match}
                />
              )}
            </div>
          </div>
        </section>
      </main>
      <SocialFooter project={project} />
      <Footer />
    </div>
  );
};

AnnouncementContainer.propTypes = {
  announcement: PropTypes.shape({
    title: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    longDescription: PropTypes.string,
    createdAt: PropTypes.string,
    publishedAt: PropTypes.string,
    slug: PropTypes.string,
    project: PropTypes.shape.isRequired,
    heroImage: PropTypes.shape,
    video: PropTypes.shape,
    otherAnnouncements: PropTypes.arrayOf(PropTypes.shape),
  }).isRequired,
  match: PropTypes.shape.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AnnouncementContainer);
