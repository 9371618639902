export const sorts = {
  NEWEST: {
    label: 'ideation.sort_orders.newest',
    param: '-created_at',
  },
  OLDEST: {
    label: 'ideation.sort_orders.oldest',
    param: 'created_at',
  },
  MOST_POPULAR: {
    label: 'ideation.sort_orders.most_popular',
    param: 'most_popular',
  },
};
