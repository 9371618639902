/* eslint-disable react/no-array-index-key */
// Libraries
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Dot, WithStore } from 'pure-react-carousel';

// Utilities
import { clamp } from 'common/utils/helpers';

const ROW_MOVEMENT_FACTOR = 2;
const THUMBNAIL_EXPOSE_FACTOR = 3;

export class Thumbnails extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    images: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        thumbnailSrc: PropTypes.string.isRequired,
        title: PropTypes.string,
        description: PropTypes.string,
      })
    ).isRequired,
    currentSlide: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
  };

  // eslint-disable-next-line react/state-in-constructor
  state = {
    offset: 0,
  };

  containerRef = createRef();

  rowRef = createRef();

  componentDidUpdate(prevProps) {
    const { currentSlide } = this.props;

    if (currentSlide !== prevProps.currentSlide) {
      this.handleSlideChange(prevProps.currentSlide);
    }
  }

  get containerWidth() {
    return this.containerRef.current.getBoundingClientRect().width;
  }

  get rowWidth() {
    return this.rowRef.current.getBoundingClientRect().width;
  }

  get thumbnailWidth() {
    return this.rowRef.current.children[0].getBoundingClientRect().width;
  }

  get padding() {
    return this.rowRef.current.offsetLeft;
  }

  get minOffset() {
    if (this.rowWidth < this.containerWidth) {
      return 0;
    }

    return -this.rowWidth - this.padding * 2 + this.containerWidth;
  }

  get maxOffset() {
    return 0;
  }

  handleSlideChange = (prevCurrentSlide) => {
    const { currentSlide } = this.props;

    if (currentSlide < prevCurrentSlide) {
      if (!this.exposePreviousThumbnail()) {
        this.exposeNextThumbnail();
      }
    }

    if (currentSlide > prevCurrentSlide) {
      if (!this.exposeNextThumbnail()) {
        this.exposePreviousThumbnail();
      }
    }
  };

  exposePreviousThumbnail = () => {
    const { currentSlide } = this.props;
    const { offset } = this.state;
    const previousThumbnailRef = this.rowRef.current.children[currentSlide - 1];
    let moved = false;

    if (previousThumbnailRef) {
      const thumbnailOffset =
        previousThumbnailRef.offsetLeft +
        offset +
        (this.thumbnailWidth - this.thumbnailWidth / THUMBNAIL_EXPOSE_FACTOR) +
        this.padding;

      if (thumbnailOffset < 0) {
        this.setOffset(offset - thumbnailOffset);
        moved = true;
      }
    } else {
      this.setOffset(this.maxOffset);
    }

    return moved;
  };

  exposeNextThumbnail = () => {
    const { currentSlide } = this.props;
    const { offset } = this.state;
    const nextThumbnailRef = this.rowRef.current.children[currentSlide + 1];
    let moved = false;

    if (nextThumbnailRef) {
      const thumbnailOffset =
        nextThumbnailRef.offsetLeft +
        offset +
        this.thumbnailWidth / THUMBNAIL_EXPOSE_FACTOR +
        this.padding;

      if (thumbnailOffset > this.containerWidth) {
        this.setOffset(offset - (thumbnailOffset - this.containerWidth));
        moved = true;
      }
    } else {
      this.setOffset(this.minOffset);
    }

    return moved;
  };

  handlePreviousClick = () => {
    const { offset } = this.state;

    this.setOffset(offset + Math.round(this.containerWidth / ROW_MOVEMENT_FACTOR));
  };

  handleNextClick = () => {
    const { offset } = this.state;

    this.setOffset(offset - Math.round(this.containerWidth / ROW_MOVEMENT_FACTOR));
  };

  setOffset = (value) => {
    this.setState({ offset: clamp(value, this.minOffset, this.maxOffset) });
  };

  render() {
    const { images, t } = this.props;
    const { offset } = this.state;

    return (
      <div className="image-gallery__thumbnails-wrapper">
        <button
          type="button"
          className="image-gallery__thumbnails-previous is-secondary-color"
          onClick={this.handlePreviousClick}
          aria-label={t('engagement.previous')}
        >
          <i className="fas fa-angle-left" />
        </button>

        <div className="image-gallery__thumbnails" ref={this.containerRef}>
          <div className="thumbnails__shadow-left" />
          <div className="thumbnails__shadow-right" />
          <div
            className="thumbnails__row"
            style={{ transform: `translateX(${offset}px` }}
            ref={this.rowRef}
          >
            {images.map(({ thumbnailSrc }, index) => (
              <Dot
                key={index}
                slide={index}
                className="thumbnails__thumbnail"
                onClick={this.handleThumbnailClick}
                style={{ backgroundImage: `url('${thumbnailSrc}')` }}
              />
            ))}
          </div>
        </div>

        <button
          type="button"
          className="image-gallery__thumbnails-next is-secondary-color"
          onClick={this.handleNextClick}
          aria-label={t('engagement.next')}
        >
          <i className="fas fa-angle-right" />
        </button>
      </div>
    );
  }
}

export default WithStore(Thumbnails, ({ currentSlide }) => ({ currentSlide }));
