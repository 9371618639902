const sorts = {
  MOST_POPULAR: {
    sortTab: 'MOST_POPULAR',
    label: 'ideation.sort_orders.most_popular',
    param: 'most_popular',
  },
  NEWEST: {
    sortTab: 'NEWEST',
    label: 'ideation.sort_orders.newest',
    param: '-created_at',
  },
  OLDEST: {
    sortTab: 'OLDEST',
    label: 'ideation.sort_orders.oldest',
    param: 'created_at',
  },
};

export const { MOST_POPULAR, NEWEST, OLDEST } = sorts;
