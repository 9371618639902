// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { authorizeAction } from 'ideation/utils/userAuthorize';
import { userAuthenticated } from 'ideation/utils/userAuthenticate';

// Enums
import { UPVOTE, DOWNVOTE } from 'ideation/enums/votingTypes';
import PublicStatus from 'common/enums/PublicStatus';

const VotingWidget = ({
  handleVote,
  voteType,
  votedByUser,
  voteCount,
  publicStatus,
  voteTooltip,
}) => {
  const [disabled, setDisabled] = useState(false);
  const closed = publicStatus !== PublicStatus.OPEN;

  const handleClick = () => {
    if (closed || disabled) return;
    setDisabled(true);
    authorizeAction(
      userAuthenticated(),
      () => handleVote().finally(() => setDisabled(false)),
      window.location.pathname
    );
  };

  return (
    <div
      className={classNames('vote-widget idea-card__button', {
        'vote-widget--closed': closed,
        'vote-widget--disabled': disabled,
      })}
      data-tooltip={voteTooltip}
    >
      <i
        data-testid={voteType}
        tabIndex={0}
        aria-label={`${voteType} idea`}
        aria-checked={votedByUser}
        role="checkbox"
        onClick={handleClick}
        className={classNames('vote-widget__icon', {
          'fa-arrow-alt-circle-up': voteType === UPVOTE,
          'fa-arrow-alt-circle-down': voteType === DOWNVOTE,
          [`fas ${voteType === UPVOTE ? 'voted' : 'downvoted'}`]: votedByUser,
          far: !votedByUser,
        })}
      />
      <span className="has-text-weight-bold">{voteCount > 0 ? voteCount : 0}</span>
    </div>
  );
};

VotingWidget.propTypes = {
  handleVote: PropTypes.func.isRequired,
  voteType: PropTypes.string.isRequired,
  voteTooltip: PropTypes.string.isRequired,
  publicStatus: PropTypes.string.isRequired,
  votedByUser: PropTypes.bool,
  voteCount: PropTypes.number,
};

VotingWidget.defaultProps = {
  votedByUser: false,
  voteCount: 0,
};

export default VotingWidget;
