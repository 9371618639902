// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

// Enums
import PublicStatus from 'common/enums/PublicStatus';

// Utils
import { authorizeAction } from 'ideation/utils/userAuthorize';
import { userAuthenticated } from 'ideation/utils/userAuthenticate';

// Components
import FlagIdeaWidgetMenu from 'ideation/components/ideaActions/FlagIdeaWidgetMenu';

const FlagIdeaWidget = ({
  handleFlagIdea,
  flaggedByUser,
  displayMenu,
  setDisplayMenu,
  publicStatus,
  id,
}) => {
  const { t } = useTranslation();
  const disabled = publicStatus !== PublicStatus.OPEN;
  const flagAction = () =>
    authorizeAction(userAuthenticated(), () => setDisplayMenu(true), window.location.pathname);
  return (
    <>
      <button
        className={classNames('idea-card__button idea-card__button--flag', {
          'disabled-action': disabled,
        })}
        type="button"
        data-tooltip="Report Idea..."
        disabled={disabled}
        onClick={disabled ? () => {} : flagAction}
        aria-controls={`flag-idea-menu-${id}`}
      >
        <span className="icon">
          <i
            className={classNames('fa-flag', {
              'fas flagged': flaggedByUser,
              far: !flaggedByUser,
            })}
          />
        </span>
        <span className="sr-only">{t('ideation.flag_idea')}</span>
      </button>
      {displayMenu && (
        <FlagIdeaWidgetMenu
          handleFlagIdea={handleFlagIdea}
          flaggedByUser={flaggedByUser}
          setDisplayMenu={setDisplayMenu}
          id={id}
        />
      )}
    </>
  );
};

FlagIdeaWidget.propTypes = {
  handleFlagIdea: PropTypes.func.isRequired,
  setDisplayMenu: PropTypes.func.isRequired,
  publicStatus: PropTypes.string.isRequired,
  flaggedByUser: PropTypes.bool,
  displayMenu: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

FlagIdeaWidget.defaultProps = {
  flaggedByUser: false,
  displayMenu: false,
};

export default FlagIdeaWidget;
