import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import mediaQueries from 'common/utils/mediaQueries';

const Tooltip = ({ visible, position, t }) => {
  const isDesktop = useMediaQuery(mediaQueries.desktop);

  if (!isDesktop || !visible || !position) {
    return null;
  }

  return (
    <div
      className="tooltip"
      style={{
        left: position.x,
        top: position.y,
      }}
    >
      {t('engagement.pin_drop_tooltip')}
    </div>
  );
};

Tooltip.propTypes = {
  visible: PropTypes.bool,
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  t: PropTypes.func.isRequired,
};

Tooltip.defaultProps = {
  visible: false,
  position: null,
};

export default withTranslation()(Tooltip);
