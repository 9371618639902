// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { determineImageSize } from 'common/utils/helpers';

// Components
import ImageGallery from '../../ImageGallery';

export const Media = ({ media }) => {
  if (media.length >= 2) {
    const images = media.map(({ imageUrls, caption: title }) => ({
      src: determineImageSize(imageUrls),
      thumbnailSrc: determineImageSize(imageUrls),
      title,
    }));

    return <ImageGallery images={images} />;
  }

  if (media.length === 1) {
    return <img src={determineImageSize(media[0].imageUrls)} alt={media[0].altText} />;
  }

  return null;
};

Media.propTypes = {
  media: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Media;
