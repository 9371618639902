// Libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// State
import { clearUserData } from 'state/ducks/engagement';

// Utilities
import { fetchProject } from 'api/rest';
import { withPersistContext } from 'common/utils/reduxPersist';
import { populatePath, jsonaDeserialize, isEmbedded } from 'common/utils/helpers';

// Routes
import Routes from 'routes';

// Components
import Header from 'header/components/Header';
import Footer from 'common/components/Footer';
import Button from 'common/components/Button';
import NotificationsSignup from 'common/components/NotificationsSignup';
import VisitHubLink from '../VisitHubLink';

const QuitPageContainer = ({ match, history, persistContext, dispatchClearUserData, t }) => {
  const [project, setProject] = useState(null);

  useEffect(() => {
    const getProject = async () => {
      const fetchedProject = await fetchProject(match.params.projectId);
      setProject(jsonaDeserialize(fetchedProject));
    };
    getProject();
    dispatchClearUserData();
    persistContext.clearStorage();
  }, []);

  const handleRestartClick = () => history.push(populatePath(Routes.ENGAGEMENT, match.params));

  return (
    <div className="quit-page">
      <Header project={project} />
      <section className="hero">
        <div className="hero-body">
          <div className="container inner">
            <h1 className="title">{t('engagement.sorry')}</h1>
            <p>{t('engagement.responses_erased_message')}</p>
            <p>{t('engagement.try_again_message')}</p>
            <div className="actions">
              <Button onClick={() => handleRestartClick()} primary>
                {t('engagement.restart_engagement_button')}
              </Button>
            </div>
          </div>
        </div>
      </section>
      <Footer>
        <div className="footer-wrapper">
          <div className="container inner">
            <div className="columns is-multiline">
              <div className="column is-full">
                <h2 className="title">{t('engagement.stay_involved')}</h2>
              </div>
              {project?.notificationSignUpEnabled && (
                <div className="column">
                  <p>{t('engagement.get_notified_message')}</p>
                  <NotificationsSignup title={project.title} />
                </div>
              )}
              {project?.public && project?.projectHubEnabled && !isEmbedded() && (
                <div className="column">
                  <p>{t('engagement.learn_more')}</p>
                  <VisitHubLink button />
                </div>
              )}
            </div>
          </div>
        </div>
      </Footer>
    </div>
  );
};

QuitPageContainer.propTypes = {
  persistContext: PropTypes.shape({
    clearStorage: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string,
    }),
  }).isRequired,
  dispatchClearUserData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchClearUserData: clearUserData,
};

const ConnectedComponent = connect(null, mapDispatchToProps)(QuitPageContainer);

export default withPersistContext(withRouter(withTranslation()(ConnectedComponent)));
