// Libraries
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Hooks
import { useKeyListener } from 'common/hooks/useKeyListener';
import { useClickOutsideNode } from 'common/hooks/useClickOutsideNode';

// Enums
import Keys from 'common/enums/Key';
import { COMMENT_OFFENSIVE, COMMENT_SPAM, COMMENT_OTHER } from 'ideation/enums/flagIdeaReasons';

const FlagIdeaWidgetMenu = ({ flaggedByUser, handleFlagIdea, setDisplayMenu, id }) => {
  const menu = useRef(null);
  const hideMenu = () => setDisplayMenu(false);
  const { t } = useTranslation();

  useClickOutsideNode(menu, hideMenu);
  useKeyListener(Keys.ESCAPE, hideMenu);

  return (
    <div className="flag-idea-menu-anchor" id={`flag-idea-menu-${id}`}>
      <div className="flag-idea-menu" aria-label="flag idea options" tabIndex={0} ref={menu}>
        {flaggedByUser ? (
          <p className="flag-idea-text">{t('ideation.idea_flagged')}</p>
        ) : (
          <>
            <button
              className="flag-idea-button"
              type="button"
              onClick={() => handleFlagIdea(COMMENT_OFFENSIVE)}
            >
              {t('ideation.report_offensive')}
            </button>
            <button
              className="flag-idea-button"
              type="button"
              onClick={() => handleFlagIdea(COMMENT_SPAM)}
            >
              {t('ideation.report_spam')}
            </button>
            <button
              className="flag-idea-button"
              type="button"
              onClick={() => handleFlagIdea(COMMENT_OTHER)}
            >
              {t('ideation.report_other')}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

FlagIdeaWidgetMenu.propTypes = {
  handleFlagIdea: PropTypes.func.isRequired,
  setDisplayMenu: PropTypes.func.isRequired,
  flaggedByUser: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

FlagIdeaWidgetMenu.defaultProps = {
  flaggedByUser: false,
};

export default FlagIdeaWidgetMenu;
