// Libraries
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Link = ({ children, className, button, primary, outlined, ariaLabelledBy, ...props }) => (
  <a
    aria-labelledby={ariaLabelledBy}
    className={classNames(
      {
        button,
        'is-primary is-primary-background': primary,
        'is-outlined is-primary-outline is-secondary-color': outlined,
      },
      className
    )}
    {...props}
  >
    {children}
  </a>
);

Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  button: PropTypes.bool,
  primary: PropTypes.bool,
  outlined: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
};

Link.defaultProps = {
  className: null,
  button: false,
  primary: false,
  outlined: false,
  ariaLabelledBy: null,
};

export default Link;
