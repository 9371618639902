// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IdeaBoardHeaderContainer = ({
  backgroundImageUrl,
  backgroundImagePosition,
  single,
  children,
}) => {
  return (
    <div
      className={classNames('idea-board-header', {
        'idea-board-header--image': backgroundImageUrl,
        'idea-board-header--single': single,
      })}
      style={{
        backgroundImage: backgroundImageUrl && `url("${backgroundImageUrl}")`,
        backgroundPosition: backgroundImagePosition || 'center',
      }}
      role="banner"
    >
      {backgroundImageUrl && <div className="idea-board-header--dim" />}
      {children}
    </div>
  );
};

IdeaBoardHeaderContainer.propTypes = {
  backgroundImageUrl: PropTypes.string,
  backgroundImagePosition: PropTypes.string,
  single: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

IdeaBoardHeaderContainer.defaultProps = {
  backgroundImageUrl: null,
  backgroundImagePosition: 'center',
  single: null,
};

export default IdeaBoardHeaderContainer;
