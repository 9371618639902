// Libraries
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { currentLanguage } from 'i18n';

// State
import { getFingerprintConsent, updateFingerprintConsent } from 'state/ducks/engagement';

// Components
import CheckboxGroup from 'common/components/CheckboxGroup';
import camelcaseKeys from 'camelcase-keys';

function FingerprintCollectionConsent() {
  const fingerprintConsent = useSelector(getFingerprintConsent);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    consentDigitalFingerprint,
    enableCustomizeDigitalFingerprintText,
    customizeDigitalFingerprintHeadingTranslations,
    customizeDigitalFingerprintDescriptionTranslations,
  } = camelcaseKeys(gon.brand);

  if (consentDigitalFingerprint === false) return null;

  const customizeHeading =
    customizeDigitalFingerprintHeadingTranslations &&
    (customizeDigitalFingerprintHeadingTranslations[currentLanguage] ||
      customizeDigitalFingerprintHeadingTranslations.en);
  const customizeDescription =
    customizeDigitalFingerprintDescriptionTranslations &&
    (customizeDigitalFingerprintDescriptionTranslations[currentLanguage] ||
      customizeDigitalFingerprintDescriptionTranslations.en);

  return (
    <div className="container inner">
      <div className="fingerprint-collection-consent">
        <h1 className="fingerprint-collection-consent__title title is-primary-color">
          {enableCustomizeDigitalFingerprintText && customizeHeading
            ? customizeHeading
            : t('engagement.fingerprint_collection_header')}
        </h1>
        {enableCustomizeDigitalFingerprintText && customizeDescription ? (
          <pre className="is-size-6 fingerprint-collection-consent__desc">
            {customizeDescription}
          </pre>
        ) : (
          <Trans i18nKey="engagement.fingerprint_collection_description">
            <p>
              For the purpose of preventing duplicate submissions to this survey from the same
              participant, we would like your consent to collect information about your browser and
              attach it to your submission.
            </p>
            <p>
              You have the option to continue without consent, but your submission may be excluded
              from the results of this survey.
            </p>
          </Trans>
        )}
        <div className="fingerprint-collection-consent__checkbox">
          <CheckboxGroup
            options={[
              {
                id: 'consent',
                label: t('engagement.fingerprint_collection_consent', {
                  brand: gon.whitelabelName || 'Zencity Engage',
                }),
              },
            ]}
            initialValue={fingerprintConsent ? ['consent'] : []}
            onChange={([value]) => dispatch(updateFingerprintConsent(Boolean(value)))}
          />
        </div>
      </div>
    </div>
  );
}

export default FingerprintCollectionConsent;
