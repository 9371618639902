// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Contexts
import ModalProvider from 'contexts/Modal/ModalProvider';

// Components
import CheckBox from 'common/components/CheckBox';
import BudgetMediaModal from '../BudgetMediaModal';

class BudgetCheckbox extends Component {
  state = { displayMediaModal: false };

  openModal = () => this.setState({ displayMediaModal: true });

  closeModal = () => this.setState({ displayMediaModal: false });

  toggleMediaModal = () =>
    this.setState((state) => ({
      displayMediaModal: !state.displayMediaModal,
    }));

  render() {
    const { clickHandler, option, checked } = this.props;
    const { id, label, displayData } = option;
    const { displayMediaModal } = this.state;
    const { amount } = displayData;

    return (
      <tr key={id} className="checkbox" style={checked ? { background: '#d7d7d7' } : {}}>
        <td className="checkbox-contents">
          <label className="checkbox__control-label" htmlFor={id}>
            <CheckBox checked={checked} onChange={clickHandler(id)} id={id} />
            <div className="checkbox__control-display" />
            <p className="checkbox__control-text is-primary-color budget-label">{label}</p>
          </label>
          <div className="checkbox-data">
            <div
              className={classNames('budget-amount', {
                'extra-wide': !option.media && !displayData.description,
              })}
            >
              {Number(amount).toLocaleString()}
            </div>
            {(option.media || displayData.description) && (
              <>
                <ModalProvider>
                  <BudgetMediaModal
                    isOpen={displayMediaModal}
                    onClose={this.closeModal}
                    budgetOption={option}
                  />
                </ModalProvider>
                <i className="fas fa-info-circle is-secondary-color" onClick={this.openModal} />
              </>
            )}
          </div>
        </td>
      </tr>
    );
  }
}

BudgetCheckbox.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  option: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.string,
    displayData: PropTypes.shape({
      amount: PropTypes.string,
      description: PropTypes.string,
    }),
    media: PropTypes.shape({}),
  }).isRequired,
  checked: PropTypes.bool,
};

BudgetCheckbox.defaultProps = {
  checked: false,
};

export default BudgetCheckbox;
