// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { currentLanguage } from 'i18n';
import camelCaseKeys from 'camelcase-keys';
import _ from 'lodash';

// Components
import Header from 'header/components/Header';
import Footer from 'common/components/Footer';
import SocialShareToolbar from 'common/components/SocialShareToolbar';
import SocialFooter from 'common/components/SocialFooter';
import ActiveProjects from 'project/components/ProjectsContainer/ActiveProjects';
import CategoryList from 'project/components/ProjectsContainer/CategoryList';
import FinishedProjects from 'project/components/ProjectsContainer/FinishedProjects';
import Opportunities from 'project/components/ProjectsContainer/Opportunities';
import NotificationsSignup from 'common/components/NotificationsSignup';

// Utilities
import { populatePath, determineImageSize } from 'common/utils/helpers';

// Routes
import routes from 'routes';

// Enumerables
import ProjectStatus from 'project/enums/ProjectStatus';
import ProjectGrouping from 'project/enums/ProjectGrouping';

export const ProjectsContainer = (props) => {
  const { projects, categories, opportunities, match, t } = props;
  const getProjectUrl = (projectId) => populatePath(routes.PROJECT, { ...match.params, projectId });

  const {
    headline,
    headlineTranslations,
    description,
    descriptionTranslations,
    heroImageUrl,
    heroImagePosition,
    showCallsToAction,
    projectGrouping,
    featuredProjectId,
    facebookUrl,
    twitterUrl,
    contactEmail,
    socialSharing,
  } = camelCaseKeys(gon.brand, { deep: true });

  const categoriesById = _.keyBy(categories, 'id');
  for (const p of projects) {
    if (categoriesById[p.categoryId]) p.category = categoriesById[p.categoryId];
  }
  const active = projects.filter(({ status }) => status === ProjectStatus.ACTIVE);
  const finished = projects.filter(({ status }) => status === ProjectStatus.FINISHED);
  const featuredIndex = active.findIndex((element) => Number(element.id) === featuredProjectId);
  const featured = active[featuredIndex];
  const locale = _.camelCase(currentLanguage || window.gon.defaultLanguage);
  if (featured) {
    active.splice(featuredIndex, 1);
  }

  const heroImage = heroImageUrl ? `url(${heroImageUrl})` : null;
  const backgroundPosition =
    heroImagePosition === 'left' || heroImagePosition === 'right' ? heroImagePosition : 'center';
  const categoriesOnly = projectGrouping === ProjectGrouping.CATEGORIES_ONLY;

  const txtFeaturedProject = t('project.featured_projects');
  const txtViewProject = t('project.view_project');
  const txtActiveProjects = t('project.active_projects');
  const txtFinishedProjects = t('project.finished_projects');
  const txtOpportunitiesToEngage = t('project.opportunities_to_engage');

  return (
    <div className="has-background-light projects-container">
      <div className="project-index">
        <a className="skip-main" href="#main">
          {t('project.skip_to_main')}
        </a>
        <Header />
        <main id="main">
          {/* Headline & welcome message */}
          <div
            className="content-hero hero is-primary"
            style={{ backgroundImage: heroImage, backgroundPosition }}
          >
            <div className={heroImage ? 'content-hero__dim' : ''}>
              <div className="content-hero__intro container">
                <h1>{(headlineTranslations && headlineTranslations[locale]) || headline}</h1>
                <div className="content-hero__intro-body">
                  <p>
                    {(descriptionTranslations && descriptionTranslations[locale]) || description}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {socialSharing && <SocialShareToolbar />}

          {/* Featured project */}
          {featuredProjectId && featured && (
            <section className="section" aria-label={txtFeaturedProject}>
              <div className="featured-project container inner">
                <div className="content">
                  <h2>{txtFeaturedProject}</h2>
                </div>

                <div className="featured-project__card">
                  <div className="featured-project__card__image">
                    {featured.heroImage && (
                      <img
                        src={determineImageSize(featured.heroImage?.imageUrls)}
                        alt={featured.heroImage.altText}
                        title={featured.heroImage?.caption}
                        style={{ objectPosition: featured?.heroImage?.objectPosition || 'center' }}
                      />
                    )}
                  </div>
                  <div className="featured-project__card__body">
                    <div>
                      <div className="featured-project__card__body__category">
                        {featured.category?.title}
                      </div>
                      <div className="featured-project__card__body__info">
                        <h3 className="title is-primary-color" id={`featured_${featured.id}`}>
                          {featured.title}
                        </h3>
                        {featured.teaser && <p>{featured.teaser}</p>}
                      </div>
                    </div>
                    <div className="featured-project__card__body__action">
                      <a
                        href={getProjectUrl(featured.slug || featured.id)}
                        className="button is-primary is-primary-background"
                        aria-labelledby={`featured_${featured.id}`}
                      >
                        {txtViewProject}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Active projects */}
          {!!active.length && !categoriesOnly && (
            <section className="section" aria-label={txtActiveProjects}>
              <div className="container">
                <div className="content">
                  <h2>{txtActiveProjects}</h2>
                  <div className="mt-6">
                    <ActiveProjects projects={active} grouping={projectGrouping} />
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Category List */}
          {!!categories.length && categoriesOnly && (
            <section className="section" aria-label={t('project.view_category')}>
              <div className="container">
                <div className="content">
                  <div className="mt-6">
                    <CategoryList categories={categories} projects={projects} />
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Opportunities */}
          {!!opportunities?.length && showCallsToAction && (
            <section
              className="section opportunities-container has-background-white"
              aria-label={txtOpportunitiesToEngage}
            >
              <div className="container">
                <div className="content">
                  <h2>{txtOpportunitiesToEngage}</h2>
                  <div className="opportunities">
                    <Opportunities projects={projects} opportunities={opportunities} />
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Finished projects */}
          {!categoriesOnly && !!finished.length && (
            <section className="section" aria-label={txtFinishedProjects}>
              <div className="container">
                <div className="content">
                  <h2>{txtFinishedProjects}</h2>
                  <div className="projects-tiles finished-project-tiles">
                    <FinishedProjects projects={finished} />
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className="section social-footer" aria-label={t('project.stay_informed')}>
            <div className="container">
              <div className="content">
                <div className="projects-stay-informed">
                  <div className="has-text-white">
                    <h2>
                      <span className="has-text-white">{t('project.stay_informed')}</span>
                    </h2>
                    <p>{t('project.stay_informed_desc')}</p>
                  </div>
                  <div>
                    <NotificationsSignup title="" t={t} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {(facebookUrl || twitterUrl || contactEmail) && (
            <div className="projects-container-footer">
              <SocialFooter
                className="has-text-white"
                project={{
                  facebookUrl,
                  twitterUrl,
                  contactEmail,
                }}
              />
            </div>
          )}
        </main>
        <Footer />
      </div>
    </div>
  );
};

ProjectsContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      locale: PropTypes.string,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      slug: PropTypes.string,
      filter: PropTypes.string,
    })
  ).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  opportunities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRouter(withTranslation()(ProjectsContainer));
