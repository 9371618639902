// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputLengthDisplay = ({ minLength, currentLength, maxLength }) => (
  <div className="length-validation-helper">
    <span
      className={classNames('current-value', {
        'current-value-invalid': currentLength > maxLength || currentLength < minLength,
      })}
    >
      {currentLength}
    </span>
    <span>{`/${maxLength}`}</span>
  </div>
);

InputLengthDisplay.propTypes = {
  currentLength: PropTypes.number,
  maxLength: PropTypes.number.isRequired,
  minLength: PropTypes.number,
};

InputLengthDisplay.defaultProps = {
  currentLength: 0,
  minLength: 0,
};

export default InputLengthDisplay;
