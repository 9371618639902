// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

// Enums
import { IdeaType } from 'ideation/enums/propTypes';

const CommentCountWidget = ({ ideaRoute, idea, userHasCommented }) => {
  const { t } = useTranslation();

  return (
    <div
      className="idea-card__button idea-card__button--comment-count"
      data-tooltip={t('ideation.comment_on_idea_tooltip')}
    >
      <a href={ideaRoute} className="icon-link">
        <span className="icon">
          <i
            className={classNames('far is-left fa-comment-alt', {
              'fas is-active': userHasCommented,
            })}
          />
        </span>
        <span className="sr-only">Comment count</span>
        <span className="has-text-weight-bold">{idea.approvedAndModeratedCount || 0}</span>
      </a>
    </div>
  );
};

CommentCountWidget.propTypes = {
  ideaRoute: PropTypes.string.isRequired,
  idea: IdeaType.isRequired,
  userHasCommented: PropTypes.bool,
};

CommentCountWidget.defaultProps = {
  userHasCommented: false,
};

export default CommentCountWidget;
