// Libraries
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

// State
import { deleteResponseEntry, upsertResponseEntry } from 'state/ducks/engagement';

// Components
import NumberInput from 'common/components/NumberInput';

function NumericQuestion({ question, currentQuestionValues, dispatchUpdateQuestionValue }) {
  const { displayData, unitLabel } = question;
  const { decimalDigits, rangeStart, rangeEnd } = displayData;
  const dispatch = useDispatch();
  const dispatchDebounced = useCallback(debounce(dispatch, 300), []);

  const onChangeHandler = (input, event) => {
    const { value } = event.target;
    dispatchUpdateQuestionValue(question.id, { [input.id]: { value } });
    if (value === '') {
      dispatchDebounced(
        deleteResponseEntry({
          inputId: input.id,
          questionId: question.id,
          inputAnswer: '',
        })
      );
      return;
    }

    dispatchDebounced(
      upsertResponseEntry({ inputId: input.id, questionId: question.id, inputAnswer: value })
    );
  };

  return (
    <div className="numeric-question">
      {question.inputs.map((input) => (
        <div className="numeric-question__input" key={input.id}>
          <NumberInput
            className="numeric-question__number-input"
            defaultValue={currentQuestionValues?.[question.id]?.[input.id]?.value}
            decimals={Number(decimalDigits)}
            min={Number(rangeStart)}
            max={Number(rangeEnd)}
            onChange={(event) => onChangeHandler(input, event)}
            ariaLabel={question.title}
          />
          <div className="numeric-question__units">{unitLabel}</div>
        </div>
      ))}
    </div>
  );
}

NumericQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    required: PropTypes.bool,
    displayData: PropTypes.shape({
      decimalDigits: PropTypes.string.isRequired,
      rangeStart: PropTypes.string.isRequired,
      rangeEnd: PropTypes.string.isRequired,
    }).isRequired,
    unitLabel: PropTypes.string,
  }).isRequired,
  currentQuestionValues: PropTypes.shape({}),
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
};

NumericQuestion.defaultProps = {
  currentQuestionValues: null,
};

export default NumericQuestion;
