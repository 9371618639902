// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from 'ideation/components/base/Modal';
import CreateIdeaForm from 'ideation/components/createIdea/CreateIdeaForm';

const CreateIdeaModal = ({
  isOpen,
  onClose,
  setSort,
  searchValue,
  resetSearchValues,
  ideaBoardId,
  projectId,
  allowDescription,
  allowImage,
  finalTranscript,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} className="narrow-full-screen">
    <CreateIdeaForm
      ideaBoardId={ideaBoardId}
      projectId={projectId}
      allowDescription={allowDescription}
      allowImage={allowImage}
      setSort={setSort}
      resetSearchValues={resetSearchValues}
      searchValue={searchValue}
      onClose={onClose}
      finalTranscript={finalTranscript}
    />
  </Modal>
);

CreateIdeaModal.propTypes = {
  ideaBoardId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  allowDescription: PropTypes.bool.isRequired,
  allowImage: PropTypes.bool.isRequired,
  setSort: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  resetSearchValues: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  finalTranscript: PropTypes.string.isRequired,
};

CreateIdeaModal.defaultProps = {
  searchValue: '',
};

export default CreateIdeaModal;
