// Libraries
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Enums
import * as eumns from 'discussion/enums';

const SortOptions = ({ defaultSorting, onSortingChange, ariaLabel }) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState(defaultSorting);

  const options = Object.keys(eumns.sorts).map((key) => {
    const option = eumns.sorts[key];

    return (
      <option key={option.param} value={option.param}>
        {t(option.label)}
      </option>
    );
  });

  useEffect(() => onSortingChange(sorting), [sorting]);

  return (
    <select value={sorting} onChange={(e) => setSorting(e.target.value)} aria-label={ariaLabel}>
      {options}
    </select>
  );
};

SortOptions.propTypes = {
  defaultSorting: PropTypes.oneOf(Object.values(eumns.sorts).map((o) => o.param)),
  onSortingChange: PropTypes.func,
  ariaLabel: PropTypes.string.isRequired,
};

SortOptions.defaultProps = {
  defaultSorting: eumns.sorts.NEWEST.param,
  onSortingChange: () => {},
};

export default SortOptions;
