// Libraries
import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import camelcaseKeys from 'camelcase-keys';

// Utilities
import { renderMarkdown, determineImageSize, classicTheme, storyTheme } from 'common/utils/helpers';
import { withPersistContext } from 'common/utils/reduxPersist';

// Components
import Block from 'engagement/components/Block';
import Quote from 'engagement/components/Quote';
import FingerprintCollectionConsent from 'engagement/components/FingerprintCollectionConsent';

export class Section extends PureComponent {
  titleRef = createRef();

  blockRefs = new Map();

  componentDidUpdate(prevProps) {
    const {
      questionErrors,
      currentSection,
      match: {
        params: { position },
      },
    } = this.props;

    const previousPosition = prevProps.match.params.position;
    const previousQuestionErrors = prevProps.questionErrors;

    // focus on section title when changing sections
    if (previousPosition !== position) {
      this.titleRef.current.focus();
    }

    // scroll to the first question error
    if (Object.keys(questionErrors).length && questionErrors !== previousQuestionErrors) {
      const questionId = Object.keys(questionErrors)[0];
      const questionBlock = currentSection.blocks.find(
        (block) => block.question && block.question.id === questionId
      );
      const top = this.blockRefs.get(questionBlock.id).getBoundingClientRect().top + window.scrollY;

      window.scrollTo({ top });
    }
  }

  getBlockSaveRef = (blockId) => (blockRef) => {
    if (blockRef) {
      this.blockRefs.set(blockId, blockRef);
    }
  };

  getBlockClearRef = (blockId) => () => {
    this.blockRefs.delete(blockId);
  };

  heroBackgroundImage = (blocks, isFirstSection) => {
    if (storyTheme() && isFirstSection) {
      const block = blocks[0];
      if (!block || !block.content) {
        return null;
      }
      const { media } = block.content;

      if (media?.length) {
        const imageUrl = determineImageSize(media[0].imageUrls);

        return `url(${imageUrl})`;
      }
    }

    return null;
  };

  blocksToRender = (blocks, isFirstSection, introEnabled) => {
    if (storyTheme() && isFirstSection && introEnabled) {
      const block = blocks[0];
      if (!block || !block.content) return [];
      const { description, video } = block.content;

      if (!description && !video) {
        return [];
      }

      const newBlock = {
        ...block,
        content: { ...block.content, media: [] },
      };

      return [newBlock];
    }

    return blocks;
  };

  renderQuote = (quote) => (
    <div className="section-wrapper__quote container inner">
      <Quote
        photoUrl={determineImageSize(quote.avatarUrls)}
        name={quote.name}
        position={quote.jobTitle}
      >
        {quote.message ? <blockquote>{quote.message}</blockquote> : <span />}
      </Quote>
    </div>
  );

  render() {
    const { consentDigitalFingerprint } = camelcaseKeys(gon.brand);
    const {
      currentSection,
      isFirstSection,
      isLastSection,
      collectDigitalFingerprint,
      introEnabled,
    } = this.props;

    if (!currentSection) {
      return null;
    }

    const { title, description, quote, blocks } = currentSection;
    const heroBackgroundImage = this.heroBackgroundImage(blocks, isFirstSection);
    const blocksToRender = this.blocksToRender(blocks, isFirstSection, introEnabled);
    const skippedIntro = currentSection.position === 2;

    const shouldShowShowCollectDigitalFingerprintOnTop =
      skippedIntro && isFirstSection && collectDigitalFingerprint && consentDigitalFingerprint;
    const shouldShowShowCollectDigitalFingerprintAtBottom =
      !skippedIntro && isFirstSection && collectDigitalFingerprint && consentDigitalFingerprint;

    return (
      <div
        className={classNames('section-wrapper', {
          'section-wrapper--first': isFirstSection,
          'section-wrapper--last': isLastSection,
        })}
      >
        {shouldShowShowCollectDigitalFingerprintOnTop && (
          <>
            <FingerprintCollectionConsent />
            <div className="mb-6" />
          </>
        )}
        <section
          className={classNames('hero section-wrapper__hero section-wrapper__hero--engagement', {
            'section-wrapper__hero--image': heroBackgroundImage,
            'section-wrapper__hero--quote': quote,
          })}
          aria-label="Page Heading"
          style={{ backgroundImage: heroBackgroundImage }}
        >
          <div className="hero-body">
            <div className="container inner">
              <h1 className="title is-spaced is-primary-color" ref={this.titleRef} tabIndex="-1">
                {title}
              </h1>
            </div>
            {storyTheme() && quote && this.renderQuote(quote)}
          </div>
        </section>

        <section className="section-wrapper__main section" id="main-content">
          {description && (
            <div className="section-wrapper__intro" aria-label="Page Intro Text">
              <div className="container inner">
                <div
                  className="description markdown"
                  dangerouslySetInnerHTML={{ __html: renderMarkdown(description) }}
                />
              </div>
            </div>
          )}
          {Boolean(blocksToRender.length) && (
            <div className="container inner">
              {blocksToRender.map((block) => (
                <Block
                  key={block.id}
                  block={block}
                  saveRef={this.getBlockSaveRef(block.id)}
                  clearRef={this.getBlockClearRef(block.id)}
                />
              ))}
            </div>
          )}
          {classicTheme() && quote && this.renderQuote(quote)}
          {shouldShowShowCollectDigitalFingerprintAtBottom && <FingerprintCollectionConsent />}
        </section>
      </div>
    );
  }
}

Section.propTypes = {
  currentSection: PropTypes.objectOf(PropTypes.shape),
  questionErrors: PropTypes.objectOf(PropTypes.shape).isRequired,
  isFirstSection: PropTypes.bool,
  isLastSection: PropTypes.bool,
  introEnabled: PropTypes.bool,
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
  collectDigitalFingerprint: PropTypes.bool.isRequired,
};

Section.defaultProps = {
  currentSection: null,
  isFirstSection: false,
  isLastSection: false,
  introEnabled: false,
};

export default withPersistContext(withRouter(Section));
