// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CategoryItem from 'project/components/ProjectsContainer/CategoryList/CategoryItem';

const CategoryList = (props) => {
  const { categories, projects } = props;

  const categoriesByIds = _.keyBy(
    categories.map((c) => Object.assign(c, { projectCount: 0 })),
    'id'
  );

  const projectsByCategoryIds = _.groupBy(projects, 'categoryId');

  for (const project of projects) {
    const category = categoriesByIds[project.categoryId];
    const activeProjectOfCategory = projectsByCategoryIds[project.categoryId];
    if (!category || !activeProjectOfCategory) continue;

    category.projectCount += activeProjectOfCategory.length;
  }

  const stuffedCategories = _.sortBy(Object.values(categoriesByIds), ['title']).reduce(
    (acc, c) => {
      if (!c.projectCount) return acc;

      if (c.prioritize) {
        acc.prioritized.push(c);
        return acc;
      }

      acc.nonPrioritized.push(c);

      return acc;
    },
    {
      prioritized: [],
      nonPrioritized: [],
    }
  );

  const sortedCategories = [...stuffedCategories.prioritized, ...stuffedCategories.nonPrioritized];

  const items = sortedCategories.map((c) => <CategoryItem key={c.id} category={c} />);

  return <div className="category-list">{items}</div>;
};

CategoryList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CategoryList;
