// Libraries
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// State
import { deleteResponseEntry, upsertResponseEntry } from 'state/ducks/engagement';

// Components
import BudgetInput from 'engagement/components/inputs/BudgetInput';
import BudgetBox from '../BudgetBox';

export function BudgetQuestion(props) {
  const { question, currentQuestionValues, dispatchUpdateQuestionValue } = props;
  const { inputs, unitLabel, displayData } = question;
  const budgetAsNumber = Number(displayData.budget);
  const questionValue = currentQuestionValues?.[question.id] || {};
  const dispatch = useDispatch();

  const currentBudgetUsed = useMemo(() => {
    let budgetUsed = 0;
    for (const input of inputs) {
      if (currentQuestionValues?.[question.id]?.[input.id])
        budgetUsed += Number(input.displayData.amount);
    }
    return budgetUsed;
  }, [questionValue]);

  const remainingBudget = budgetAsNumber - currentBudgetUsed;
  const calculateProgress = () => Math.min((currentBudgetUsed / budgetAsNumber) * 100, 100);

  const onChange = (inputIds, lastInputId) => {
    const newQuestionValue = inputIds.reduce((accumulator, inputId) => {
      accumulator[inputId] = { value: true };
      return accumulator;
    }, {});

    dispatchUpdateQuestionValue(question.id, newQuestionValue);

    const params = {
      inputId: lastInputId,
      questionId: question.id,
    };
    if (inputIds.includes(lastInputId)) dispatch(upsertResponseEntry(params));
    else dispatch(deleteResponseEntry(params));
  };

  return (
    <div className="budget-question" data-test="budget-question">
      <div className="budget-question" data-test="budget-question">
        <BudgetInput options={inputs} initialValue={questionValue} onChange={onChange} />
        <BudgetBox
          units={unitLabel}
          currentBudgetUsed={currentBudgetUsed}
          calculateProgress={calculateProgress}
          remainingBudget={remainingBudget}
          budgetAsNumber={budgetAsNumber}
        />
      </div>
    </div>
  );
}

BudgetQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    unitLabel: PropTypes.string,
    displayData: PropTypes.shape({
      budget: PropTypes.string,
    }),
    inputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  questionValues: PropTypes.shape({}).isRequired,
  currentQuestionValues: PropTypes.shape({}).isRequired,
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
};

export default memo(BudgetQuestion);
