// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import compareDesc from 'date-fns/compareDesc';
import parseISO from 'date-fns/parseISO';

// Components
import ShowMoreList from 'common/components/ShowMoreList';
import AnnouncementListItem from '../AnnouncementListItem';

const AnnouncementSidebar = ({ announcements, title, match }) => {
  const sortedAnnouncements =
    announcements &&
    announcements.sort((first, second) =>
      compareDesc(parseISO(first.publishedAt), parseISO(second.publishedAt))
    );
  return (
    <div className="announcement__sidebar">
      <p className="announcement__sidebar-title">
        <i className="fas fa-bell" />
        {title}
      </p>
      <ul className="project-updates__items">
        {!!(announcements || []).length && (
          <ShowMoreList>
            {sortedAnnouncements.map((announcement) => (
              <AnnouncementListItem
                key={announcement.id}
                match={match}
                announcement={announcement}
              />
            ))}
          </ShowMoreList>
        )}
      </ul>
    </div>
  );
};

AnnouncementSidebar.propTypes = {
  title: PropTypes.string.isRequired,
  announcements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      titleTranslations: PropTypes.shape({}),
      shortDescriptionTranslations: PropTypes.shape({}),
      publishedAt: PropTypes.string,
      slug: PropTypes.string,
    })
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      locale: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default AnnouncementSidebar;
