// Libraries
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';
import classNames from 'classnames';

// Enums
import SliderPosition from 'common/enums/SliderPosition';

// Helpers
import { roundStepValue, rtlLanguage } from 'common/utils/helpers';

// Components
import Slider from 'common/components/Slider';
import NumberInput from 'common/components/NumberInput';

const { LEFT, RIGHT, MIDDLE } = SliderPosition;

const SliderQuestionInput = ({ question, defaultValue, onChange }) => {
  const { displayData, unitLabel } = question;
  const { sliderPosition } = displayData;
  const rangeStart = Number(displayData.rangeStart);
  const rangeEnd = Number(displayData.rangeEnd);
  const stepSize = Number(displayData.stepSize);
  const [value, setValue] = useState(defaultValue);
  const onChangeDebounced = useCallback(debounce(onChange, 500), []);

  const updateValue = (newValue) => {
    setValue(String(newValue));
    onChangeDebounced(String(roundStepValue(newValue, stepSize, rangeStart, rangeEnd)));
  };

  const getSliderValue = () => {
    if (value === '') return rangeStart;
    if (isString(value)) return roundStepValue(value, stepSize, rangeStart, rangeEnd);

    if (sliderPosition === LEFT) return rangeStart;
    if (sliderPosition === RIGHT) return rangeEnd;
    if (sliderPosition === MIDDLE) {
      const middleValue = rangeStart + (rangeEnd - rangeStart) / 2;
      return roundStepValue(middleValue, stepSize, rangeStart, rangeEnd);
    }

    return null;
  };

  const renderLabel = (label, className) => (
    <div className={classNames('slider-question__slider-label', className)}>
      {label} {unitLabel}
    </div>
  );

  return (
    <div className="slider-question__input">
      <div className="slider-question__slider">
        <div className="slider-question__slider-labels">
          {renderLabel(rangeStart, 'slider-question__slider-label--start')}
          {renderLabel(rangeEnd, 'slider-question__slider-label--end')}
        </div>
        <Slider
          ariaLabel={question.title}
          ariaValuetext={`Value: ${getSliderValue()}`}
          value={getSliderValue()}
          onChange={updateValue}
          min={rangeStart}
          max={rangeEnd}
          step={stepSize}
          invert={rtlLanguage()}
        />
      </div>
      <NumberInput
        aria-label={question.title}
        className="slider-question__value-input"
        value={value || ''}
        onChange={(event) => updateValue(event.target.value)}
        onBlur={() => setValue(String(roundStepValue(value, stepSize, rangeStart, rangeEnd)))}
        decimals={0}
        min={rangeStart}
        max={rangeEnd}
        step={stepSize}
        style={{ maxWidth: `calc(${displayData.rangeEnd.length}ch + 60px` }}
      />
    </div>
  );
};

SliderQuestionInput.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    required: PropTypes.bool,
    displayData: PropTypes.shape({
      rangeEnd: PropTypes.string.isRequired,
      stepSize: PropTypes.string.isRequired,
      rangeStart: PropTypes.string.isRequired,
      sliderPosition: PropTypes.string.isRequired,
    }).isRequired,
    unitLabel: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

SliderQuestionInput.defaultProps = {
  defaultValue: null,
};

export default SliderQuestionInput;
