// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

// Utilities
import { classicTheme } from 'common/utils/helpers';

const CheckboxInput = ({ id, name, label, register, validations, error, disabled }) => {
  const { t } = useTranslation();

  return (
    <div className="input-section">
      <div className="ideation-checkbox">
        <label className="checkbox__control-label" htmlFor={id}>
          <input
            type="checkbox"
            id={id}
            aria-label={label}
            className="checkbox__control-input"
            disabled={disabled}
            {...register(name, validations)}
          />
          <div className="checkbox__control-display" />
          <span className={classNames('checkbox-text', { 'is-primary-color': classicTheme() })}>
            {label}
          </span>
        </label>
      </div>
      {error && <span className="input-error">{t(error.message)}</span>}
    </div>
  );
};

CheckboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  validations: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.shape({ message: PropTypes.string.isRequired }),
  disabled: PropTypes.bool,
};

CheckboxInput.defaultProps = {
  validations: {},
  error: null,
  disabled: false,
};

export default CheckboxInput;
