// Libraries
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Enumerables
import Key from 'common/enums/Key';

const RatingQuestionStar = ({ rating, isActive, willBeActive }) => {
  const elementRef = useRef();

  const onKeyDown = (event) => {
    if ([Key.ENTER, Key.SPACE].includes(event.key)) {
      // Trigger react-rater click event
      elementRef.current.parentElement.click();
    }
  };

  return (
    <div
      className={classNames('rating-question__star')}
      onKeyDown={onKeyDown}
      ref={elementRef}
      tabIndex="0"
      aria-label={`${rating} ${rating > 1 ? 'stars' : 'star'}`}
      role="button"
    >
      <div className="rating-question__star-label">{rating}</div>
      <i
        className={classNames('fa-star', {
          fas: isActive || willBeActive,
          far: !isActive,
          'rating-question__star-icon--active': isActive,
        })}
      />
    </div>
  );
};

RatingQuestionStar.propTypes = {
  rating: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  willBeActive: PropTypes.bool,
};

RatingQuestionStar.defaultProps = {
  isActive: false,
  willBeActive: false,
};

export default RatingQuestionStar;
