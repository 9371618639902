import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Content from '../Content';
import QuestionContainer from '../QuestionContainer';

/**
 * Block component responsible for rendering question and other media content.
 */
export class Block extends Component {
  static propTypes = {
    block: PropTypes.shape({
      question: PropTypes.object,
    }).isRequired,
    saveRef: PropTypes.func.isRequired,
    clearRef: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    const { clearRef } = this.props;
    clearRef();
  }

  render() {
    const { block, saveRef } = this.props;

    return (
      <div className="block" ref={saveRef}>
        {block.content && <Content content={block.content} />}
        {block.question && <QuestionContainer question={block.question} />}
      </div>
    );
  }
}

export default Block;
