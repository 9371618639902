// Libraries
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';

function QRCodeImage({ value, alt, options, ...props }) {
  const [dataUrl, setDataUrl] = useState();

  useEffect(() => {
    QRCode.toDataURL(value, options).then(setDataUrl);
  }, []);

  return <img {...props} src={dataUrl} alt={alt} />;
}

QRCodeImage.propTypes = {
  value: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  options: PropTypes.shape({
    margin: PropTypes.number,
    scale: PropTypes.number,
    width: PropTypes.number,
  }),
};

QRCodeImage.defaultProps = {
  options: undefined,
};

export default QRCodeImage;
