import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MobileSortableItem from './MobileSortableItem';

/**
 * MobileSortableList component.
 */
class MobileSortableList extends PureComponent {
  static propTypes = {
    /**
     * Items to sort.
     */
    items: PropTypes.array.isRequired,
    /**
     * Currently ordered items.
     */
    orderedItems: PropTypes.array.isRequired,
    /**
     * Callback to call when order of items is changed.
     */
    onSortEnd: PropTypes.func.isRequired,
  };

  renderItem = (item) => {
    const { items, orderedItems, onSortEnd } = this.props;
    const index = orderedItems.indexOf(item);

    return (
      <MobileSortableItem
        key={item.id}
        item={item}
        index={index}
        options={[...items.keys()]}
        onChange={(newIndex) => {
          onSortEnd({
            oldIndex: index,
            newIndex,
          });
        }}
      />
    );
  };

  render() {
    const { orderedItems } = this.props;

    return <div className="mobile-sortable-list">{orderedItems.map(this.renderItem)}</div>;
  }
}

export default MobileSortableList;
