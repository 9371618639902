// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import isBefore from 'date-fns/isBefore';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { useParams } from 'react-router-dom';

// Media Queries
import mediaQueries from 'common/utils/mediaQueries';

// Utilities
import { populatePath } from 'common/utils/helpers';

// Routes
import routes from 'routes';

const List = ({ items, projects }) => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const isDesktop = useMediaQuery({ ...mediaQueries.desktop });
  const projectsById = _.keyBy(projects, 'id');

  const getProjectUrl = (projectId) => {
    return populatePath(routes.PROJECT, { ...params, projectId });
  };

  return items.map((item, index) => {
    const endTime = item.endTime ? format(new Date(item.endTime), 'yyyy-MM-dd') : null;
    const activeProject = projectsById[item.projectId];

    const getLink = () => {
      const projectId =
        activeProject?.slug || activeProject?.id || item.project?.slug || item.project?.id;
      const opportunityType = _.snakeCase(item.recordType);
      const opportunityId = item.slug || item.recordId;
      return `/${i18n.language}/projects/${projectId}/${opportunityType}s/${opportunityId}`;
    };

    const completeTime = t(
      `project.${item.minutesToComplete > 1 ? 'approximate_time_other' : 'approximate_time'}`,
      {
        count: item.minutesToComplete,
      }
    );

    const element = (
      <div className="opportunity" key={`${item.id}`}>
        <div className="opportunity__label">
          <div className="tag is-success">{t('project.open')}</div>
        </div>
        <div className="opportunity__content">
          {activeProject ? (
            <a
              href={getProjectUrl(activeProject.slug || activeProject.id)}
              className="project-title"
            >
              {activeProject.title}
            </a>
          ) : (
            <div className="project-title">&nbsp;</div>
          )}
          <h3 className="is-size-4 title" id={`opportunity_${item.id}`}>
            {item.title}
          </h3>
          <p>{item.description || item.shortDescription}</p>
          <div className="opportunity__content__datetime is-size-7">
            {!!endTime && <span>{`${t('project.closes')}: ${endTime}`}</span>}
            {!!item.minutesToComplete && (
              <>
                {!!endTime && <span>・</span>}
                <span dangerouslySetInnerHTML={{ __html: completeTime }} />
              </>
            )}
            {!endTime && !item.minutesToComplete && <span>&nbsp;</span>}
          </div>
          <div className="opportunity__content__action">
            <a
              className="button is-success is-small"
              href={getLink()}
              aria-labelledby={`opportunity_${item.id}`}
            >
              {t('project.participate_now')}
            </a>
          </div>
        </div>
      </div>
    );

    if (!isDesktop) return element;

    return (
      <Slide key={`${item.type}-${item.id}`} index={index}>
        {element}
      </Slide>
    );
  });
};

const Opportunities = (props) => {
  const { projects, opportunities } = props;
  const isDesktop = useMediaQuery({ ...mediaQueries.desktop });
  const items = opportunities.sort((a, b) => {
    if (a.endTime === null && b.endTime === null) {
      if (isBefore(new Date(a.startTime), new Date(b.startTime))) return 1;
      if (isBefore(new Date(b.startTime), new Date(a.startTime))) return -1;
    }

    if (a.endTime === null && b.endTime !== null) return 1;
    if (b.endTime === null && a.endTime !== null) return -1;

    if (isBefore(new Date(a.endTime), new Date(b.endTime))) return -1;
    if (isBefore(new Date(b.endTime), new Date(a.endTime))) return 1;

    return 0;
  });

  const list = <List items={items} projects={projects} />;

  if (!isDesktop) return list;

  return (
    <CarouselProvider totalSlides={items.length} visibleSlides={2} isIntrinsicHeight>
      <Slider>{list}</Slider>

      <ButtonBack className="image-gallery__slider-previous button is-primary is-primary-background">
        <i className="fas fa-angle-left" />
      </ButtonBack>

      <ButtonNext className="image-gallery__slider-next button is-primary is-primary-background">
        <i className="fas fa-angle-right" />
      </ButtonNext>
    </CarouselProvider>
  );
};

Opportunities.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  opportunities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Opportunities;
