// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSWRConfig } from 'swr';

// Enums
import { sorts } from 'discussion/enums';
import DiscussableTypes from 'common/enums/DiscussableTypes';

// Components
import CommentInput from 'discussion/components/CommentInput';
import DiscussionCommentList from 'discussion/components/DiscussionCommentList';

const DiscussionBoard = (props) => {
  const {
    discussableId,
    discussableType,
    isLoggedIn,
    approvedAndModeratedCount,
    disabled,
    onRootCommentSubmit,
  } = props;

  const { cache, mutate } = useSWRConfig();
  const [sorting, setSorting] = useState(sorts.NEWEST.param);

  // Mutate SWR for getting latest data
  const clearDiscussionCaches = async () => {
    const mutations = Array.from(cache.keys()).map((key) => mutate(key));
    await Promise.all(mutations);
  };

  const onSortingChange = async (sortingOption) => {
    if (sortingOption !== sorting) {
      setSorting(sortingOption);
      await clearDiscussionCaches();
    }
  };

  return (
    <div className="discussion-board">
      {isLoggedIn && (
        <CommentInput
          discussableType={discussableType}
          discussableId={discussableId}
          disabled={disabled}
          onSubmit={() => {
            clearDiscussionCaches();
            onRootCommentSubmit?.();
          }}
        />
      )}
      <div className="mt-6" />
      <DiscussionCommentList
        discussableType={discussableType}
        discussableId={discussableId}
        approvedAndModeratedCount={approvedAndModeratedCount}
        isLoggedIn={isLoggedIn}
        sorting={sorting}
        disableActions={disabled}
        onSortingChange={onSortingChange}
        onCommentSubmit={clearDiscussionCaches}
      />
    </div>
  );
};

DiscussionBoard.propTypes = {
  discussableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  discussableType: PropTypes.oneOf(Object.values(DiscussableTypes)).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  approvedAndModeratedCount: PropTypes.number.isRequired,
  onRootCommentSubmit: PropTypes.func,
};

DiscussionBoard.defaultProps = {
  onRootCommentSubmit: null,
};

export default DiscussionBoard;
