// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// State
import { deserializeEngagement } from 'state/ducks/engagement';

// Utilities
import { populatePath } from 'common/utils/helpers';

// Routes
import Routes from 'routes';

// Components
import Link from 'common/components/Link';

export const VisitHubLink = ({ icon, button, deserializedEngagement, match, t }) => (
  <Link
    href={deserializedEngagement.redirectUrl || populatePath(Routes.PROJECT, match.params)}
    {...(button && { button: true, primary: true })}
    className={button ? '' : 'is-secondary-color'}
  >
    {icon}
    {button
      ? deserializedEngagement.buttonText || t('engagement.visit_project_hub')
      : deserializedEngagement.linkText || t('engagement.back_to_overview')}
  </Link>
);

VisitHubLink.propTypes = {
  icon: PropTypes.node,
  button: PropTypes.bool,
  deserializedEngagement: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

VisitHubLink.defaultProps = {
  icon: null,
  button: false,
};

const mapStateToProps = (state) => ({ deserializedEngagement: deserializeEngagement(state) });

const ConnectedComponent = connect(mapStateToProps, null)(VisitHubLink);

export default withRouter(withTranslation()(ConnectedComponent));
