// Libraries
import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';
import { debounce } from 'underscore';

// Utilities
import { getOrdinal, storyTheme } from 'common/utils/helpers';

// Components
import SortableItem from './SortableItem';

// Constants
const ARROW_UP = 'ArrowUp';
const ARROW_DOWN = 'ArrowDown';
const WINDOW_RESIZE_DEBOUNCE_MS = 200;

export class SortableList extends PureComponent {
  state = { focusedIndex: null };

  ref = createRef();

  itemsRef = createRef();

  componentDidMount() {
    this.setItemHeights();
    window.addEventListener('resize', this.setItemHeights);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setItemHeights);
  }

  setItemHeights = debounce(() => {
    if (!this.itemsRef.current) {
      return;
    }

    const { setItemHeights } = this.props;
    const heights = [...this.itemsRef.current.childNodes].map(
      (node) => node.getBoundingClientRect().height
    );

    setItemHeights(heights);
  }, WINDOW_RESIZE_DEBOUNCE_MS);

  addKeyDownListener = (index) => {
    this.setState({ focusedIndex: index });
    document.addEventListener('keydown', this.handleKeyDown);
  };

  removeKeyDownListener = () => {
    document.removeEventListener('keydown', this.handleKeyDown);
  };

  handleKeyDown = (event) => {
    const { focusedIndex } = this.state;
    const { items } = this.props;
    const lastIndex = items.length - 1;

    if (event.key === ARROW_UP && focusedIndex > 0) {
      event.preventDefault();
      this.adjustIndex(focusedIndex, focusedIndex - 1);
    }

    if (event.key === ARROW_DOWN && focusedIndex < lastIndex) {
      event.preventDefault();
      this.adjustIndex(focusedIndex, focusedIndex + 1);
    }
  };

  adjustIndex = (focusedIndex, newIndex) => {
    const { onSortEndKeyboard } = this.props;
    onSortEndKeyboard({
      oldIndex: focusedIndex,
      newIndex,
    });
    this.setState({ focusedIndex: newIndex });
  };

  renderBackground = (index) => {
    const { itemHeights } = this.props;

    return (
      <div
        className="sortable-list__background"
        style={{
          width: this.ref.current?.getBoundingClientRect().width,
          height: itemHeights[index],
        }}
      />
    );
  };

  render() {
    const { items, itemHeights } = this.props;

    return (
      <div className="sortable-list" ref={this.ref}>
        <div className="columns">
          <div className="column sortable-list__indexes">
            <ol>
              {items.map((item, index) => (
                <li className="sortable-list__index" key={index}>
                  {storyTheme() && this.renderBackground(index)}
                  <div
                    className="sortable-list__index-value is-primary-background"
                    style={{ height: itemHeights[index] }}
                  >
                    {storyTheme() ? index + 1 : getOrdinal(index + 1)}
                  </div>
                </li>
              ))}
            </ol>
          </div>
          <div className="column sortable-list__items">
            <ol ref={this.itemsRef} role="listbox">
              {items.map((item, index) => (
                <SortableItem
                  key={item.id}
                  index={index}
                  item={item}
                  addKeyDownListener={() => this.addKeyDownListener(index)}
                  removeKeyDownListener={() => this.removeKeyDownListener(index)}
                />
              ))}
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

SortableList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemHeights: PropTypes.arrayOf(PropTypes.number).isRequired,
  setItemHeights: PropTypes.func.isRequired,
  onSortEndKeyboard: PropTypes.func.isRequired,
};

export default SortableContainer(SortableList);
