// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// State
import { clearUserData } from 'state/ducks/engagement';

// Utilities
import { withPersistContext } from 'common/utils/reduxPersist';
import { populatePath } from 'common/utils/helpers';

// Routes
import Routes from 'routes';

export class ErrorPageContainer extends PureComponent {
  componentDidMount() {
    const { persistContext, dispatchClearUserData } = this.props;
    dispatchClearUserData();
    persistContext.clearStorage();
  }

  render() {
    const { match, t } = this.props;

    return (
      <div className="hero is-primary is-fullheight engagement-error-page is-primary-background">
        <div className="hero-body">
          <div className="container">
            <div className="hero-content">
              <h1 className="title is-1 is-primary-color">{t('engagement.500_error_message')}</h1>
              <p className="subtitle is-4">
                <Trans i18nKey="engagement.error_page_try_again">
                  Don&apos;t worry - it wasn&apos;t your fault. We&apos;ve been alerted and will
                  look into it as quickly as possible.
                  <br />
                  In the meantime, you can
                  <a href={populatePath(Routes.ENGAGEMENT, match.params)}> Click here </a>
                  to give it another try.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ErrorPageContainer.propTypes = {
  persistContext: PropTypes.shape({
    clearStorage: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }).isRequired,
  dispatchClearUserData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchClearUserData: clearUserData,
};

const ConnectedComponent = connect(null, mapDispatchToProps)(ErrorPageContainer);

export default withPersistContext(withRouter(withTranslation()(ConnectedComponent)));
