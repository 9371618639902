// Libraries
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utilities
import {
  decimalPlaces,
  determineImageSize,
  roundStepValue,
  rtlLanguage,
} from 'common/utils/helpers';

// Components
import Slider from 'common/components/Slider';
import NumberInput from 'common/components/NumberInput';
import Image from 'common/components/Image';
import OpenBudgetComparison from '../OpenBudgetComparison';

function OpenBudgetInput({
  input,
  unitLabel,
  comparisonValueLabel,
  unitLayout,
  maxInputValue,
  defaultValue,
  onChange,
}) {
  const { displayData, label, media } = input;
  const { description, stepSize, minValue, maxValue, fixed } = displayData;
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState(
    String(roundStepValue(defaultValue || displayData.defaultValue, stepSize, minValue, maxValue))
  );
  const sanitizedValue = roundStepValue(value, stepSize, minValue, maxValue);

  const updateValue = (newValue) => {
    if (fixed) return;
    setValue(String(newValue));
    onChange(String(roundStepValue(newValue, stepSize, minValue, maxValue)));
  };

  useEffect(() => {
    if (defaultValue === null) onChange(value, true);
  }, []);

  return (
    <div className="open-budget-input">
      <button
        type="button"
        className="open-budget-input__header"
        onClick={() => setExpanded(!expanded)}
        disabled={!media && !description}
      >
        <h3 className="open-budget-input__title">{label}</h3>
        <div className="open-budget-input__chevron">
          <i
            className={classNames('fas', {
              'fa-chevron-down': expanded,
              'fa-chevron-right': !expanded && !rtlLanguage(),
              'fa-chevron-left': !expanded && rtlLanguage(),
            })}
          />
        </div>
      </button>
      <div className="open-budget-input__controls">
        <div className="open-budget-input__slider-container">
          <div className="open-budget-input__slider-labels">
            <div className=" open-budget-input__slider-label open-budget-input__slider-label--start">
              {minValue.toLocaleString()}
            </div>
            <div className=" open-budget-input__slider-label open-budget-input__slider-label--end">
              {maxValue.toLocaleString()}
            </div>
          </div>
          <Slider
            ariaLabel={label}
            ariaValuetext={`Value: ${sanitizedValue}`}
            className="open-budget-input__slider"
            thumbClassName="open-budget-input__slider-thumb"
            trackClassName="open-budget-input__slider-track"
            value={sanitizedValue}
            onChange={updateValue}
            min={minValue}
            max={maxValue}
            step={stepSize}
            disabled={fixed}
            invert={rtlLanguage()}
          />
        </div>
        <div className="open-budget-input__value-container">
          <div
            className={classNames('open-budget-input__value', {
              'open-budget-input__value--reverse': unitLabel === '$',
            })}
          >
            <NumberInput
              aria-label={label}
              className="open-budget-input__value-input"
              value={value}
              onChange={(event) => updateValue(event.target.value)}
              onBlur={() => setValue(String(sanitizedValue))}
              decimals={decimalPlaces(stepSize)}
              min={minValue}
              max={maxValue}
              step={stepSize}
              disabled={fixed}
              style={{ minWidth: `calc(${maxInputValue.toString().length}ch + 60px` }}
            />
            <div className={classNames({ 'is-hidden-tablet': unitLayout === 'header' })}>
              {unitLabel}
            </div>
          </div>
          <OpenBudgetComparison
            value={sanitizedValue}
            comparisonValue={Number(displayData.comparisonValue)}
            unitLabel={unitLabel}
            comparisonValueLabel={comparisonValueLabel}
            unitLayout={unitLayout}
          />
        </div>
      </div>
      <div
        className={classNames('open-budget-input__content fade-in', {
          'open-budget-input__content--expanded': expanded,
        })}
      >
        {description && <div className="open-budget-input__description">{description}</div>}
        {media && <Image src={determineImageSize(media.imageUrls)} alt={media.altText} />}
      </div>
    </div>
  );
}

OpenBudgetInput.propTypes = {
  input: PropTypes.shape({
    label: PropTypes.string.isRequired,
    displayData: PropTypes.shape({
      description: PropTypes.string,
      minValue: PropTypes.number.isRequired,
      maxValue: PropTypes.number.isRequired,
      stepSize: PropTypes.number.isRequired,
      defaultValue: PropTypes.string.isRequired,
      comparisonValue: PropTypes.string,
      fixed: PropTypes.bool.isRequired,
    }).isRequired,
    media: PropTypes.shape({
      imageUrls: PropTypes.shape({}),
      altText: PropTypes.string,
    }),
  }).isRequired,
  unitLabel: PropTypes.string,
  comparisonValueLabel: PropTypes.string,
  unitLayout: PropTypes.string.isRequired,
  maxInputValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

OpenBudgetInput.defaultProps = {
  defaultValue: null,
  unitLabel: '',
  comparisonValueLabel: '',
};

export default OpenBudgetInput;
