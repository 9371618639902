// Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Utils
import { redirectToSignInPath, redirectToSignUpPath } from 'common/utils/authHelpers';

const LoginSection = () => {
  const { t } = useTranslation();

  return (
    <div className="login-section">
      <div className="login-section__text">{t('ideation.login_or_sign_up_reminder')}</div>
      <div className="login-section__actions">
        <button
          type="button"
          className="button login-section__actions__login-button"
          onClick={() => redirectToSignInPath(window.location.pathname)}
        >
          {t('ideation.log_in_button')}
        </button>
        <button
          type="button"
          className="button is-primary is-primary-background login-section__actions__sign-up-button"
          onClick={() => redirectToSignUpPath(window.location.pathname)}
        >
          {t('ideation.sign_up_button')}
        </button>
      </div>
    </div>
  );
};

export default LoginSection;
