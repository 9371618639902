// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ProjectTile from 'project/components/ProjectsContainer/ProjectTile';

const SingleList = (props) => {
  const { projects } = props;

  const projectElements = projects.map((project) => (
    <ProjectTile key={project.id} project={project} />
  ));

  return (
    <div className="actives-projects__single-list">
      <div className="projects-tiles active-project-tiles">{projectElements}</div>
    </div>
  );
};

SingleList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SingleList;
