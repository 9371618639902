// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const IdeaBoardActions = ({ openModal }) => {
  const { t } = useTranslation();

  return (
    <div className="idea-board__cta has-text-centered level">
      <div className="level-item is-narrow">
        <span className="idea-board__cta-text">{t('ideation.comment_on_idea')}</span>
      </div>
      <div className="level-item is-narrow">
        <span className="idea-board__cta-seperator">{`-${t('ideation.or')}-`}</span>
      </div>
      <div className="level-item is-narrow">
        <button
          type="button"
          className="button is-compact is-primary is-primary-background idea-board__cta-button"
          onClick={openModal}
        >
          {`+${t('ideation.add_idea_button')}`}
        </button>
      </div>
    </div>
  );
};

IdeaBoardActions.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default IdeaBoardActions;
