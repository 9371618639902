// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const BudgetBox = ({
  units,
  currentBudgetUsed,
  calculateProgress,
  remainingBudget,
  budgetAsNumber,
  t,
}) => (
  <div className="budget-box-container">
    <div className="budget-box is-primary-background">
      <div className="budget-data">
        <p className="budget-used-text">{`${units} ${t('engagement.used')}`}</p>
        <p className="budget-used-number">{currentBudgetUsed.toLocaleString()}</p>
      </div>
      <div className="budget-progress">
        <div className="progress-total" />
        <div
          className="progress-used"
          style={{ width: `${calculateProgress(currentBudgetUsed, budgetAsNumber)}%` }}
        />
      </div>
      <div className="budget-data">
        <p>{t('engagement.remaining')}</p>
        <p>{remainingBudget.toLocaleString()}</p>
      </div>
    </div>
    {remainingBudget < 0 && (
      <div className="over-budget-box">
        <i className="fas fa-exclamation-triangle" />
        <div>
          <span>{t('engagement.you_are')}</span>
          <span className="over-budget-bold">
            {` ${Math.abs(remainingBudget).toLocaleString()} `}
          </span>
          <span>{`${t('engagement.over_the_limit', {
            limit: budgetAsNumber.toLocaleString(),
          })}!`}</span>
        </div>
      </div>
    )}
  </div>
);

BudgetBox.propTypes = {
  units: PropTypes.string,
  currentBudgetUsed: PropTypes.number.isRequired,
  calculateProgress: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  remainingBudget: PropTypes.number.isRequired,
  budgetAsNumber: PropTypes.number.isRequired,
};

BudgetBox.defaultProps = {
  units: '',
};

export default withTranslation()(BudgetBox);
