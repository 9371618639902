// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { currentLanguage } from 'i18n';

// Routes
import Routes from 'routes';

// Utils
import { determineImageSize, populatePath } from 'common/utils/helpers';

// Enums
import PublicStatus from 'common/enums/PublicStatus';
import { IdeaBoardType, IdeaType } from 'ideation/enums/propTypes';

// Components
import IdeaBoardHeaderContainer from 'ideation/components/ideaBoard/IdeaBoardHeaderContainer';
import IdeaActionContainer from 'ideation/components/ideaActions/IdeaActionContainer';

const IdeaHeader = ({ idea, ideaBoard, onIdeaUpdate }) => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const backgroundImageUrl =
    ideaBoard?.heroImage?.imageUrls && encodeURI(determineImageSize(ideaBoard.heroImage.imageUrls));

  return (
    <IdeaBoardHeaderContainer backgroundImageUrl={backgroundImageUrl} single>
      <div className="container inner">
        <a
          href={populatePath(Routes.IDEA_BOARD, {
            ...match.params,
            projectId: ideaBoard?.project.id,
          })}
          className="back-link"
        >
          <span className="has-text-weight-bold">{t('ideation.all_ideas')}</span>
        </a>
      </div>
      <div className="idea-board-header__inner container inner inner--narrow">
        <div className="idea-card idea-card--single">
          {idea?.responseIcon && (
            <div className="idea-card__response-icon">
              <i className={`fas fa-${idea.responseIcon}`} />
              {t(`ideation.idea_response_icon_labels.${idea.responseIcon}`)}
            </div>
          )}
          <div className="idea-card__content">
            <h1 className="is-size-3 idea-card__title is-primary-color has-text-weight-bold">
              {idea?.title}
            </h1>
            <div className="idea-card__body">
              {idea?.heroImage?.imageUrls && (
                <img
                  className="idea-card__image"
                  src={determineImageSize(idea.heroImage.imageUrls)}
                  alt="idea card"
                />
              )}
              {idea?.description && (
                <div className="idea-card__description">{idea.description}</div>
              )}
              {idea?.responseText && (
                <div className="idea-card__response">
                  <div className="idea-card__response-header">
                    <i className="fas fa-comment-dots" />
                    {gon.brand.name_translations[currentLanguage]}
                  </div>
                  <div className="idea-card__response-text">{idea.responseText}</div>
                </div>
              )}
            </div>
          </div>
          {/* TODO: refactor into component */}
          <span className="idea-card__footer-label has-text-weight-bold is-size-6">
            {ideaBoard?.publicStatus === PublicStatus.CLOSED
              ? t('ideation.voting_results')
              : t('ideation.vote_for_idea')}
          </span>
          <div className="idea-card__footer">
            {idea && ideaBoard && (
              <IdeaActionContainer
                idea={idea}
                publicStatus={ideaBoard.publicStatus}
                onIdeaUpdate={onIdeaUpdate}
              />
            )}
          </div>
        </div>
      </div>
    </IdeaBoardHeaderContainer>
  );
};

IdeaHeader.propTypes = {
  idea: IdeaType,
  ideaBoard: IdeaBoardType,
  onIdeaUpdate: PropTypes.func.isRequired,
};

IdeaHeader.defaultProps = {
  idea: null,
  ideaBoard: null,
};

export default IdeaHeader;
